import { Box } from "@mui/system";
import React from "react";
import ReviewRatings from "./ProposedEmployeerReviewRatings/ReviewRatings";

export default function ProposedEmployeer({ userRole }) {
  return (
    <Box >
    
      {userRole === "employer" && (
        <ReviewRatings />
        // Render content specific to employers
      )}
    </Box>
  );
}
