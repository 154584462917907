import React, { useState, useEffect } from "react";
import {
  Grid,
  Modal,
  Container,
  Box,
  Typography,
  Button,
  IconButton,
  useMediaQuery, useTheme 
} from "@mui/material";
import "./HomeMembership.css";
import SubscriptionModal from "./SubscriptionModal/SubscriptionModal";
import SelectNetwork from "./SelectNetwork/SelectNetwork";
import CoinPayments from "./CoinPayments/CoinPayments";
import close from "../../../Assets/Images/cancel-circle-half-dot.png";
import Slider from "react-slick";

export default function HomeMembership({ open, onClose }) {
  const [openModal, setOpenModal] = useState(false);
  const [cryptoModalOpen, setCryptoModalOpen] = useState(false);
  const [newModalOpen, setNewModalOpen] = useState(false); // State for the new modal
  const [isMobile, setIsMobile] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isMobileMUI = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768); // Adjust breakpoint according to your needs
    }

    handleResize(); // Call initially
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleCryptoModalOpen = () => {
    setCryptoModalOpen(true);
  };

  const handleCryptoModalClose = () => {
    setCryptoModalOpen(false);
  };
  const handleNewModalOpen = () => {
    setNewModalOpen(true);
    setCryptoModalOpen(false); // Close the Select Network modal
  };

  // Function to handle closing the new modal
  const handleNewModalClose = () => {
    setNewModalOpen(false);
  };

    // Custom Next Arrow Component
    const NextArrow = (props) => {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{
            ...style,
            display: "block",
            background: "#0B3013",
            borderRadius: "50%",
            padding: "5px",
          }}
          onClick={onClick}
        />
      );
    };
  
    // Custom Prev Arrow Component
    const PrevArrow = (props) => {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{
            ...style,
            display: "block",
            background: "#0B3013",
            borderRadius: "50%",
            padding: "5px",
          }}
          onClick={onClick}
        />
      );
    };

    
  const settings = {
    infinite: true,
    speed: 500,
    dots:true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    adaptiveHeight: true,
  };

  return (
    <section className="HomeMembership">
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="signup-modal-title"
        aria-describedby="signup-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: isMobileMUI ? '95%' : isDesktop ? 1200 : '75%', // Adjust width for mobile and desktop devices
            // width: 1200,
            bgcolor: "#f8f8f9 !important",
            boxShadow: 24,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            borderRadius: 2,
          }}
        >
          <Container maxWidth="xl">
            <Grid
            className="pbZero"
              sx={{
                display: "flex",
                justifyContent: "center",
                py: 5,
                position: "relative",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                  className="dollarDigit pbZero"
                    variant="h4"
                    sx={{ color: "black", py: 1, fontWeight: 600 }}
                  >
                    Membership Plans{" "}
                  </Typography>
                  <Typography variant="p" sx={{ color: "black", py: 2 }}>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting
                  </Typography>
                </Box>
              </Box>
                <IconButton
                className="closeBtn"
                  sx={{
                    display: "flex",
                    ms: "auto",
                    justifyContent: "end",
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 15,
                    bottom: "100%",
                    "&:hover": {
                      background:'transparent',
                    }
                  }}
                  onClick={onClose}

                >
                  <img src={close} alt="db" />
                </IconButton>
            </Grid>
            <Box className='pbZero ptZero' sx={{ py: 5 }}>
              {isMobile ? (
                <>
                  <Slider {...settings}>
                    <Grid xs={4} className="membership-grid1">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography className='dollarDigit'
                            variant="h3"
                            sx={{ pe: 2, display: "block", color: "#000" }}
                          >
                            $49
                          </Typography>{" "}
                          &nbsp;
                          <Typography className='monthText'
                            variant="h5"
                            sx={{ color: "#000", fontWeight: "lighter" }}
                          >
                            / monthly
                          </Typography>
                        </Box>
                        <Typography variant="h5" sx={{ py: 1, color: "#000" }}>
                          Standard Plan
                        </Typography>
                      </Box>
                      <Box className='ptZero' sx={{ py: 3 }}>
                           {/*  <Typography className='subsText'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Lorem Ipsum is simply text of the printing and
                          typesetting
                        </Typography>*/} 
                      </Box>
                      <Box sx={{ py: 3 }}>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          1 Listing
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          30 Days Visibility
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Highlighted in Search
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Result
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          4 Revision
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          9 Days Delivery Time
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Product Support
                        </Typography>
                      </Box>
                      <Box sx={{ py: 3 }}>
                        <Button
                          className="buy-button"
                          sx={{
                            display: "block",
                            margin: "auto",
                            textTransform: "capitalize",
                            bgcolor: "#F1FCF4",
                            fontSize: 16,
                            color: "#0B3013",
                            borderRadius: 10,
                            px: 6,
                            py: 1,
                          }}
                          onClick={handleModalOpen}
                        >
                          Buy Now
                        </Button>{" "}
                      </Box>
                    </Grid>
                    <Grid xs={4} className="membership-grid1">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography className='dollarDigit'
                            variant="h3"
                            sx={{ pe: 2, display: "block", color: "#000" }}
                          >
                            $49
                          </Typography>{" "}
                          &nbsp;
                          <Typography className='monthText'
                            variant="h5"
                            sx={{ color: "#000", fontWeight: "lighter" }}
                          >
                            / monthly
                          </Typography>
                        </Box>
                        <Typography variant="h5" sx={{ py: 1, color: "#000" }}>
                          Standard Plan
                        </Typography>
                      </Box>
                      <Box sx={{ py: 3 }}>
                           {/*  <Typography className='subsText'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Lorem Ipsum is simply text of the printing and
                          typesetting
                        </Typography>*/} 
                      </Box>
                      <Box sx={{ py: 3 }}>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          1 Listing
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          30 Days Visibility
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Highlighted in Search
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Result
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          4 Revision
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          9 Days Delivery Time
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Product Support
                        </Typography>
                      </Box>
                      <Box sx={{ py: 3 }}>
                        <Button
                          className="buy-button"
                          sx={{
                            display: "block",
                            margin: "auto",
                            textTransform: "capitalize",
                            bgcolor: "#F1FCF4",
                            fontSize: 16,
                            color: "#0B3013",
                            borderRadius: 10,
                            px: 6,
                            py: 1,
                          }}
                          onClick={handleModalOpen}
                        >
                          Buy Now
                        </Button>{" "}
                      </Box>
                    </Grid>
                    <Grid xs={4} className="membership-grid1">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography className='dollarDigit'
                            variant="h3"
                            sx={{ pe: 2, display: "block", color: "#000" }}
                          >
                            $49
                          </Typography>{" "}
                          &nbsp;
                          <Typography className='monthText'
                            variant="h5"
                            sx={{ color: "#000", fontWeight: "lighter" }}
                          >
                            / monthly
                          </Typography>
                        </Box>
                        <Typography variant="h5" sx={{ py: 1, color: "#000" }}>
                          Standard Plan
                        </Typography>
                      </Box>
                      <Box sx={{ py: 3 }}>
                           {/*  <Typography className='subsText'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Lorem Ipsum is simply text of the printing and
                          typesetting
                        </Typography>*/} 
                      </Box>
                      <Box sx={{ py: 3 }}>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          1 Listing
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          30 Days Visibility
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Highlighted in Search
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Result
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          4 Revision
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          9 Days Delivery Time
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Product Support
                        </Typography>
                      </Box>
                      <Box sx={{ py: 3 }}>
                        <Button
                          className="buy-button"
                          sx={{
                            display: "block",
                            margin: "auto",
                            textTransform: "capitalize",
                            bgcolor: "#F1FCF4",
                            fontSize: 16,
                            color: "#0B3013",
                            borderRadius: 10,
                            px: 6,
                            py: 1,
                          }}
                          onClick={handleModalOpen}
                        >
                          Buy Now
                        </Button>{" "}
                      </Box>
                    </Grid>
                  </Slider>
                </>
              ) : (
                <>
                  <Grid container>
                    <Grid xs={4} className="membership-grid">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography className='dollarDigit'
                            variant="h3"
                            sx={{ pe: 2, display: "block", color: "#000" }}
                          >
                            $49
                          </Typography>{" "}
                          &nbsp;
                          <Typography className='monthText'
                            variant="h5"
                            sx={{ color: "#000", fontWeight: "lighter" }}
                          >
                            / monthly
                          </Typography>
                        </Box>
                        <Typography variant="h5" sx={{ py: 1, color: "#000" }}>
                          Standard Plan
                        </Typography>
                      </Box>
                      <Box sx={{ py: 3 }}>
                           {/*  <Typography className='subsText'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Lorem Ipsum is simply text of the printing and
                          typesetting
                        </Typography>*/} 
                      </Box>
                      <Box sx={{ py: 3 }}>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          1 Listing
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          30 Days Visibility
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Highlighted in Search
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Result
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          4 Revision
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          9 Days Delivery Time
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Product Support
                        </Typography>
                      </Box>
                      <Box sx={{ py: 3 }}>
                        <Button
                          className="buy-button"
                          sx={{
                            display: "block",
                            margin: "auto",
                            textTransform: "capitalize",
                            bgcolor: "#F1FCF4",
                            fontSize: 16,
                            color: "#0B3013",
                            borderRadius: 10,
                            px: 6,
                            py: 1,
                          }}
                          onClick={handleModalOpen}
                        >
                          Buy Now
                        </Button>{" "}
                      </Box>
                    </Grid>
                    <Grid xs={4} className="membership-grid">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography className='dollarDigit'
                            variant="h3"
                            sx={{ pe: 2, display: "block", color: "#000" }}
                          >
                            $49
                          </Typography>{" "}
                          &nbsp;
                          <Typography className='monthText'
                            variant="h5"
                            sx={{ color: "#000", fontWeight: "lighter" }}
                          >
                            / monthly
                          </Typography>
                        </Box>
                        <Typography variant="h5" sx={{ py: 1, color: "#000" }}>
                          Standard Plan
                        </Typography>
                      </Box>
                      <Box sx={{ py: 3 }}>
                        {/* <Typography className='subsText'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Lorem Ipsum is simply text of the printing and
                          typesetting
                        </Typography> */}
                      </Box>
                      <Box sx={{ py: 3 }}>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          1 Listing
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          30 Days Visibility
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Highlighted in Search
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Result
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          4 Revision
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          9 Days Delivery Time
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Product Support
                        </Typography>
                      </Box>
                      <Box sx={{ py: 3 }}>
                        <Button
                          className="buy-button"
                          sx={{
                            display: "block",
                            margin: "auto",
                            textTransform: "capitalize",
                            bgcolor: "#F1FCF4",
                            fontSize: 16,
                            color: "#0B3013",
                            borderRadius: 10,
                            px: 6,
                            py: 1,
                          }}
                          onClick={handleModalOpen}
                        >
                          Buy Now
                        </Button>{" "}
                      </Box>
                    </Grid>
                    <Grid xs={4} className="membership-grid">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography className='dollarDigit'
                            variant="h3"
                            sx={{ pe: 2, display: "block", color: "#000" }}
                          >
                            $49
                          </Typography>{" "}
                          &nbsp;
                          <Typography className='monthText'
                            variant="h5"
                            sx={{ color: "#000", fontWeight: "lighter" }}
                          >
                            / monthly
                          </Typography>
                        </Box>
                        <Typography variant="h5" sx={{ py: 1, color: "#000" }}>
                          Standard Plan
                        </Typography>
                      </Box>
                      <Box sx={{ py: 3 }}>
                        {/* <Typography className='subsText'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Lorem Ipsum is simply text of the printing and
                          typesetting
                        </Typography> */}
                      </Box>
                      <Box sx={{ py: 3 }}>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          1 Listing
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          30 Days Visibility
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Highlighted in Search
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Result
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          4 Revision
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          9 Days Delivery Time
                        </Typography>
                        <Typography className='subsParagraph'
                          variant="h6"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Product Support
                        </Typography>
                      </Box>
                      <Box sx={{ py: 3 }}>
                        <Button
                          className="buy-button"
                          sx={{
                            display: "block",
                            margin: "auto",
                            textTransform: "capitalize",
                            bgcolor: "#F1FCF4",
                            fontSize: 16,
                            color: "#0B3013",
                            borderRadius: 10,
                            px: 6,
                            py: 1,
                          }}
                          onClick={handleModalOpen}
                        >
                          Buy Now
                        </Button>{" "}
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </Container>
        </Box>
      </Modal>

      <SubscriptionModal
        open={openModal}
        onClose={handleModalClose}
        onCryptoSelected={handleCryptoModalOpen}
      />
      <SelectNetwork
        open={cryptoModalOpen}
        onClose={handleCryptoModalClose}
        onSubmit={handleNewModalOpen}
      />
      <CoinPayments open={newModalOpen} onClose={handleNewModalClose} />
    </section>
  );
}
