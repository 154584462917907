import React from "react";
import { Box, Typography } from "@mui/material";
import "./ModalBannerText1.css";
import { useTranslation } from "react-i18next";
import "../../../Localization/i18n";

export default function ModalBannerText2() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start", // Align items to the start
        px: 11,
        pt: 10,
      }}
    >
      <Typography
        variant="div"
        sx={{
          fontSize: { xl: 18, md: 15 },
          display: "block",
          fontWeight: 400,
          textAlign: "start",
          pb: 4,
        }}
      >
    {t('Almost')}
      </Typography>
      <Typography
        variant="div"
        sx={{
          fontSize: { xl: 18, md: 15 },
          display: "block",
          fontWeight: 400,
          textAlign: "start",
          pb: 4,
        }}
      >
   {t('We')}
      </Typography>
      <Typography
        variant="div"
        sx={{
          fontSize: { xl: 18, md: 15 },
          display: "block",
          fontWeight: 400,
          textAlign: "start",
          pb: 4,
        }}
      >
        {t("Didnts")}
      </Typography>
    </Box>
  );
}
