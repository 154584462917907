import CryptoJS from "crypto-js";

// const keyHex = CryptoJS.enc.Hex.parse(
//   "3b229739d7d2c70a45548dfd35eee2e28d8e9dc1744487c64fc99b1610e59a8b"
// );
// const ivHex = CryptoJS.enc.Hex.parse("b7a9841bf2d98d3b37d75e9f1747b126");

// export function encryptText(encodedObject) {
//   if (!encodedObject) return null;

//   console.log(encodedObject);

//   const encrypted = CryptoJS.AES.encrypt(encodedObject, keyHex, {
//     iv: ivHex,
//     mode: CryptoJS.mode.CBC,
//     padding: CryptoJS.pad.Pkcs7,
//   });

//   const encryptData = encrypted.ciphertext.toString(CryptoJS.enc.Hex);
//   console.log("GENERATED ENCRYPT KEY - ", encryptData);

//   return encryptData;
// }

// export function decryptText(encryptedKey) {
//   if (!encryptedKey) return null;

//   const encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedKey);
//   const encryptedBase64Str = CryptoJS.enc.Base64.stringify(encryptedHexStr);

//   const decrypted = CryptoJS.AES.decrypt(encryptedBase64Str, keyHex, {
//     iv: ivHex,
//     mode: CryptoJS.mode.CBC,
//     padding: CryptoJS.pad.Pkcs7,
//   });

//   const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

//   return decryptedData;
// }

// METHOD 2

const key = CryptoJS.enc.Utf8.parse("my32lengthsupersecretnooneknows1"); // 32 chars
const iv = CryptoJS.enc.Utf8.parse("0000000000000000"); // 16 bytes IV

export function encryptText(text) {
  const encrypted = CryptoJS.AES.encrypt(text, key, {
    iv: iv,
  }).toString();
  return encrypted;
}

export function decryptText(encryptedText) {
  const decrypted = CryptoJS.AES.decrypt(encryptedText, key, { iv: iv });
  return decrypted.toString(CryptoJS.enc.Utf8);
}


// METHOD 3

// const key = CryptoJS.enc.Hex.parse(
//   "3b229739d7d2c70a45548dfd35eee2e28d8e9dc1744487c64fc99b1610e59a8b"
// );
// const iv = CryptoJS.enc.Hex.parse("b7a9841bf2d98d3b37d75e9f1747b126");


// export function encryptText(object) {
//   if (object === null || object === undefined) return null;
//   const encodedObject = JSON.stringify(object);
//   console.log("ENCODED OBJECT -", encodedObject);
//   const encrypted = CryptoJS.AES.encrypt(encodedObject, key, { iv: iv });
//   console.log(
//     "GENERATED ENCRYPT KEY -",
//     encrypted.ciphertext.toString(CryptoJS.enc.Hex)
//   );
//   return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
// }

// export function decryptText(encryptedKey) {
//   const encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedKey);
//   const encryptedBase64Str = CryptoJS.enc.Base64.stringify(encryptedHexStr);
//   const decrypted = CryptoJS.AES.decrypt(encryptedBase64Str, key, { iv: iv });
//   const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);
//   console.log("DECRYPTED DATA -", decryptedData);
//   return decryptedData;
// }

// const _encryptKey = CryptoJS.enc.Hex.parse(
//   "3b229739d7d2c70a45548dfd35eee2e28d8e9dc1744487c64fc99b1610e59a8b"
// );
// const _encryptIv = CryptoJS.enc.Hex.parse("b7a9841bf2d98d3b37d75e9f1747b126");

// export function encryptText(plainText) {
//   if (!plainText) return null;
//   const encrypted = CryptoJS.AES.encrypt(plainText, _encryptKey, {
//     iv: _encryptIv,
//   });
//   console.log(
//     `GENERATED ENCRYPT KEY - ${encrypted.ciphertext.toString(CryptoJS.enc.Hex)}`
//   );
//   return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
// }

// export function decryptText(encryptedText) {
//   const encryptedHex = CryptoJS.enc.Hex.parse(encryptedText);
//   const encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedHex);
//   const decrypted = CryptoJS.AES.decrypt(encryptedBase64, _encryptKey, {
//     iv: _encryptIv,
//   });
//   const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
//   console.log(`DECRYPTED DATA - ${decryptedText}`);
//   return decryptedText;
// }
