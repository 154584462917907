// AppRoutes.js

import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import MainLayout from "../Layout/MainLayout/MainLayout";
import Home from "../Pages/Home/Home";
import Signin from "../Auth/Signin/Signin";
import Signup from "../Auth/Signup/Signup";
import ContactUs from "../Pages/ContactUs/ContactUs";
import Dashboard from "../Dashboard/Dashboard";
import DashboardLayout from "../Dashboard/DashboardLayout/DashboardLayout";
import EmployeeDashboard from "../Dashboard/EmployeeDashboardPages/EmployeeDashboard/EmployeeDashboard";
import EmployeeMyProposals from "../Dashboard/EmployeeDashboardPages/EmployeeMyProposals/EmployeeMyProposals";
import EmployeerDashboard from "../Dashboard/EmployeerDashboardPages/EmployeerDashboard/EmployeerDashboard";
import EmployeerMyProjects from "../Dashboard/EmployeerDashboardPages/EmployeerMyProjects/EmployeerMyProjects";
import EmployeeMessage from "../Dashboard/EmployeeDashboardPages/EmployeeMessage/EmployeeMessage";
import EmployeeProfile from "../Dashboard/EmployeeDashboardPages/EmployeeProfile/EmployeeProfile";
import BrowseJobs from "../Pages/BrowseJobs/BrowseJobs";
import Help from "../Pages/Help/Help";
// import EmployeerBookmark from '../Dashboard/EmployeerDashboardPages/EmployeerBookmark/EmployeerBookmark';
import EmployeerMessage from "../Dashboard/EmployeerDashboardPages/EmployeerMessage/EmployeerMessage";
import EmployeerCreateProject from "../Dashboard/EmployeerDashboardPages/EmployeerCreateProject/EmployeerCreateProject";
import EmployeerProfile from "../Dashboard/EmployeerDashboardPages/EmployeerProfile/EmployeerProfile";
import ProposedEmployeer from "../Pages/ProposedEmployeerReviewRating/ProposedEmployeer";
import SendAnOfferAll from "../Pages/SendAnOfferAll/SendAnOfferAll";
import ProjectDetails from "../Pages/ProjectDetails/ProjectDetails";
import Services from "../Pages/Services/Services";
import EmployeeSaved from "../Dashboard/EmployeeDashboardPages/EmployeeSaved/EmployeeSaved";
import EmployeeMyProjects from "../Dashboard/EmployeeDashboardPages/EmployeeMyProjects/EmployeeMyProjects";
import EmployeerEditProject from "../Dashboard/EmployeerDashboardPages/EmployeerEditProject/EmployeerEditProject";
import { GetUserDetails } from "../Helpers/Utility";
import ProtectedRoute from "./ProctedRoutes";
import EmployeerBrowseProfileDashboard from "../Dashboard/EmployeerDashboardPages/EmployeerBrowseProfileDashboard/EmployeerBrowseProfileDashboard";
import EmployeerVerifyEmail from "../Dashboard/EmployeerDashboardPages/EmployeerVerifyEmail/EmployeerVerifyEmail";
import EmployeerChangePassword from "../Dashboard/EmployeerDashboardPages/EmployeerChangePassword/EmployeerChangePassword";
import EmployeerBillingPay from "../Dashboard/EmployeerDashboardPages/EmployeerBillingPay/EmployeerBillingPay";
import { Alert } from "../Helpers/Alert";
import Terms from "../Pages/Terms/Terms";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import EmployeeAwardReq from "../Dashboard/EmployeeDashboardPages/EmployeeAwardReq/EmployeeAwardReq";
import TrustAndSafetey from "../Pages/TrustAndSafetey/TrustAndSafetey";
import HelpAndSupport from "../Pages/HelpAndSupport/HelpAndSupport";
import EmployeerPayments from "../Dashboard/EmployeerDashboardPages/EmployeerPayments/EmployeerPayments";
import PricingFees from "../Pages/PricingFees/PricingFees";
import { toast } from "react-toastify";
import DeletionPolicy from "../Pages/DeletionPolicy/DeletionPolicy";

const Redirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, []);

  return <></>;
};

export default function AppRoutes() {
  const userDetails = GetUserDetails();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    // Check if we have navigated in this session already
    let hasNavigated = sessionStorage.getItem("hasNavigated");
    if (searchParams.get("crypto_payment_status")) {
      if (searchParams.get("crypto_payment_status") == "success") {
        toast.info(
          "Your crypto payment will take 30-60 minutes to finish processing"
        );
      } else {
        toast.info(
          "Your crypto payment was unsuccessfull please try again later."
        );
      }
      searchParams.delete("crypto_payment_status");
      setSearchParams(searchParams);
    }
    if (
      !hasNavigated &&
      !window.location.pathname.includes("dashboard") &&
      !window.location.pathname.includes("sendanoffer")
    ) {
      let loginData = localStorage.getItem("loginData");
      if (loginData) {
        let parsedLoginData = JSON.parse(loginData);
        let authToken = parsedLoginData.token;
        let role = parsedLoginData.role;
        if (searchParams.get("change_password_token")) {
          return;
        }
        if (parsedLoginData.email == process.env.REACT_APP_ADMIN_EMAIL) {
          sessionStorage.setItem("hasNavigated", "true");
          navigate("/dashboard/employer/payments");
          return;
        }
        if (authToken && role === "employer") {
          navigate("/dashboard/employer/browseprofiles");
          // Set the flag in sessionStorage
          sessionStorage.setItem("hasNavigated", "true");
        } else if (authToken && role === "employee") {
          navigate("/dashboard/employee");
          // Set the flag in sessionStorage
          sessionStorage.setItem("hasNavigated", "true");
        }
      }
    }
  }, [navigate]);

  
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="deletion-policy" element={<DeletionPolicy />} />
        <Route path="signin" element={<Signin />} />
        <Route path="signup" element={<Signup />} />
        <Route path="projectdetails" element={<ProjectDetails />} />
        <Route path="services" element={<Services />} />
        <Route path="contact" element={<ContactUs />} />
        <Route
          path="browsejobs"
          element={<BrowseJobs userRole={"employee"} />}
        />
        <Route
          path="reviewratings"
          element={<ProposedEmployeer userRole={userDetails?.role} />}
        />
        <Route
          path="sendanoffer"
          element={<SendAnOfferAll userRole={userDetails?.role} />}
        />
        <Route
          path="dashboard/*"
          element={<DashboardLayout role={userDetails?.role} />}
        />
        <Route path="terms-and-conditions" element={<Terms />} />
        <Route path="privacypolicy" element={<PrivacyPolicy />} />
        {/* <Route path="trustsaftey" element={<TrustAndSafetey />} /> */}
        <Route path="help" element={<HelpAndSupport />} />
        <Route path="pricingfees" element={<PricingFees />} />
      </Route>
      {/* Dashboard routes */}
      <Route
        path="dashboard"
        element={
          <ProtectedRoute
            element={<DashboardLayout role={userDetails?.role} />}
          />
        }
      >
        {/* <Route path="/dashboard" element={<DashboardLayout role={userDetails?.role} />}> */}
        <Route index element={<Dashboard />} />
        {/* Employee Dashboard */}
        <Route path="employee/*" element={<EmployeeDashboard />} />
        <Route path="employee/myproposals" element={<EmployeeMyProposals />} />
        <Route path="employee/myprojects" element={<EmployeeMyProjects />} />
        <Route path="employee/bookmark" element={<EmployeeSaved />} />
        <Route path="employee/message" element={<EmployeeMessage />} />
        <Route path="employee/profile" element={<EmployeeProfile />} />
        <Route path="employee/projectdetails" element={<ProjectDetails />} />
        <Route path="employee/awardrequest" element={<EmployeeAwardReq />} />

        {/* Employer Dashboard */}
        <Route path="employer/*" element={<EmployeerDashboard />} />
        <Route
          path="employer/browseprofiles"
          element={<EmployeerBrowseProfileDashboard />}
        />
        <Route path="employer/myprojects" element={<EmployeerMyProjects />} />
        <Route
          path="employer/reviewratings"
          element={<ProposedEmployeer userRole={userDetails?.role} />}
        />
        <Route
          path="employer/sendanoffer"
          element={<SendAnOfferAll userRole={userDetails?.role} />}
        />
        {/* <Route path="employer/bookmark" element={<EmployeerBookmark />} /> */}
        <Route path="employer/message" element={<EmployeerMessage />} />
        <Route
          path="employer/createproject"
          element={<EmployeerCreateProject />}
        />
        <Route path="employer/editproject" element={<EmployeerEditProject />} />
        <Route path="employer/profile" element={<EmployeerProfile />} />
        <Route path="employer/verifyemail" element={<EmployeerVerifyEmail />} />
        {/* <Route path="employer/changepassword" element={<EmployeerChangePassword />} /> */}
        <Route
          path="employer/bilingpayments"
          element={<EmployeerBillingPay />}
        />
        <Route path="accountant/payments" element={<EmployeerPayments />} />
      </Route>
      <Route path="*" element={<Redirect />} />
    </Routes>
  );
}
