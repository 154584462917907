import React from 'react';

const CustomCaretIcon = (props) => (
  <img
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAB/SURBVHgB7ZDBDYAgDEX/CIzQERzBEdxAN9ANZBPdiBEcgRGwCSV6UYFwsy95F/ihnwKKojwxs2thfinIw7KBHTOyk2QtCnGsZ+klQ5JxqIDYQ6SK+yw6XA3N7dzIw18/zKJH3HFqSjIwSIEmDIhtU2svg5tC7M5uaLAWRfkTJ8VzG1aSekLEAAAAAElFTkSuQmCC"
    alt="Custom Caret"
    style={{ width: '24px', height: '24px' }}
    {...props}
  />
);

export default CustomCaretIcon;
