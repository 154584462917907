import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Typography,
  Tabs,
  Tab,
  useMediaQuery,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import LoopIcon from "@mui/icons-material/Loop";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import loader from "../../../Assets/Images/loader.svg";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import NodataFound from "../../../Components/NodataFound/NodataFound";
import {
  getCommision,
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { postAPI } from "../../../Services/Api";
import { useTranslation } from "react-i18next";

export default function EmployeerPayments() {
  // Define sample data
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [paymentList, setPaymentList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPAge] = useState(1);
  const [tabValue, setTabValue] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    const { email } = GetUserDetails();
    var a = process.env.REACT_APP_ADMIN_EMAIL;
    if (email != process.env.REACT_APP_ADMIN_EMAIL) {
      navigate("/");
      return;
    }
    getPaymentData();
  }, []);

  const getPaymentData = async (page = currentPage) => {
    LoaderShow();
    try {
      const { token } = GetUserDetails();
      const res = await postAPI(
        `coinpayment/PaymentDetail?page=${page}`,
        {},
        token
      );
      if (res.statusCode == 200) {
        setPaymentList(res.data);
        setTotalPages(res?.pagination?.totalPages || 1);
        setCurrentPAge(res?.pagination?.currentPage || 1);
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
    LoaderHide();
  };

  const refreshStatus = async (milestone) => {
    LoaderShow();
    try {
      const data = {
        withdrawalId: milestone.withdrawalDetails.withdrawalId,
      };
      const { token } = GetUserDetails();
      const res = await postAPI("coinpayment/getWithdrawalStatus", data, token);
      if (res.statusCode == 200) {
        const newWithDrawalDetails = {
          ...milestone.withdrawalDetails,
          withdrawalStatus: res.data?.status_text,
        };
        setPaymentList((dt) =>
          dt.map((ele) => {
            if (ele.milestoneId == milestone.milestoneId)
              ele.withdrawalDetails = newWithDrawalDetails;
            return ele;
          })
        );
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
    LoaderHide();
  };

  const PayEmployee = async (milestone) => {
    LoaderShow();
    try {
      const data = {
        cryptoWalletAddress: milestone.employeeCryptoAddress,
        // cryptoWalletAddress: "mfrrVGudSx8akEToprA5U7s4mjSuDoPJ92",
        amount:
          parseFloat(milestone.milestoneAmount?.usdtConvertedAmount) -
          parseFloat(milestone?.milestoneFee?.usdtConvertedFee),
        fee: parseFloat(milestone?.milestoneFee?.usdtConvertedFee),
        milestoneIds: milestone.milestoneId,
      };
      const { token } = GetUserDetails();
      const res = await postAPI("coinpayment/employee-payment", data, token);
      if (res.statusCode == 200) {
        milestone.withdrawalDetails = { withdrawalId: res?.withdrawalId };
        refreshStatus(milestone);
        toast.success(res.message);
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
    LoaderHide();
  };

  const MarkAsPay = async (milestone) => {
    LoaderShow();
    try {
      const { token } = GetUserDetails();
      const data = {
        status: "paid",
        milestoneId: milestone.milestoneId,
      };
      const response = await postAPI("milestone/status/update", data, token);
      if (response.statusCode == 200) {
        setPaymentList((mileStones) =>
          mileStones.map((ele) => {
            if (ele.milestoneId == milestone.milestoneId) {
              ele.milestoneStatus = "paid";
            }
            return ele;
          })
        );
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
    LoaderHide();
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setCurrentPAge(1);
    setTotalPages(1);
  }, [tabValue]);

  const filteredPaymentList = useMemo(() => {
    if (tabValue == 0) {
      const arr = paymentList.filter(
        (ele) =>
          !(
            ele?.milestoneStatus == "paid" &&
            ele?.withdrawalDetails?.withdrawalStatus == "Complete"
          )
      );
      setTotalPages(Math.ceil(arr.length / 10));
      return arr;
    } else {
      const arr = paymentList.filter(
        (ele) =>
          ele?.milestoneStatus == "paid" &&
          ele?.withdrawalDetails?.withdrawalStatus == "Complete"
      );
      setTotalPages(Math.ceil(arr.length / 10));
      return arr;
    }
  }, [paymentList, tabValue]);

  return (
    <section className="messageEmployee">
      <div
        id="hideloding"
        className="loding-display"
        style={{ display: "none" }}
      >
        <img src={loader} alt="loader-img" />
      </div>
      <Box>
        <Typography variant="h4" sx={{ pb: 1, fontWeight: 600 }}>
          Payments
        </Typography>
      </Box>
      <Box
        className="plZero prZero"
        sx={{
          borderBottom: 1,
          borderColor: "#d6d6d6",
          mx: 2,
          px: 2.5,
          py: 2.5,
          pb: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Tabs
            variant={isMobile ? "scrollable" : "standard"}
            scrollButtons={isMobile ? "auto" : "off"}
            allowScrollButtonsMobile={isMobile}
            textColor="#222222"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#000",
              },
            }}
            value={tabValue}
            onChange={handleChange}
            aria-label="project details tabs"
          >
            <Tab
              sx={{
                textTransform: "capitalize",
                pl: 0,
                mx: 1,
                fontSize: 17,
                fontFamily: "inherit",
              }}
              label={"New"}
            />
            <Tab
              sx={{
                textTransform: "capitalize",
                pl: 0,
                mx: 1,
                fontSize: 17,
                fontFamily: "inherit",
              }}
              label={"Paid"}
            />
          </Tabs>
        </Box>
      </Box>
      <Box sx={{ backgroundColor: "#fff", my: 4, borderRadius: 7.5, py: 2 }}>
        {tabValue == 0 && (
          <Box>
            {filteredPaymentList.length > 0 ? (
              <Box>
                <Box sx={{ overflowY: "auto" }}>
                  <Table sx={{ fontFamily: "inherit" }}>
                    <TableHead sx={{ borderBottom: "0!important" }}>
                      <TableRow>
                        {[
                          "Project",
                          "Employer",
                          "Employee",
                          "Payment type",
                          "Crypto Address",
                          "Employer Balance",
                          "Amount in USD",
                          "Converted Amount",
                          "Status",
                          "Action",
                        ].map((header) => (
                          <TableCell
                            key={header}
                            sx={{
                              px: 5,
                              fontWeight: 600,
                              fontSize: 18,
                              borderBottom: "0!important",
                              fontFamily: "inherit",
                            }}
                          >
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {console.log(
                        filteredPaymentList
                          // .filter(
                          //   (ele) =>
                          //     ele?.withdrawalDetails?.withdrawalStatus !=
                          //     "complete"
                          // )
                          .slice((currentPage - 1) * 10, currentPage * 10)
                      )}
                      {filteredPaymentList
                        // .filter(
                        //   (ele) =>
                        //     ele?.withdrawalDetails?.withdrawalStatus !=
                        //     "complete"
                        // )
                        .slice((currentPage - 1) * 10, currentPage * 10)
                        .map((data, index) => (
                          <TableRow
                            key={data?.milestoneId}
                            sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              {data?.projectName}
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              {data?.employerName}
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              {data?.employeeName}
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              {data?.method}
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              {data?.employeeCryptoAddress}
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              ${data?.employerBalance}
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              $
                              {data?.milestoneAmount?.actualAmount -
                                data?.milestoneFee?.actualFee}
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              {data?.milestoneAmount?.usdtConvertedAmount -
                                data?.milestoneFee?.usdtConvertedFee}{" "}
                              USDT
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                display: "flex",
                                gap: "5px",
                                flexDirection: "row",
                              }}
                            >
                              {data?.withdrawalDetails?.withdrawalStatus && (
                                <LoopIcon
                                  onClick={(ev) => refreshStatus(data)}
                                  sx={{ px: 1 }}
                                />
                              )}

                              <Button
                                disabled
                                sx={{
                                  borderRadius: 9999,
                                  textTransform: "capitalize",
                                  "&:disabled": {
                                    color: "#2d2d2d",
                                  },
                                  marginLeft: data?.withdrawalDetails
                                    ?.withdrawalStatus
                                    ? 0
                                    : "45px",
                                }}
                                variant="contained"
                                color="success"
                              >
                                {data?.withdrawalDetails?.withdrawalStatus
                                  ? data?.withdrawalDetails?.withdrawalStatus
                                  : data?.employerBalance >=
                                    data?.milestoneAmount?.usdtConvertedAmount
                                  ? "Payment not initiated"
                                  : "Insufficient Balance"}
                              </Button>
                              {data?.milestoneStatus == "paid" && (
                                <Button
                                  disabled
                                  sx={{
                                    borderRadius: 9999,
                                    textTransform: "capitalize",
                                    "&:disabled": {
                                      color: "#2d2d2d",
                                    },
                                    marginLeft: "5px",
                                  }}
                                  variant="contained"
                                  color="success"
                                >
                                  Paid
                                </Button>
                              )}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {/* <Button
                            onClick={(ev) => PayEmployee(data)}
                            sx={{
                              borderRadius: 9999,
                              textTransform: "capitalize",
                            }}
                            variant="contained"
                            color="success"
                          >
                            Pay now
                          </Button> */}
                              {data?.employerBalance >=
                                data?.milestoneAmount?.usdtConvertedAmount &&
                              !data?.withdrawalDetails?.withdrawalStatus ? (
                                <Button
                                  onClick={(ev) => PayEmployee(data)}
                                  sx={{
                                    borderRadius: 9999,
                                    textTransform: "capitalize",
                                  }}
                                  variant="contained"
                                  color="success"
                                >
                                  Pay now
                                </Button>
                              ) : data?.milestoneStatus != "paid" &&
                                data?.withdrawalDetails?.withdrawalStatus ==
                                  "Complete" ? (
                                <Button
                                  onClick={(ev) => MarkAsPay(data)}
                                  sx={{
                                    borderRadius: 9999,
                                    textTransform: "capitalize",
                                  }}
                                  variant="contained"
                                  color="success"
                                >
                                  Mark as paid
                                </Button>
                              ) : (
                                "-"
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Box>
                <Box
                  sx={{
                    py: 3,
                    display: "flex",
                    ml: "auto",
                    justifyContent: "end",
                  }}
                >
                  <CustomPagination
                    count={totalPages}
                    page={currentPage}
                    onChange={(ev, value) => {
                      setCurrentPAge(value);
                    }}
                  />
                </Box>
              </Box>
            ) : (
              <NodataFound />
            )}
          </Box>
        )}
        {tabValue == 1 && (
          <Box>
            {filteredPaymentList.length > 0 ? (
              <Box>
                <Box sx={{ overflowY: "auto" }}>
                  <Table sx={{ fontFamily: "inherit" }}>
                    <TableHead sx={{ borderBottom: "0!important" }}>
                      <TableRow>
                        {[
                          "Withdrawal Id",
                          "Project",
                          "Employer",
                          "Employee",
                          "Payment type",
                          "Crypto Address",
                          "Employer Balance",
                          "Amount in USD",
                          "Converted Amount",
                          "Status",
                          // "Action",
                        ].map((header) => (
                          <TableCell
                            key={header}
                            sx={{
                              px: 5,
                              fontWeight: 600,
                              fontSize: 18,
                              borderBottom: "0!important",
                              fontFamily: "inherit",
                            }}
                          >
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredPaymentList
                        // .filter(
                        //   (ele) =>
                        //     ele?.withdrawalDetails?.withdrawalStatus !=
                        //     "complete"
                        // )
                        .slice((currentPage - 1) * 10, currentPage * 10)
                        .map((data, index) => (
                          <TableRow
                            key={data?.milestoneId}
                            sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              {data?.withdrawalDetails?.withdrawalId || "-"}
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              {data?.projectName}
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              {data?.employerName}
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              {data?.employeeName}
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              {data?.method}
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              {data?.employeeCryptoAddress}
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              ${data?.employerBalance}
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              ${data?.milestoneAmount?.actualAmount}
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              {data?.milestoneAmount?.usdtConvertedAmount} USDT
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                display: "flex",
                                gap: "5px",
                                flexDirection: "row",
                              }}
                            >
                              <Button
                                disabled
                                sx={{
                                  borderRadius: 9999,
                                  textTransform: "capitalize",
                                  "&:disabled": {
                                    color: "#2d2d2d",
                                  },
                                }}
                                variant="contained"
                                color="success"
                              >
                                {data?.withdrawalDetails?.withdrawalStatus
                                  ? data?.withdrawalDetails?.withdrawalStatus
                                  : data?.employerBalance >=
                                    data?.milestoneAmount?.usdtConvertedAmount
                                  ? "Payment not initiated"
                                  : "Insufficient Balance"}
                              </Button>
                              {data?.milestoneStatus == "paid" && (
                                <Button
                                  disabled
                                  sx={{
                                    borderRadius: 9999,
                                    textTransform: "capitalize",
                                    "&:disabled": {
                                      color: "#2d2d2d",
                                    },
                                    marginLeft: "5px",
                                  }}
                                  variant="contained"
                                  color="success"
                                >
                                  Paid
                                </Button>
                              )}
                            </TableCell>
                            {/* <TableCell>
                              {data?.milestoneStatus != "paid" && <Button
                                onClick={(ev) => MarkAsPay(data)}
                                sx={{
                                  borderRadius: 9999,
                                  textTransform: "capitalize",
                                }}
                                variant="contained"
                                color="success"
                              >
                                Mark as paid
                              </Button>}
                            </TableCell> */}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Box>
                <Box
                  sx={{
                    py: 3,
                    display: "flex",
                    ml: "auto",
                    justifyContent: "end",
                  }}
                >
                  <CustomPagination
                    count={totalPages}
                    page={currentPage}
                    onChange={(ev, value) => {
                      setCurrentPAge(value);
                    }}
                  />
                </Box>
              </Box>
            ) : (
              <NodataFound />
            )}
          </Box>
        )}
      </Box>
    </section>
  );
}
