import { Box, Container, Typography } from "@mui/material";
import "./DeletionPolicy.css";
import { useTranslation } from "react-i18next";

export default function DeletionPolicy() {
  const { t } = useTranslation();
  return (
    <section className="mainSection">
      <Container maxWidth="xl" sx={{ px: { md: 8, xl: 4, xxl: 4 }, py: 3 }}>
        <Box className="terms-main" sx={{ py: 5 }}>
          <h4 className="terms-heading">{t("deletionPolicy")}</h4>
        </Box>
        <div
          className="terms-content"
          style={{
            backgroundColor: "#fff",
            borderRadius: "20px",
          }}
        >
          <div style={{ marginBottom: "20px" }}>
            <h2
              style={{
                paddingBottom: "15px",
                borderBottom: "1px solid #dbdbdb",
              }}
            >
              {t("deletionPolicy")}
            </h2>
            <div style={{ padding: "15px 0 15px 0", marginTop: "10px" }}>
              <p style={{ marginBottom: "20px" }}>
                {t("deletionPolicy1")}{" "}
                <a style={{ color: "black" }} href="/">
                  https://www.workwavehub.com
                </a>
                 {t("deletionPolicy2")}{" "}
                <a
                  style={{ color: "black" }}
                  href="mailto:contact@workwavehub.com"
                >
                  contact@workwavehub.com
                </a>
                 {t("deletionPolicy3")}
              </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
