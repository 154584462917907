// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getMessaging, getToken } from "firebase/messaging";
import { postAPI } from "../../Services/Api";
import { GetUserDetails } from "../../Helpers/Utility";
import { toast } from "react-toastify";
import axios from "axios";
import {
  addDoc,
  collection,
  doc,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDAzNO7NJzs2osefBsXK0tdBjZzOIrDxK8",
  authDomain: "workwavehub-605e8.firebaseapp.com",
  databaseURL: "https://workwavehub-605e8-default-rtdb.firebaseio.com",
  projectId: "workwavehub-605e8",
  storageBucket: "workwavehub-605e8.appspot.com",
  messagingSenderId: "201781975580",
  appId: "1:201781975580:web:b5be48a34ab9531e17ddd9",
  measurementId: "G-153X02SE4J",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyCeFKp7JhUm3DMWuyfQ4J5oKRlielVy-pU",
//   authDomain: "workwavehub-gvm.firebaseapp.com",
//   databaseURL: "https://workwavehub-gvm-default-rtdb.firebaseio.com",
//   projectId: "workwavehub-gvm",
//   storageBucket: "workwavehub-gvm.appspot.com",
//   messagingSenderId: "1063431700661",
//   appId: "1:1063431700661:web:83edcf196dce8d39cd7efc",
//   measurementId: "G-V4NBV4PJTB",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const messaging = getMessaging(app);
const db = getFirestore(app);

function getPermission() {
  try {
    const logindetails = GetUserDetails();
    if (!logindetails) {
      return;
    }
    Notification.requestPermission().then(async (permission) => {
      if (permission === "granted") {
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_VAPID_KEY,
        });
        const logKey = localStorage.getItem("notif-token");
        if (!logKey || logKey != token) {
          localStorage.setItem("notif-token", token);
          postAPI(
            "user/update-webtoken",
            { web_token: token },
            GetUserDetails().token
          );
          // const res = await postAPI("");
        }
      } else {
        if (logindetails && !sessionStorage.getItem("notif-shown")) {
          toast.info(
            "You have decline web notification on this page. \nTo get notified please allow notification from site settings.",
            {
              position: "top-right",
              autoClose: false, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          sessionStorage.setItem("notif-shown", true);
        }
      }
    });
  } catch (err) {}
}

getPermission();

const provider = new GoogleAuthProvider();

export { auth, messaging, db, provider };
