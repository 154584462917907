import {
  Box,
  TextField,
  Typography,
  FormLabel,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import React, { useState, useRef, useEffect, useMemo } from "react";
// import './RegistrationEmployee.css'
// import GoogleIcon from '@mui/icons-material/Google';
import {
  LoaderShow,
  LoaderHide,
  isValidEmail,
  isValidName,
  isValidPhoneNumber,
  getCoutryFromPhoneCode,
} from "../../../../../Helpers/Utility";
import { postAPI } from "../../../../../Services/Api";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import loader from "../../../../../Assets/Images/loader.svg";
import googleIcon from "../../../../../Assets/Images/RedGoole.png";

import { auth, provider } from "../../../../Firebase/firebase";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithCredential,
  getAuth,
  signInWithPopup,
} from "firebase/auth";

import { useTranslation } from "react-i18next";
import "../../../../../Localization/i18n";
import { Link } from "react-router-dom";

export default function RegistrationEmployer(props) {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);
  // const [showPassword, setShowPassword] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [errors, setErrors] = React.useState({}); // State for validation errors
  // const [firstName, setFirstName] = React.useState('');
  // const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState("");
  // const [password, setPassword] = React.useState('');
  const [phoneNumber, setPhoneNumber] = useState("");
  // const [ setPhoneNumberError] = useState('');
  const phoneInputRef = useRef(null); // Ref for the PhoneInput component
  const [isOTPSend, setIsOTPSend] = useState("");
  const [fullName, setFullName] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const appVerifier = useMemo(() => props.appVerifier, [props]);
  // const [appVerifier, setAppVerifier] = useState(false);
  useEffect(() => {
    LoaderHide();
    if (props?.employerUserData?.registrationDataEmployer) {
      setFullName(props?.employerUserData?.registrationDataEmployer?.fullName);
      setUserName(props?.employerUserData?.registrationDataEmployer?.userName);
      setEmail(props?.employerUserData?.registrationDataEmployer?.email);
    }
  }, []);

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    setPhoneNumberError("");
  };

  const getUserExistance = async () => {
    try {
      const dataToSend = {
        phoneNumber: `+${phoneNumber}`,
        email: email,
        userName: userName,
        role: "employer",
      };
      const response = await postAPI("user/checkExistence", dataToSend);
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  };

  // const getUserExistanceDetails = async () => {
  //   try {
  //     const dataToSend = {
  //       phoneNumber: `+${phoneNumber}`,
  //       email: email,
  //       userName: userName,
  //       role: "employer"
  //     };
  //     const response = await postAPI('user/checkExistence', dataToSend);
  //     return response

  //   } catch (error) {
  //     toast.error(error.message)
  //   }
  // }

  // const handleSendCode = async () => {

  //   const checkUserDetails = await getUserExistance()

  //   console.log("checkUserDetails======", checkUserDetails)
  //   console.log("phoneNumberError======", phoneNumberError)

  //   if (checkUserDetails === undefined) {
  //     return
  //   }

  //   if (!phoneNumber) {
  //     setPhoneNumberError('Please enter phone number');
  //   } else if (!isValidPhoneNumber(phoneNumber)) {
  //     setPhoneNumberError('Please enter a valid phone number');
  //   } else {

  //     const dataToSend = {
  //       phoneNumber: `+${phoneNumber}`,
  //       role: "employer"
  //     }

  //     try {
  //       const response = await postAPI('auth/send-otp', dataToSend);
  //       if (response.statusCode === 200) {
  //         setIsOTPSend(response.message)
  //         localStorage.setItem("mobile", response.otp)
  //         localStorage.setItem("phoneNumber", phoneNumber)
  //         toast.success(response.message);
  //         setPhoneNumberError('');
  //       } else {
  //         toast.error(response.message);
  //       }
  //     } catch (error) {
  //       toast.error(error.message);
  //     }

  //   }
  // };

  // const handleKeyPress = (e) => {
  //   if (e.key === 'Enter') {
  //     e.preventDefault(); // Prevent form submission
  //     // handleSendCode();
  //   }
  // };

  const renderInput = ({ onChange, onBlur, onFocus }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <PhoneInput
        placeholder="0000000000"
        country={"us"}
        value={phoneNumber}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        inputStyle={{
          background: isFocused ? "#FFFFFF" : "transparent",
          borderColor: isFocused ? "#0B3013" : "#E0E0E0",
          boxShadow: isFocused ? "0px 4px 10px 3px rgba(0,0,0,0.11)" : "none",
          width: "100%",
          padding: "12px 16px",
          borderRadius: "4px",
          outline: "none",
          boxSizing: "border-box",
          fontSize: "16px",
        }}
        containerStyle={{ width: "100%" }}
        inputProps={{ autoFocus: false }}
      />
      <span style={{ marginLeft: "5px", fontSize: "16px" }}>
        {phoneNumber ? `(+${phoneNumber.replace(/\D/g, "")})` : ""}
      </span>
    </div>
  );

  const handleFocus = () => {
    setIsFocused(false);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  // const handleTogglePasswordVisibility = () => {
  //   setShowPassword(!showPassword);
  // };

  const handleTermsCheckboxChange = (event) => {
    setTermsChecked(event.target.checked);
    setErrors((prevErrors) => ({
      ...prevErrors,
      terms: event.target.checked
        ? ""
        : "You must agree to the Terms & Conditions",
    }));
  };

  const [confirmationResult, setConfirmationResult] = useState(null);

  const handleSignUp = async (event) => {
    // Perform validation before proceeding with signup
    const newErrors = {};

    if (!fullName?.trim()) {
      newErrors.fullName = t("FullNameIsRequired");
    } else if (!isValidName(fullName)) {
      newErrors.fullName = t("FullNameAtleastThree");
    }

    if (!userName?.trim()) {
      newErrors.userName = t("UserNameIsRequired");
    } else if (!isValidName(userName)) {
      newErrors.userName = t("UserNameIs3Characters");
    }

    // if (!lastName.trim()) {
    //   newErrors.lastName = 'Last Name is required';
    // } else if (!isValidName(lastName)) {
    //   newErrors.lastName = 'Last  name should be more than 5  characters';
    // }

    if (!email?.trim()) {
      newErrors.email = t("EmailIsRequired");
    } else if (!isValidEmail(email)) {
      newErrors.email = t("InvalidEmailFormat");
    }

    if (!phoneNumber) {
      newErrors.phoneNumber = t("PleaseEnterPhoneNumber");
    } else if (!isValidPhoneNumber(phoneNumber)) {
      newErrors.phoneNumber = t("PleaseEnterValidNumber");
    }

    // if (!isOTPSend) {
    //   newErrors.isSendOTP = '';
    // }

    // if (!password.trim()) {
    //   newErrors.password = 'Password is required';
    // } else if (password.length < 6 || !hasSpecialCharacter(password)) {
    //   newErrors.password =
    //     'Password must be at least 6 characters long and include at least one special character';
    // }

    if (!termsChecked) {
      newErrors.terms = t("YouMustAgree");
    }

    // const checkUserExistOrNot = await getUserExistance()

    // console.log("checkUserExistOrNot=====", checkUserExistOrNot?.message)

    // if (checkUserExistOrNot === undefined) {
    //   newErrors.checkuser = "email already exists"
    // }

    // Update the errors state with new validation errors
    setErrors(newErrors);

    // If there are no errors, proceed with signup
    if (Object.keys(newErrors).length === 0) {
      try {
        LoaderShow();

        const checkUserExistOrNot = await getUserExistance();

        if (checkUserExistOrNot.statusCode === 200) {
          const dataToSend = {
            phoneNumber: `+${phoneNumber}`,
            role: "employer",
          };

          try {
            // Simple send-otp method
            // const response = await postAPI('auth/send-otp', dataToSend);
            // if (response.statusCode === 200) {
            //   setIsOTPSend(response.message)
            //   localStorage.setItem("mobile", response.otp)
            //   localStorage.setItem("phoneNumber", phoneNumber)
            //   toast.success(response.message);
            //   LoaderHide()
            //   setPhoneNumberError('');
            //   const dataToSendNextPage = {
            //     fullName: fullName,
            //     userName: userName,
            //     email: email,
            //     phoneNumber: `+${phoneNumber}`,
            //   };
            //   props.SetEmployerUserDataObject('registrationDataEmployer', dataToSendNextPage);
            //   props.handleNextStepEmployer() // Call the prop function for signup
            // } else {
            //   toast.error(response.message);
            //   LoaderHide()
            // }

            // SignIn with firebase

            // Initialize reCAPTCHA verifier
            let newAppVerifier;
            if (!appVerifier) {
              newAppVerifier = new RecaptchaVerifier(
                auth,
                "recaptcha-container",
                {
                  size: "invisible",
                  callback: (response) => {
                    // reCAPTCHA solved - will proceed with submit function
                  },
                  "expired-callback": () => {
                    // Response expired. Ask user to solve reCAPTCHA again.
                  },
                }
              );
              // setAppVerifier(newAppVerifier);
            }

            const response = await signInWithPhoneNumber(
              auth,
              "+" + phoneNumber,
              appVerifier || newAppVerifier
            );
            // .then(async (confirmationResult) => {
            //   co = confirmationResult
            //   setConfirmationResult(confirmationResult);
            //   console.log("confirmationResult======", confirmationResult)
            //   console.log("confirm======", confirmationResult?.confirm())

            //   const verificationId = confirmationResult?.verificationId;
            //   localStorage.setItem('verificationId', verificationId);

            //   LoaderHide()
            //   setPhoneNumberError('');
            //   const dataToSendNextPage = {
            //     fullName: fullName,
            //     userName: userName,
            //     email: email,
            //     phoneNumber: `+${phoneNumber}`,
            //     verifyOtpnumber: confirmationResult?.verificationId,
            //   };
            //   // props.SetEmployerUserDataObject('registrationDataEmployer', dataToSendNextPage);
            //   // props.handleNextStepEmployer() // Call the prop function for signup
            // })
            // .catch((error) => {
            //   console.error('Error during sign-in:', error);
            //   alert('Error during sign-in. Please try again.');
            // });

            if (response !== undefined) {
              localStorage.setItem("optconfirm", JSON.stringify(response));
              const dataToSendNextPage = {
                fullName: fullName,
                userName: userName,
                email: email,
                phoneNumber: `+${phoneNumber}`,
                response: response,
                location: getCoutryFromPhoneCode(`+${phoneNumber}`),
              };
              props.SetEmployerUserDataObject(
                "registrationDataEmployer",
                dataToSendNextPage
              );
              props.handleNextStepEmployer(); // Call the prop function for signup
            }
            LoaderHide();
          } catch (error) {
            if (error.message.includes("auth/too")) {
              toast.error(
                "Too many request sent at one time. Please try again later!"
              );
            } else if (error.message.includes("invalid")) {
              toast.error("Incorrect OTP, Please try again!");
            } else {
              toast.error(error.message);
            }
            LoaderHide();
          }
        }
      } catch (error) {
        console.error("Failed to post data:===", error.message);
        LoaderHide();
      }
    }
  };

  const signInGoogle = async (ev) => {
    // ev.preventDefault();
    // const auth = getAuth();
    // LoaderShow();
    // try {
    //   const result = await signInWithPopup(auth, provider);
    //   console.log(result);
    // } catch (err) {}
    // LoaderHide();
  };

  return (
    <Box sx={{ p: 14 }} className="sideBg">
      <div
        id="hideloding"
        className="loding-display"
        style={{ display: "none" }}
      >
        <img src={loader} alt="loader-img" />
      </div>
      <Box sx={{ paddingTop: 4 }}>
        <Box className="">
          <Typography
            variant="h5"
            sx={{
              display: "flex",
              textAlign: "center",
              fontWeight: 700,
              pb: 2,
              fontFamily: "inherit",
            }}
            className="registerHead"
          >
            {t("Register")}{" "}
          </Typography>
          <p className="beginText">{t("WorkWaveHub")}</p>
        </Box>
        <Box sx={{ width: "100%", marginTop: 5 }}>
          <div>
            <FormLabel
              sx={{ display: "block", textAlign: "start", py: 1, fontSize: 14 }}
            >
              {" "}
              {t("First")}
            </FormLabel>
            <TextField
              variant="outlined"
              sx={{
                background: isFocused ? "#FFFFFF" : "transparent",
                borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                boxShadow: isFocused
                  ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                  : "none",
              }}
              fullWidth={true}
              placeholder={t("First")}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              error={!!errors.fullName} // Set error state based on validation
              helperText={errors.fullName} // Display error message if there's an error
            />
          </div>
          <div>
            <FormLabel
              sx={{ display: "block", textAlign: "start", py: 1, fontSize: 14 }}
            >
              {" "}
              {t("User")}*
            </FormLabel>
            <TextField
              variant="outlined"
              sx={{
                background: isFocused ? "#FFFFFF" : "transparent",
                borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                boxShadow: isFocused
                  ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                  : "none",
              }}
              fullWidth={true}
              placeholder={t("User")}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              error={!!errors.userName} // Set error state based on validation
              helperText={errors.userName} // Display error message if there's an error
            />
          </div>
          <Box sx={{ my: 1 }}>
            <FormLabel
              sx={{ display: "block", textAlign: "start", py: 1, fontSize: 14 }}
            >
              {t("EmailAddd")}
            </FormLabel>
            <TextField
              variant="outlined"
              sx={{
                background: isFocused ? "#FFFFFF" : "transparent",
                borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                boxShadow: isFocused
                  ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                  : "none",
              }}
              fullWidth={true}
              placeholder={t("EnterEmailAdd")}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!errors.email}
              helperText={errors.email}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <div>
              <FormLabel
                sx={{
                  display: "block",
                  textAlign: "start",
                  py: 1,
                  fontSize: 14,
                }}
              >
                {t("PhoneNo")}*
              </FormLabel>
              <PhoneInput
                country={"us"}
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                inputComponent={renderInput}
                inputRef={phoneInputRef} // Assign the ref to PhoneInput
                // onKeyDown={handleKeyPress} // Handle key press events
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
              />
              {errors.phoneNumber && (
                <Typography
                  variant="body2"
                  sx={{ color: "#d32f2f", textAlign: "start" }}
                >
                  {errors.phoneNumber}
                </Typography>
              )}
            </div>
            {/* <Box sx={{ my: 2 }}>
              <Button
                onClick={handleSendCode}
                // onClick={props.handleNextStepEmployer}
                className="SignUpBtn"
                sx={{ textTransform: "capitalize", width: 100, color: "white" }}
              >
                Send Code
              </Button>
            </Box> */}
          </Box>
          {/* <Box sx={{ my: 1 }}>
            <FormLabel sx={{ display: 'block', textAlign: 'start', py: 1, fontSize: 14 }}>Create password*</FormLabel>
            <TextField
              variant='outlined'
              sx={{
                background: isFocused ? '#FFFFFF' : 'transparent',
                borderColor: isFocused ? '#0B3013' : '#E0E0E0',
                boxShadow: isFocused ? '0px 4px 10px 3px rgba(0,0,0,0.11)' : 'none',
              }}
              fullWidth={true}
              placeholder='Enter Password'
              type={showPassword ? 'text' : 'password'} // Change the type dynamically
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!errors.password}
              helperText={errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box> */}
          <Box
            sx={{
              my: 1,
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Checkbox
              checked={termsChecked}
              onChange={handleTermsCheckboxChange}
            />
            <a
              style={{ color: "#777777" }}
              href="/terms-and-conditions"
              alt="terms"
            >
              {t("TermsAnd")}
            </a>
          </Box>
          {errors.terms && (
            <Typography
              variant="body2"
              sx={{ color: "#d32f2f", textAlign: "start" }}
            >
              {errors.terms}
            </Typography>
          )}

          <br />
          {errors.isSendOTP && (
            <Typography
              variant="body2"
              sx={{ color: "#d32f2f", alignItems: "left" }}
            >
              {errors.isSendOTP}
            </Typography>
          )}
          {/* <Box sx={{ my: 2, display: "flex", justifyContent: "start" }}>
            <div id="recaptcha-container"></div>
          </Box> */}
          <Box sx={{ my: 2, display: "flex", justifyContent: "start" }}>
            <Button
              // onClick={props.handleNextStepEmployer} // Call the prop function on click
              onClick={handleSignUp}
              className="SignUpBtn"
              sx={{ textTransform: "capitalize", width: 100, color: "white" }}
            >
              {t("SignupOne")}
            </Button>
          </Box>
          {/* <Box sx={{ my: 1 }}>
            <FormLabel
              sx={{
                display: "block",
                textAlign: "center",
                py: 1,
                fontSize: 14,
              }}
            >
              {" "}
              OR
            </FormLabel>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "start" }}>
            <Button
              onClick={signInGoogle}
              className="SignUpGoogle"
              sx={{
                background: "#fff",
                textTransform: "capitalize",
                color: "white",
              }}
              startIcon={<img src={googleIcon} alt="dd" />} // Add the Google icon as the startIcon
            >
              {t("RegisterGoogle")}
            </Button>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
}
