// Home.jsx
import React from "react";
import HomeBanner from "./HomeBanner/HomeBanner";
import HomePartners from "./HomePartners/HomePartners";
import HomeTalentCategory from "./HomeTalentCategory/HomeTalentCategory";
import HomeMakeReal from "./HomeMakeReal/HomeMakeReal";
import HomeTestinomials from "./HomeTestinomials/HomeTestinomials";
import HomeMembership from "./HomeMembership/HomeMembership";
import HomeDonation from "./HomeDonation/HomeDonation";

export default function Home() {
  return (
    <div className="mainHome">
      <HomeBanner/>
      {/* <HomePartners/> */}
      <HomeTalentCategory/>
      <HomeMakeReal/>
      {/* <HomeTestinomials/> */}
      <HomeMembership/>
      <HomeDonation/>
    </div>
  );
}
