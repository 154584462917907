import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import CodeIcon from "@mui/icons-material/Code";
// import VpnKeyIcon from "@mui/icons-material/VpnKey";
import DeleteIcon from "@mui/icons-material/Delete";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import { TextareaAutosize as BaseTextareaAutosize } from "@mui/material";
// import { TextareaAutosize } from '@mui/material';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import loader from "../../../Assets/Images/loader.svg";
import arrowCross from "../../../Assets/Images/arrow-up-right-01-round.png";
import { countries } from "../../../Helpers/common_constants";
import {
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  Typography,
  Avatar,
  FormLabel,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Dropdown from "../../../Components/Dropdown/Dropdown";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
  isValidEmail,
  isValidName,
  isValidPhoneNumber,
} from "../../../Helpers/Utility";
import { deleteAPI, getAPI, postAPI, updateAPI } from "../../../Services/Api";
import { toast } from "react-toastify";
import axios from "axios";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../../Localization/i18n";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import { auth, db } from "../../../Auth/Firebase/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

// import { postAPI } from "../../../Services/Api";

// const blue = {
//   100: "#DAECFF",
//   200: "#b6daff",
//   400: "#3399FF",
//   500: "#007FFF",
//   600: "#0072E5",
//   900: "#003A75",
// };

// const grey = {
//   50: "#F3F6F9",
//   100: "#E5EAF2",
//   200: "#DAE2ED",
//   300: "#C7D0DD",
//   400: "#B0B8C4",
//   500: "#9DA8B7",
//   600: "#6B7A90",
//   700: "#434D5B",
//   800: "#303740",
//   900: "#1C2025",
// };

// const Textarea = styled(BaseTextareaAutosize)(
//   ({ theme }) => `
//   box-sizing: border-box;
//   width: 320px;
//   font-family: 'IBM Plex Sans', sans-serif;
//   font-size: 0.875rem;
//   font-weight: 400;
//   line-height: 1.5;
//   padding: 8px 12px;
//   border-radius: 8px;
//   color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
//   background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
//   border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
//   box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]
//     };

//   &:hover {
//     border-color: ${blue[400]};
//   }

//   }

//   // firefox
//   &:focus-visible {
//     outline: 0;
//   }
// `
// );

const StyledTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  fontFamily: "inherit !important",
  "& .MuiTabs-indicator": {
    backgroundColor: "#0B3013", // Change background color of active tab
  },
});

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontFamily: "inherit !important",
  minWidth: 0,
  marginRight: theme.spacing(4),
  "& .MuiTab-wrapper": {
    flexDirection: "row",
    gap: "8px",
    alignItems: "center", // Align icon and text vertically
  },
  "&.Mui-selected": {
    color: "#0B3013", // Change text color when tab is active
    "& svg": {
      fill: "#0B3013", // Change icon color when tab is active
    },
  },
}));

export default function EmployeerProfile() {
  const [value, setValue] = React.useState(0);
  const [avatar] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [setDepartment] = useState("");
  const [language] = useState("");
  const [languageLevel] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const theme = useTheme();
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [isRegistering, setIsRegistering] = useState(false); // State for registration section visibility
  const [appVerifier, setAppVerifier] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [timer, setTimer] = useState(120); // Initial timer value in seconds
  const [resendDisabled, setResendDisabled] = useState(true); // Resend button disabled state
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    LoaderHide();
  }, []);

  useEffect(() => {
    let intervalId;
    if (isRegistering) {
      if (timer > 0) {
        intervalId = setInterval(() => {
          setTimer((prevTimer) => prevTimer - 1);
        }, 1000);
      } else {
        setResendDisabled(false); // Enable resend button when timer reaches 0
        clearInterval(intervalId);
      }
      return () => clearInterval(intervalId);
    }
  }, [timer, isRegistering]);

  // Define dropdown options

  const handleResendOtp = async () => {
    await handleSignInEmployer();
    setTimer(120);
    setResendDisabled(true);
  };

  const [phoneNumberError, setPhoneNumberError] = useState("");

  const getPhoneNumberExistance = async () => {
    if (!phoneNumber) {
      setPhoneNumberError(t("PleaseEnterPhoneNumber"));
    } else if (!isValidPhoneNumber(phoneNumber)) {
      setPhoneNumberError(t("PleaseEnterValidNumber"));
    } else {
      const dataToSend = {
        phoneNumber: `+${phoneNumber}`,
        role: "employer",
      };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/user/checkExistence`,
          dataToSend
        );
        return response;
      } catch (error) {
        if (error.response) {
          return error.response.data;
        } else if (error.request) {
          console.error("No response received from the server");
        } else {
          console.error("Error:", error.message);
        }
      }
    }
  };

  const handleSignInEmployer = async () => {
    LoaderShow();
    const pnoneNumberExists = await getPhoneNumberExistance();

    if (pnoneNumberExists?.message === "Phone number already exists") {
      LoaderShow();
      if (!phoneNumber) {
        setPhoneNumberError(t("PleaseEnterPhoneNumber"));
      } else if (!isValidPhoneNumber(phoneNumber)) {
        setPhoneNumberError(t("PleaseEnterValidNumber"));
      } else {
        const dataToSend = {
          phoneNumber: `+${phoneNumber}`,
          role: "employer",
        };

        try {
          // before sigin login code
          // const response = await postAPI('auth/send-otp', dataToSend);

          // if (response.statusCode === 200) {
          //   LoaderHide()
          //   localStorage.setItem("mobile", response.otp)
          //   localStorage.setItem("phoneNumber", phoneNumber)
          //   setIsRegistering(true); // Toggle to display registration section
          //   toast.success(response.message);
          //   setPhoneNumberError('');

          // } else {
          //   toast.error(response.message);
          //   LoaderHide()

          // }

          // firebase login
          LoaderShow();
          let newAppVerifier;
          if (!appVerifier) {
            newAppVerifier = new RecaptchaVerifier(
              auth,
              "recaptcha-container",
              {
                size: "invisible",
                callback: (response) => {
                  // reCAPTCHA solved - will proceed with submit function
                },
                "expired-callback": () => {
                  // Response expired. Ask user to solve reCAPTCHA again.
                },
              }
            );
            setAppVerifier(newAppVerifier);
          }
          LoaderShow();

          // Old Promise.race method

          // const timeoutPromise = new Promise((_, reject) => {
          //   setTimeout(() => {
          //     reject(new Error("Timeout: OTP request took too long."));
          //   }, 10000); // 10 seconds timeout
          // });

          // const response = await signInWithPhoneNumber(auth, "+" + phoneNumber, appVerifier)
          // const response = await Promise.race([
          //   signInWithPhoneNumber(auth, `+${phoneNumber}`, appVerifier),
          //   timeoutPromise,
          // ]);
          const response = await signInWithPhoneNumber(
            auth,
            `+${phoneNumber}`,
            appVerifier || newAppVerifier
          );

          // console.log("response======", response);
          LoaderShow();

          if (response !== undefined) {
            LoaderHide();
            localStorage.setItem("mobile", response.otp);
            localStorage.setItem("phoneNumber", phoneNumber);
            setIsRegistering(true); // Toggle to display registration section
            toast.success(response.message);
            setPhoneNumberError("");
            setConfirmationResult(response);
          }
        } catch (error) {
          if (error.message.includes("auth/too")) {
            toast.error(
              "Too many request sent at one time. Please try again later!"
            );
          } else if (error.message.includes("invalid")) {
            toast.error("Incorrect OTP, Please try again!");
          } else {
            toast.error(error.message);
          }
          LoaderHide();
        }
      }
    } else {
      toast.error("This number is not registered!");
      LoaderHide();
    }
    LoaderHide();
  };

  const handleVerifyOTP = async () => {
    LoaderShow();
    // Validate OTP here
    // const validOtp = localStorage.getItem("mobile")
    const phoneNumber = localStorage.getItem("phoneNumber");
    if (!otp) {
      setOtpError(t("PleaseEnterOpt"));
    } else if (otp.length !== 6) {
      setOtpError(t("OTP6Digit"));
    } else {
      setOtpError("");
      const dataToSend = {
        // otp: otp,
        phoneNumber: `+${phoneNumber}`,
        userOTP: otp,
      };

      try {
        LoaderShow();

        // const response = await postAPI('auth/verify-otp', dataToSend);

        // if (response.statusCode === 200) {

        //   const data = {
        //     phoneNumber: `+${phoneNumber}`,
        //   }

        //   const responselogin = await postAPI("user/employer/login", data);
        //   console.log("Post response:", response);
        //   if (responselogin.statusCode === 200) {
        //     LoaderHide()
        //     toast.success(responselogin.message);
        //     setBrowseModalOpen(true);
        //     handleClose();
        //     // navigate("/services");
        //     // window.location.reload(true)
        //     var loginDetails = responselogin.data;
        //     var objLoginData = {
        //       userId: loginDetails._id,
        //       token: responselogin.token,
        //       role: loginDetails.role,
        //       userProfile: loginDetails.profile,
        //       firstName: loginDetails.firstName,
        //       phoneNumber: loginDetails.phoneNumber,
        //     };
        //     localStorage.setItem("loginData", JSON.stringify(objLoginData));
        //     handleClose();
        //   } else {
        //     toast.error("login failed");
        //     LoaderHide()
        //   }
        // } else {
        //   toast.error("Invalid OTP",);
        //   LoaderHide()
        // }

        const response = await confirmationResult?.confirm(otp);

        if (response?.user !== undefined) {
          const data = {
            number: `+${phoneNumber}`,
          };
          const { token } = GetUserDetails();
          const responselogin = await deleteAPI("user/delete", data, token);
          if (responselogin.statusCode === 200) {
            await deleteDoc(doc(db, "users", userDetails.firebaseUID));
            const roomRef = collection(db, "rooms");
            const roomQuery = query(
              roomRef,
              where("userIds", "array-contains", userDetails?.firebaseUID)
            );
            const rooms = await getDocs(roomQuery);
            rooms.forEach(async (documentSnapshot) => {
              await deleteDoc(doc(db, "rooms", documentSnapshot.id));
            });
            toast.success(responselogin.message);
            navigate("/home");
            localStorage.removeItem("loginData");
            localStorage.removeItem("notif-token");
          } else {
            toast.error("Deletetion failed!");
          }
        } else {
          toast.error("Invalid OTP");
          LoaderHide();
        }
      } catch (error) {
        LoaderHide();
        if (error.message.includes("invalid")) {
          toast.error("Invalid OTP");
          return;
        }
        if (error.message.includes("code-expired")) {
          toast.error("OTP expired");
        }
        toast.error(error.message);
      }
    }
  };
  const languageOptions = [
    { value: "demo", label: "demo" },
    { value: "demo1", label: "demo2" },
  ];

  const languageLevelOptions = [
    { value: "demo", label: "demo" },
    { value: "demo1", label: "demo2" },
  ];

  const countryOptions = [
    { value: "usa", label: "USA" },
    { value: "uk", label: "UK" },
    { value: "canada", label: "Canada" },
    { value: "india", label: "India" },
  ];

  const stateOptions = [
    { value: "newyork", label: "New York" },
    { value: "london", label: "London" },
    { value: "toronto", label: "Toronto" },
    { value: "surate", label: "Surat" },
  ];

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];

  const handleFocus = () => {
    setIsFocused(false);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // Function to handle avatar upload
  // const handleAvatarChange = (event) => {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();
  //   reader.onloadend = () => {
  //     setAvatar(reader.result);
  //   };
  //   if (file) {
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
  };

  const renderInput = ({ onChange, onBlur, onFocus }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <PhoneInput
        placeholder="0000000000"
        country={"us"}
        value={phoneNumber}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        inputStyle={{
          background: isFocused ? "#FFFFFF" : "transparent",
          borderColor: isFocused ? "#0B3013" : "#E0E0E0",
          boxShadow: isFocused ? "0px 4px 10px 3px rgba(0,0,0,0.11)" : "none",
          width: "100%",
          padding: "12px 16px",
          borderRadius: "4px",
          outline: "none",
          boxSizing: "border-box",
          fontSize: "16px",
        }}
        containerStyle={{ width: "100%" }}
        inputProps={{ autoFocus: false }}
      />
      <span style={{ marginLeft: "5px", fontSize: "16px" }}>
        {phoneNumber ? `(+${phoneNumber.replace(/\D/g, "")})` : ""}
      </span>
    </div>
  );

  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    gender: "",
    country: "",
    state: "",
    language: "",
    languageLevel: "",
    location: "",
  });

  const [gender, setGender] = useState("");
  const [countrys, setCountrys] = useState("");
  const [states, setStates] = useState("");

  useEffect(() => {
    // Set the initial gender value based on userDetails
    const initialGender =
      genderOptions.find(
        (option) =>
          option?.label?.toLowerCase() === userDetails?.gender?.toLowerCase()
      )?.value || "";
    const initialCountry =
      countryOptions.find(
        (option) =>
          option?.label?.toLowerCase() === userDetails?.location?.toLowerCase()
      )?.label || "";
    const initialState =
      stateOptions.find(
        (option) =>
          option?.label?.toLowerCase() === userDetails?.state?.toLowerCase()
      )?.value || "";
    setGender(initialGender);
    setCountrys(initialCountry);
    setStates(initialState);
  }, []);

  const handleChangegender = (event) => {
    const value = event.target.value;
    setUserDetails({ ...userDetails, gender: value });
  };

  const handleChangeCountrys = (event) => {
    const value = event.target.value;
    setUserDetails({ ...userDetails, location: value });
  };

  const handleChangeStates = (event) => {
    const value = event.target.value;
    setUserDetails({ ...userDetails, state: value });
  };

  const [validationErrors, setValidationErrors] = useState({});

  const validateForm = () => {
    const errors = {};

    if (!userDetails.firstName.trim()) {
      errors.firstName = t("firstNameReq");
    } else if (!isValidName(userDetails.firstName)) {
      errors.firstName = t("firstNameGreater3");
    }

    if (!userDetails?.lastName?.trim()) {
      errors.lastName = t("lastNameReq");
    } else if (!isValidName(userDetails.lastName)) {
      errors.lastName = t("lastNameGreater3");
    }

    if (!userDetails?.state?.trim()) {
      errors.state = t("stateNameReq");
    }

    // if (!userDetails?.email?.trim()) {
    //   errors.email = 'Email is required';
    // } else if (!isValidEmail(userDetails.email)) {
    //   errors.email = 'Invalid email format!';
    // }

    // if (!userDetails.phoneNumber) {
    //   errors.phoneNumber = 'Please enter phone number';
    // } else if (!isValidPhoneNumber(userDetails.phoneNumber)) {
    //   errors.phoneNumber = 'Please enter a valid phone number';
    // }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    getUserDetails();
    getDepositeAmount();
  }, []);

  const getUserDetails = async () => {
    try {
      LoaderShow();
      const token = GetUserDetails().token;
      const response = await getAPI("user/getdetails", token);
      if (response.statusCode === 200) {
        setUserDetails(response.data);
        LoaderHide();
      }
    } catch (error) {
      LoaderHide();
      toast.error(error.message);
    }
  };

  const [depositeAmout, setDepositeAmount] = useState(0);

  const getDepositeAmount = async () => {
    try {
      LoaderShow();
      const token = GetUserDetails().token;
      const response = await getAPI("user/balance", token);
      if (response.statusCode === 200) {
        setDepositeAmount(response?.depositAmount);
        LoaderHide();
      }
    } catch (error) {
      LoaderHide();
      toast.error(error.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      [name]: value,
    }));
  };

  const handleState = (event) => {
    const value = event.target.value;
    setUserDetails((prevFormData) => ({
      ...prevFormData,
      state: value,
    }));
  };

  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const handleFileChanges = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile?.size > 1_048_576) {
      toast.warn("Maximum size of attachments should be less than 2mb.");
      return;
    }
    setFile(selectedFile);

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  // const handleUpload = async () => {
  //   try {
  //     const formData = new FormData();
  //     formData.append('profile', file);

  //     const response = await axios.post('https://dev.api.workwavehub.com/user/register', formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     });

  //     console.log('Image uploaded successfully:==', response.data);
  //     // Handle any further actions after successful upload
  //   } catch (error) {
  //     console.error('Error uploading image:===', error);
  //     // Handle error scenarios
  //   }
  // };

  const updateUser = async () => {
    if (!validateForm()) {
      return;
    }

    LoaderShow();

    const token = GetUserDetails().token;

    let phone;

    if (phoneNumber.length === 0) {
      phone = userDetails.phoneNumber;
    } else {
      phone = `+${phoneNumber}`;
    }
    const dataToSend = new FormData();
    dataToSend.append("firstName", userDetails.firstName);
    dataToSend.append("lastName", userDetails.lastName);
    dataToSend.append("userName", userDetails.userName);
    dataToSend.append("email", userDetails.email);
    dataToSend.append("phoneNumber", phone);
    dataToSend.append("role", userDetails.role);
    dataToSend.append("profile", file);
    dataToSend.append("location", userDetails?.location);
    dataToSend.append("gender", userDetails.gender);
    dataToSend.append("state", userDetails?.state);
    // dataToSend.append('skills', userDetails.skills);

    try {
      const response = await updateAPI(
        `user/update/${userDetails._id}`,
        dataToSend,
        token
      );
      if (response.statusCode === 200) {
        if (userDetails?.firebaseUID)
          await setDoc(doc(db, "users", userDetails.firebaseUID), {
            firstName: response?.data?.firstName || "",
            lastName: response?.data?.lastName || "",
            imageUrl: response?.data?.profile || "",
            createdAt: Timestamp.now(),
            lastSeen: Timestamp.now(),
            metadata: null,
            role: null,
            updatedAt: Timestamp.now(),
          });
        LoaderHide();
        toast.success(response.message);
        window.location.reload(true);
      }
    } catch (error) {
      LoaderHide();
      toast.error(error.message);
    }
  };

  return (
    <>
      <div id="hideloding" className="loding-display">
        <img src={loader} alt="loader-img" />
      </div>
      <div id="recaptcha-container"></div>
      <section className="myProfile">
        <Box sx={{ mb: 4 }}>
          <Typography
            className="employeeHead1"
            variant="h4"
            sx={{ pb: 1, fontWeight: 600 }}
          >
            {t("MyProfile")}
          </Typography>
          <Typography
            variant="p"
            sx={{ fontSize: 18, color: "#777" }}
          ></Typography>
        </Box>
        <Box
          className="plZero prZero"
          sx={{
            backgroundColor: "#fff",
            px: 4,

            my: 4,
          }}
        >
          <StyledTabs
            sx={{ display: "flex" }}
            value={value}
            onChange={handleChange}
            variant={isMobile ? "scrollable" : "standard"}
            scrollButtons={isMobile ? "auto" : "off"}
            allowScrollButtonsMobile={isMobile}
          >
            <StyledTab icon={<AccountCircleIcon />} label={t("Profile")} />
            <StyledTab
              icon={<DeleteIcon />}
              label={t("DeleteAccountEmployee")}
            />
          </StyledTabs>

          <Box sx={{ py: 4, px: 2 }}>
            {value === 0 && (
              <Box>
                <Typography
                  variant="h6"
                  sx={{ color: "#000", fontWeight: 600, pb: 2, px: 0 }}
                >
                  {t("Profile")}
                </Typography>
                <Box sx={{ py: 4, borderTop: 1, borderColor: "#E9E9E9" }}>
                  <Box
                    className="flexColumnRespo"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {previewUrl ? (
                        <Avatar
                          alt="Profile Picture"
                          src={previewUrl}
                          sx={{
                            width: 120,
                            height: 120,
                            border: 1,
                            borderColor: "#ccc",
                          }}
                          // onClick={() =>
                          //   document.getElementById("avatar-upload").click()
                          // }
                        />
                      ) : (
                        <Avatar
                          alt="Profile Picture"
                          src={userDetails?.profileImageUrl}
                          sx={{
                            width: 120,
                            height: 120,
                            border: 1,
                            borderColor: "#ccc",
                          }}
                          // onClick={() =>
                          //   document.getElementById("avatar-upload").click()
                          // }
                        />
                      )}

                      {avatar && (
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            fontSize: 20,
                            borderRadius: 2,
                            padding: 0.4,
                            marginLeft: 10,
                          }}
                        >
                          <EditIcon
                            color="secondary"
                            sx={{
                              backgroundColor: "#0B3013",
                              fontSize: 20,
                              padding: 0.4,
                              borderRadius: 2,
                              color: "#fff",
                            }}
                            onClick={() =>
                              document.getElementById("avatar-upload").click()
                            }
                          />
                        </Box>
                      )}
                      {!avatar && (
                        <label
                          htmlFor="avatar-upload"
                          style={{
                            position: "absolute",
                            bottom: 0,
                            left: 86,
                            right: 0,
                            cursor: "pointer",
                          }}
                        >
                          <EditIcon
                            color="secondary"
                            sx={{
                              backgroundColor: "#0B3013",
                              fontSize: 20,
                              padding: 0.4,
                              borderRadius: 2,
                              color: "#fff",
                            }}
                          />
                        </label>
                      )}
                      {/* <img height={"100px"} width={"100px"} src={userDetails?.profileImageUrl} alt={`${userDetails?.firstName} ${userDetails?.lastName}`} /> */}
                      <input
                        accept="image/*"
                        id="avatar-upload"
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleFileChanges}
                      />
                    </Box>
                    <Box>
                      <Typography
                        className="filterSec"
                        variant="p"
                        sx={{ px: 3, display: "block" }}
                      >
                        {/* Max file size is 1MB, Minimum dimension: 330x300 And
                        Suitable files are <br />
                        .jpg & .png */}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ my: 1 }}>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 14,
                          }}
                        >
                          {t("First")}
                        </FormLabel>
                        <TextField
                          variant="outlined"
                          sx={{
                            background: isFocused ? "#FFFFFF" : "transparent",
                            borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                            boxShadow: isFocused
                              ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                              : "none",
                          }}
                          fullWidth={true}
                          placeholder={t("First")}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          id="firstName"
                          name="firstName"
                          onChange={handleInputChange}
                          value={userDetails?.firstName}
                          helperText={validationErrors.firstName}
                          error={!!validationErrors.firstName}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ my: 1 }}>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 14,
                          }}
                        >
                          {t("Last")}
                        </FormLabel>
                        <TextField
                          variant="outlined"
                          sx={{
                            background: isFocused ? "#FFFFFF" : "transparent",
                            borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                            boxShadow: isFocused
                              ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                              : "none",
                          }}
                          fullWidth={true}
                          placeholder={t("Last")}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          id="lastName"
                          name="lastName"
                          onChange={handleInputChange}
                          value={userDetails?.lastName}
                          helperText={validationErrors.lastName}
                          error={!!validationErrors.lastName}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ my: 1 }}>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 14,
                          }}
                        >
                          {t("EmailAdd")}
                        </FormLabel>
                        <TextField
                          disabled
                          variant="outlined"
                          sx={{
                            background: isFocused ? "#FFFFFF" : "transparent",
                            borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                            boxShadow: isFocused
                              ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                              : "none",
                          }}
                          fullWidth={true}
                          placeholder={t("EmailAdd")}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          id="email"
                          name="email"
                          onChange={handleInputChange}
                          value={userDetails?.email}
                          helperText={validationErrors.email}
                          error={!!validationErrors.email}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ my: 1 }}>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 14,
                          }}
                        >
                          {t("PhoneNo")}
                        </FormLabel>
                        <TextField
                          disabled
                          fullWidth={true}
                          onChange={handlePhoneNumberChange}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          inputComponent={renderInput}
                          id="phone"
                          name="phone"
                          value={userDetails?.phoneNumber}
                          helperText={validationErrors.phoneNumber}
                          error={!!validationErrors.phoneNumber}
                        />
                        {validationErrors.phoneNumber && (
                          <Typography variant="body2" sx={{ color: "#d32f2f" }}>
                            {validationErrors.phoneNumber}
                          </Typography>
                        )}
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl sx={{ mt: 2 }} fullWidth>
                        <InputLabel id="gender-select-label">
                          {t("Gender")}
                        </InputLabel>
                        <Select
                          labelId="gender-select-label"
                          id="gender-select"
                          value={userDetails?.gender}
                          label="Gender"
                          onChange={handleChangegender}
                        >
                          {genderOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            // py: 1.4,
                            mt: 0,
                            fontSize: 14,
                          }}
                        >
                          {t("DepositeAmount")}
                        </FormLabel>
                        <TextField
                          disabled
                          variant="outlined"
                          sx={{
                            background: isFocused ? "#FFFFFF" : "transparent",
                            borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                            boxShadow: isFocused
                              ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                              : "none",
                          }}
                          fullWidth={true}
                          placeholder={t("DepositeAmount")}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          id='sate'
                          name="sate"
                          value={depositeAmout}
                        // onChange={handleState}
                        // value={userDetails?.state}
                        // helperText={validationErrors.state}
                        // error={!!validationErrors.state}
                        />
                      </FormControl>
                    </Grid> */}

                    <Grid item xs={12} md={6}>
                      <FormControl sx={{ mt: 2 }} fullWidth>
                        <InputLabel id="gender-select-label">
                          {t("Country")}
                        </InputLabel>
                        <Select
                          disabled
                          labelId="gender-select-label"
                          id="gender-select"
                          value={userDetails?.location}
                          label={t("Country")}
                          onChange={handleChangeCountrys}
                        >
                          {countries?.map((option) => (
                            <MenuItem key={option.value} value={option.label}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {/* <Box>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 14,
                          }}
                        >
                          Country
                        </FormLabel>
                        <Dropdown
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                          options={countryOptions}
                          label="Department"
                        />
                      </Box> */}
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            // py: 1.4,
                            mt: -0.5,
                            fontSize: 14,
                          }}
                        >
                          {t("State")}
                        </FormLabel>
                        <TextField
                          variant="outlined"
                          sx={{
                            background: isFocused ? "#FFFFFF" : "transparent",
                            borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                            boxShadow: isFocused
                              ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                              : "none",
                          }}
                          fullWidth={true}
                          placeholder={t("State")}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          id="sate"
                          name="sate"
                          onChange={handleState}
                          value={userDetails?.state}
                          helperText={validationErrors.state}
                          error={!!validationErrors.state}
                        />
                      </FormControl>
                      {/* <Box>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 14,
                          }}
                        >
                          City
                        </FormLabel>
                        <Dropdown
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          options={cityOptions}
                          label="Department"
                        />
                      </Box> */}
                    </Grid>

                    {/* <Grid item xs={12} md={6}>
                      <Box>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 14,
                          }}
                        >
                          Language
                        </FormLabel>
                        <Dropdown
                          value={language}
                          onChange={(e) => setDepartment(e.target.value)}
                          options={languageOptions}
                          label="Department"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 14,
                          }}
                        >
                          Languages Level
                        </FormLabel>
                        <Dropdown
                          value={languageLevel}
                          onChange={(e) => setDepartment(e.target.value)}
                          options={languageLevelOptions}
                          label="Department"
                        />
                      </Box>
                    </Grid> */}
                  </Grid>

                  <Box sx={{ my: 2, display: "flex", justifyContent: "start" }}>
                    <Button
                      className="SignUpBtn"
                      sx={{
                        textTransform: "capitalize",
                        py: "8px !important",
                        px: "24px !important",
                        width: "auto !important",
                        borderRadius: 12,
                        maxWidth: "auto !important",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                      }}
                      endIcon={
                        <Box
                          component="img"
                          src={arrowCross}
                          sx={{ width: 24, height: 24, marginRight: 1 }}
                        />
                      }
                      onClick={updateUser}
                    >
                      {t("Save")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
            {value === 1 && (
              <>
                {!isRegistering && (
                  <Box sx={{ marginTop: 0 }}>
                    <div>
                      <FormLabel
                        sx={{
                          display: "block",
                          textAlign: "start",
                          py: 1,
                          fontSize: 14,
                        }}
                      >
                        {t("PhoneNo")}
                      </FormLabel>
                      <PhoneInput
                        country={"us"}
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        inputComponent={renderInput}
                      />
                      {phoneNumberError && (
                        <Typography variant="body2" sx={{ color: "red" }}>
                          {phoneNumberError}
                        </Typography>
                      )}
                    </div>
                    <Button
                      onClick={handleSignInEmployer} // Toggle to display registration section
                      className="SignUpBtn"
                      sx={{
                        my: 4,
                        textTransform: "capitalize",
                        width: 100,
                        color: "white",
                      }}
                    >
                      {t("getOTP")}
                    </Button>
                  </Box>
                )}

                {isRegistering && ( // Conditionally render the registration section based on state
                  <Box sx={{ width: "100%", marginTop: 8 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h5"
                          sx={{ textAlign: "start", fontWeight: 700 }}
                        >
                          {t("OTP")}{" "}
                        </Typography>
                        <p className="beginText">{t("WeOTP")}</p>
                      </Box>

                      <Box sx={{ pt: 3, maxWidth: "500px" }}>
                        <OtpInput
                          inputStyle={{
                            border: "2px solid #0B3013",
                            borderRadius: "8px",
                            width: "54px",
                            height: "54px",
                            fontSize: "18px",
                            color: "#000",
                            fontWeight: "400",
                            caretColor: "blue",
                          }}
                          focusStyle={{
                            border: "1px solid #CFD3DB",
                            outline: "none",
                          }}
                          value={otp}
                          onChange={setOtp}
                          numInputs={6}
                          inputType="password"
                          renderSeparator={
                            <span
                              style={{ paddingLeft: 5, paddingRight: 5 }}
                            ></span>
                          }
                          renderInput={(props) => (
                            <input {...props} className="otpInput" />
                          )}
                          containerStyle="otpContainer" // Apply the custom CSS class
                        />
                        {otpError && (
                          <Typography
                            variant="body2"
                            sx={{ color: "red", mt: 1 }}
                          >
                            {otpError}
                          </Typography>
                        )}
                      </Box>

                      <Box
                        sx={{
                          pt: 5,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span className="timerText">{`${
                          timer < 10 ? "0" : ""
                        }${timer} Sec`}</span>
                        <span
                          style={{
                            cursor: resendDisabled ? "disabled" : "pointer",
                          }}
                          className={`resend ${
                            resendDisabled ? "disabled" : ""
                          }`}
                          onClick={
                            !resendDisabled ? handleResendOtp : undefined
                          }
                        >
                          {t("resendOTP")}
                        </span>
                      </Box>

                      <Box
                        sx={{
                          mt: 4,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          onClick={handleVerifyOTP}
                          className="SignUpBtn"
                          sx={{
                            textTransform: "capitalize",
                            width: 100,
                            color: "white",
                          }}
                        >
                          {t("Verify")}
                        </Button>
                      </Box>

                      <Box
                        sx={{
                          my: 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FormLabel
                          sx={{
                            display: "flex",
                            textAlign: "center",
                            py: 1,
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: 14,
                          }}
                        >
                          <LockOutlinedIcon fontSize="12" sx={{ px: 1 }} />
                          {t("YourInfo")}
                        </FormLabel>
                      </Box>
                    </Box>
                  </Box>
                )}
              </>
            )}
            {value === 2 && (
              <Box sx={{ width: "100%", marginTop: 8 }}>
                <Box>
                  <Typography
                    variant="h5"
                    sx={{ textAlign: "start", fontWeight: 700 }}
                  >
                    OTP Verification{" "}
                  </Typography>
                  <p className="beginText">
                    We have sent an OTP to your phone number !{" "}
                  </p>
                </Box>

                <Box sx={{ pt: 3 }}>
                  <OtpInput
                    inputStyle={{
                      border: "2px solid #0B3013",
                      borderRadius: "8px",
                      width: "54px",
                      height: "54px",
                      fontSize: "18px",
                      color: "#000",
                      fontWeight: "400",
                      caretColor: "blue",
                    }}
                    focusStyle={{
                      border: "1px solid #CFD3DB",
                      outline: "none",
                    }}
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    inputType="password"
                    renderSeparator={
                      <span style={{ paddingLeft: 5, paddingRight: 5 }}></span>
                    }
                    renderInput={(props) => (
                      <input {...props} className="otpInput" />
                    )}
                  />
                  {otpError && (
                    <Typography variant="body2" sx={{ color: "red", mt: 1 }}>
                      {otpError}
                    </Typography>
                  )}
                </Box>

                <Box sx={{ pt: 5 }}>
                  <span className="timerText">{`00:${
                    timer < 10 ? "0" : ""
                  }${timer} Sec`}</span>

                  <br />
                  <br />
                  <span
                    className={`resend ${resendDisabled ? "disabled" : ""}`}
                    onClick={!resendDisabled ? handleResendOtp : undefined}
                  >
                    Resend OTP
                  </span>
                </Box>
                <Box sx={{ mt: 4 }}>
                  <Button
                    onClick={handleVerifyOTP}
                    className="SignUpBtn"
                    sx={{
                      textTransform: "capitalize",
                      width: 100,
                      color: "white",
                    }}
                  >
                    {t("Verify")}
                  </Button>
                </Box>
                <Box sx={{ my: 1 }}>
                  <FormLabel
                    sx={{
                      display: "flex",
                      textAlign: "center",
                      py: 1,
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: 14,
                    }}
                  >
                    <LockOutlinedIcon fontSize="12" sx={{ px: 1 }} />
                    {t("YourInfo")}
                  </FormLabel>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </section>
    </>
  );
}
