// Dropdown.js
import React from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/system';
import customArrowImage from '../../Assets/Images/caret.png'; // Import your custom arrow image

import { useTranslation } from "react-i18next";
import "../../Localization/i18n";
// StyledSelect component to customize the arrow
const StyledSelect = styled(Select)({
  'MuiSelect-icon': {
    display: 'none'
  },
  '& .MuiSelect-select': {
    paddingRight: '40px', // Adjust paddingRight to accommodate the custom arrow image
    backgroundImage: `url(${customArrowImage})`, // Set custom image as background
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 12px center', // Adjust the position of the image
    '-moz-appearance': 'none', /* Firefox */
    '-webkit-appearance': 'none', /* Safari and Chrome */
    appearance: 'none', /* Hide the default arrow */
  },
});

const Dropdown = ({ value, onChange, options, label }) => {

  const { t } = useTranslation();

  return (
    <FormControl fullWidth>
      <StyledSelect
        value={value}
        onChange={onChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        MenuProps={{ disableScrollLock: true }}
      >
        <MenuItem value="">
          <div>{t("Select")}</div>
        </MenuItem>
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default Dropdown;
