import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import "./Signup.css";
import Typography from "@mui/material/Typography";
import { Button, Grid, useTheme } from "@mui/material";
// import ModalAuthBanner from "../../Assets/Images/ModalAuthBanner.png"; // Import your image component here
import Employee from "../../Assets/Images/Employee.png"; // Import your image component here
import ModalAuthBannerCommon from "../../Assets/Images/ModalAuthBannerCommon.png"; // Import your image component here

import Employeer from "../../Assets/Images/Employeer.png"; // Import your image component here
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RegistrationEmployee from "./SignupSteps/Employee/RegistrationEmployee/RegistrationEmployee";
import RegistrationEmployer from "./SignupSteps/Employer/RegistrationEmployer/RegistrationEmployer";
import MobileVerificationEmployee from "./SignupSteps/Employee/MobileVerificationEmployee/MobileVerificationEmployee";
import OTPVerficationEmployee from "./SignupSteps/Employee/OTPVerficationEmployee/OTPVerficationEmployee";
import OTPVerficationEmployer from "./SignupSteps/Employer/OTPVerficationEmployer/OTPVerficationEmployer";
import SkillSetEmployee from "./SignupSteps/Employee/SkillSetEmployee/SkillSetEmployee";
import SkillSetEmployer from "./SignupSteps/Employer/SkillSetEmployer/SkillSetEmployer";
import ProfileEmployee from "./SignupSteps/Employee/ProfileEmployee/ProfileEmployee";
import ProfileEmployer from "./SignupSteps/Employer/ProfileEmployer/ProfileEmployer";
import AboutEmployee from "./SignupSteps/Employee/AboutEmployee/AboutEmployee";
import WhatYouNeedEmployer from "./SignupSteps/Employer/WhatYouNeedEmployer/WhatYouNeedEmployer";
import SignIn from "../Signin/Signin";
import { Paper } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BrowseEmployer from "./SignupSteps/Employer/BrowseEmployer/BrowseEmployer";
import ModalBannerText1 from "./ModalBannerText/ModalBannerText1";
import ModalBannerText2 from "./ModalBannerText/ModalBannerText2";

// import EmployeeBanner1 from '../../Assets/Images/MainBannerContentEmployee1.png'
// import EmployeeBanner2  from '../../Assets/Images/MainBannerContentEmployee2.png'
import { useTranslation } from "react-i18next";
import "../../Localization/i18n";

import loader from "../../Assets/Images/loader.svg";
import PaymentAddressEmployee from "./SignupSteps/Employee/PaymentAddressEmployee/PaymentAddressEmployee";
import { RecaptchaVerifier } from "firebase/auth";
import { auth } from "../Firebase/firebase";

const Signup = ({ open, handleClose, toggleSignUpModal }) => {
  const theme = useTheme();
  const [appVerifier, setAppVerifier] = useState(false);

  const { t } = useTranslation();
  const [role, setRole] = useState("Employee");
  const [section, setSection] = useState(1);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(true); // Initially show the sign-up modal

  // const bgImage = ModalAuthBannerCommon; // Assuming YourImage is the variable containing the image URL

  useEffect(() => {
    if (open) {
      setRole("Employee");
      setSection(1);
    }
  }, [open]);

  useEffect(() => {
    let newAppVerifier;
    if (!appVerifier) {
      newAppVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved - will proceed with submit function
        },
        "expired-callback": () => {
          // Response expired. Ask user to solve reCAPTCHA again.
        },
      });
      setAppVerifier(newAppVerifier);
    }
  }, []);

  const [employeeUserData, setEmployeeUserData] = useState({
    registrationDataEmployee: {},
    mobileVerificationDataEmployee: {},
    otpVerificationDataEmployee: {},
    skillsetEmployee: {},
    profileEmployee: {},
    skillsetIDEmployee: {},
  });

  const [employerUserData, setEmployerUserData] = useState({
    registrationDataEmployer: {},
    mobileVerificationDataEmployer: {},
    otpVerificationDataEmployer: {},
    skillsetEmployer: {},
    profileEmployer: {},
    skillsetIDEmployer: {},
  });

  const SetEmployerUserDataObject = (dataType, data) => {
    setEmployerUserData((prevUserData) => ({
      ...prevUserData,
      [dataType]: data,
    }));
  };

  const SetEmployeeUserDataObject = (dataType, data) => {
    setEmployeeUserData((prevUserData) => ({
      ...prevUserData,
      [dataType]: data,
    }));
  };

  const handleChangeRole = (selectedRole) => {
    setRole(selectedRole);
    setSection(section + 1); // Move to the next section after selecting a role
  };

  // const handleNextSection = () => {
  //   if (section === 7) {
  //     handleClose(); // Close the modal when reaching the last section
  //   } else {
  //     setSection(section + 1);
  //   }
  // };

  // const handleNextStep = () => {
  //   if (role === "Employee" && section === 7) {
  //     handleClose(); // Close the modal when reaching the last section for Employee
  //   } else if (role === "Employer" && section === 7) {
  //     handleClose(); // Close the modal when reaching the last section for Employer
  //   } else {
  //     setSection(section + 1);
  //   }
  // };

  const handleNextStep = () => {
    if (role === "Employee" && section === 8) {
      handleClose(); // Close the modal when reaching the last section for Employee
    } else if (role === "Employer" && section === 7) {
      handleClose(); // Close the modal when reaching the last section for Employer
    } else {
      setSection(section + 1);
    }
  };

  const handleSkip = () => {
    if (section < 8) {
      setSection(section + 1);
    }
  };

  const handleBackStep = () => {
    setSection(section - 1);
  };

  const toggleSignInModal = () => {
    setShowSignInModal(!showSignInModal);
    setShowSignupModal(false); // Hide sign-up modal when sign-in modal is opened
  };

  const getTextForRoleAndStep = (role, step) => {
    if (role === "Employee") {
      switch (step) {
        case 1:
          return <ModalBannerText1 />;
        case 2:
          return <ModalBannerText1 />;
        case 3:
          return <ModalBannerText1 />;
        case 4:
          return <ModalBannerText2 />;
        case 5:
          return <ModalBannerText1 />;
        case 6:
          return <ModalBannerText1 />;
        case 7:
          return <ModalBannerText1 />;
        case 8:
          return <ModalBannerText1 />;
        // Add cases for other steps
        default:
          return "Employee Default Text";
      }
    } else if (role === "Employer") {
      switch (step) {
        case 1:
          return <ModalBannerText1 />;
        case 2:
          return <ModalBannerText1 />;
        case 3:
          return <ModalBannerText2 />;
        case 4:
          return <ModalBannerText1 />;
        case 5:
          return <ModalBannerText1 />;
        case 6:
          return <ModalBannerText1 />;

        default:
          return "Employer Default Text";
      }
    } else {
      return "Default Text";
    }
  };

  // const toggleSignupModal = () => {
  //   setShowSignupModal(!showSignupModal);
  //   setShowSignInModal(false); // Hide sign-in modal when sign-up modal is opened
  // };

  // const handlePrevSection = () => {
  //   setSection(section - 1);
  // };

  return (
    <>
        <div id="recaptcha-container"></div>
      <Modal
        open={open && showSignupModal} // Show only if 'open' prop and showSignupModal state are true
        onClose={handleClose}
        aria-labelledby="signup-modal-title"
        aria-describedby="signup-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: 1400,
            bgcolor: "#f8f8f9 !important",
            boxShadow: 24,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            id="hideloding"
            className="loding-display"
            style={{ display: "none" }}
          >
            <img src={loader} alt="loader-img" />
          </div>
          <Grid
            container
            component="main"
            sx={{ height: "100vh", maxHeight: "90vh" }}
          >
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: { xs: "none", md: "block" }, // Hide on extra-small screens
                backgroundImage: `url(${ModalAuthBannerCommon})`, // Set the common background image URL
                backgroundRepeat: "no-repeat",
                backgroundColor:
                  theme.palette.mode === "light"
                    ? theme.palette.grey[50]
                    : theme.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "top",
                position: "relative", // For positioning text over the background
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white", // Adjust text color based on background
                  textAlign: "center",
                  padding: "16px",
                  width: "100%",
                  // backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background for text readability
                  borderRadius: "8px",
                }}
              >
                {getTextForRoleAndStep(role, section)}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              className="sideBg"
              sx={{
                display: "flex",
                maxHeight: "calc(100vh - 64px)",
                overflowY: "auto",
                position: "relative",
                // paddingLeft: 0,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#f8f8f9",

                px: { md: 5 },
              }}
              component={Paper}
              elevation={6}
              square
            >
              <div className="iconArrow12" onClick={handleBackStep}>
                {section >= 2 ? <ArrowBackIcon /> : ""}
              </div>
              {/* <div className="skipText" onClick={handleSkip}>
                {section < 8 ? "skip" : ""}
              </div> */}
              {section === 1 && (
                <Typography
                  id="signup-modal-description"
                  sx={{ padding: 6, paddingTop: 0 }}
                >
                  <div
                    style={{ cursor: "pointer" }}
                    className="signinText"
                    onClick={toggleSignUpModal}
                  >
                    <span>{t("Already")}</span>{" "}
                    <span className="SigninTextJoin">{t("SignIn")}</span>
                  </div>
                  <Box sx={{ paddingTop: 12 }}>
                    <div className="sognupIntro">
                      <h2 className="">{t("JoinUs")}</h2>
                      <Typography
                        className=""
                        variant="p"
                        sx={{
                          display: "block",
                          color: "#777777",
                          textAlign: "start",
                          py: 1,
                        }}
                      >
                        {t("JoinGrow")}
                      </Typography>
                    </div>
                    <Box
                      className="radioSelection"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        paddingTop: 4,
                      }}
                    >
                      <div
                        className={`employee ${
                          role === "Employee" ? "active" : ""
                        }`}
                        onClick={() => handleChangeRole("Employee")}
                      >
                        <div className="employeeInner">
                          <img
                            height={50}
                            width={50}
                            src={Employee}
                            alt="Employee"
                          />
                          <div className="employeeText">
                            <Box sx={{ px: 2 }} className="">
                              <h4 className="textStart"> {t("Employee")}</h4>
                            </Box>
                            <Box sx={{ px: 2 }} className="beginText">
                              {t("Personal")}
                            </Box>
                          </div>
                          <div className="iconArrow" onClick={handleNextStep}>
                            <ArrowForwardIcon />
                            {/* <i className="fa fa-arrow-right" /> */}
                          </div>
                        </div>
                      </div>
                      <div
                        className={`employee ${
                          role === "Employer" ? "active" : ""
                        }`}
                        onClick={() => handleChangeRole("Employer")}
                      >
                        <div className="employeeInner">
                          <img
                            height={50}
                            width={50}
                            src={Employeer}
                            alt="Employer"
                          />
                          <div className="employeeText">
                            <Box sx={{ px: 2 }} className="">
                              <h4 className="textStart"> {t("Employer")}</h4>
                            </Box>
                            <Box sx={{ px: 2 }} className="beginText">
                              {t("Own")}
                            </Box>
                          </div>

                          <div className="iconArrow" onClick={handleNextStep}>
                            <ArrowForwardIcon />
                            {/* <i className="fa fa-arrow-right" /> */}
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Box>
                </Typography>
              )}
              {role === "Employee" && section === 2 && (
                /* Employee Section 2   */
                <Box>
                  {" "}
                  {/* Adjust maxHeight as needed */}
                  <div className="RegistrationEmployee">
                    <RegistrationEmployee
                      employeeUserData={employeeUserData}
                      handleNextStepEmployee={handleNextStep}
                      handleBackStepEmployee={handleBackStep}
                      SetEmployeeUserDataObject={SetEmployeeUserDataObject}
                    />
                  </div>
                </Box>
              )}

              {role === "Employer" && section === 2 && (
                /* Employer Section 2 */
                <>
                  <Box>
                    {" "}
                    {/* Adjust maxHeight as needed */}
                    <div className="RegistrationEmployer">
                      <RegistrationEmployer
                        appVerifier={appVerifier}
                        employerUserData={employerUserData}
                        handleNextStepEmployer={handleNextStep}
                        handleBackStepEmployer={handleBackStep}
                        SetEmployerUserDataObject={SetEmployerUserDataObject}
                      />
                    </div>
                  </Box>
                </>
              )}

              {/* Add similar sections for each role 3 */}
              {role === "Employee" && section === 3 && (
                /* Employee Section 3 */
                <>
                  <Box>
                    {" "}
                    {/* Adjust maxHeight as needed */}
                    <div className="MobileVerificationEmployee">
                      <MobileVerificationEmployee
                        appVerifier={appVerifier}
                        handleNextStepEmployee={handleNextStep}
                        handleBackStepEmployee={handleBackStep}
                        SetEmployeeUserDataObject={SetEmployeeUserDataObject}
                      />
                    </div>
                  </Box>
                  {/* <Button onClick={handleNextSection} sx={{ mt: 2 }}>
                    Next
                  </Button>
                  <Button onClick={handlePrevSection} sx={{ mt: 2 }}>
                    Previous
                  </Button> */}
                </>
              )}
              {/* {role === "Employer" && section === 3 && (
                <>
                  <Box
                    
                  >
                    {" "}
                    <div className="MobileVerificationEmployer">
                      <MobileVerificationEmployer
                        handleNextStepEmployer={handleNextStep}
                        handleBackStepEmployer={handleBackStep}
                        SetEmployerUserDataObject={SetEmployerUserDataObject}
                      />
                    </div>
                  </Box>
                </>
              )} */}
              {/* Add similar sections for each role 4 */}
              {role === "Employee" && section === 4 && (
                /* Employee Section 3 */
                <>
                  <Box>
                    {" "}
                    {/* Adjust maxHeight as needed */}
                    <div className="OTPVerficationEmployee">
                      <OTPVerficationEmployee
                        appVerifier={appVerifier}
                        handleNextStepEmployee={handleNextStep}
                        handleBackStepEmployee={handleBackStep}
                        SetEmployeeUserDataObject={SetEmployeeUserDataObject}
                        employeeUserData={employeeUserData}
                      />
                    </div>
                  </Box>
                  {/* <Button onClick={handleNextSection} sx={{ mt: 2 }}>
                    Next
                  </Button>
                  <Button onClick={handlePrevSection} sx={{ mt: 2 }}>
                    Previous
                  </Button> */}
                </>
              )}
              {role === "Employer" && section === 3 && (
                /* Employer Section 3 */
                <>
                  <Box>
                    {" "}
                    {/* Adjust maxHeight as needed */}
                    <div className="OTPVerficationEmployer">
                      <OTPVerficationEmployer
                        appVerifier={appVerifier}
                        handleNextStepEmployer={handleNextStep}
                        handleBackStepEmployer={handleBackStep}
                        SetEmployerUserDataObject={SetEmployerUserDataObject}
                        employerUserData={employerUserData}
                      />
                    </div>
                  </Box>
                  {/* <Button onClick={handleNextSection} sx={{ mt: 2 }}>
                    Next
                  </Button>
                  <Button onClick={handlePrevSection} sx={{ mt: 2 }}>
                    Previous
                  </Button> */}
                </>
              )}

              {/* Add similar sections for each role 5  */}
              {role === "Employee" && section === 5 && (
                /* Employee Section 3 */
                <>
                  <Box>
                    {" "}
                    {/* Adjust maxHeight as needed */}
                    <div
                      className="SkillSetEmployee"
                      style={{
                        height: "100%",
                      }}
                    >
                      <SkillSetEmployee
                        employeeUserData={employeeUserData}
                        handleNextStepEmployee={handleNextStep}
                        handleBackStepEmployee={handleBackStep}
                        SetEmployeeUserDataObject={SetEmployeeUserDataObject}
                      />
                    </div>
                  </Box>
                  {/* <Button onClick={handleNextSection} sx={{ mt: 2 }}>
                    Next
                  </Button>
                  <Button onClick={handlePrevSection} sx={{ mt: 2 }}>
                    Previous
                  </Button> */}
                </>
              )}
              {role === "Employer" && section === 4 && (
                /* Employer Section 3 */
                <>
                  <Box>
                    {" "}
                    {/* Adjust maxHeight as needed */}
                    <div
                      className="SkillSetEmployer"
                      style={{
                        height: "100%",
                      }}
                    >
                      <SkillSetEmployer
                        handleNextStepEmployer={handleNextStep}
                        handleBackStepEmployer={handleBackStep}
                        employerUserData={employerUserData}
                      />
                    </div>
                  </Box>
                  {/* <Button onClick={handleNextSection} sx={{ mt: 2 }}>
                    Next
                  </Button>
                  <Button onClick={handlePrevSection} sx={{ mt: 2 }}>
                    Previous
                  </Button> */}
                </>
              )}

              {/* Add similar sections for each role 6  */}
              {role === "Employee" && section === 6 && (
                /* Employee Section 3 */
                <>
                  <Box>
                    {" "}
                    {/* Adjust maxHeight as needed */}
                    <div className="ProfileEmployee">
                      <ProfileEmployee
                        employeeUserData={employeeUserData}
                        handleNextStepEmployee={handleNextStep}
                        handleBackStepEmployee={handleBackStep}
                        SetEmployeeUserDataObject={SetEmployeeUserDataObject}
                      />
                    </div>
                  </Box>
                  {/* <Button onClick={handleNextSection} sx={{ mt: 2 }}>
                    Next
                  </Button>
                  <Button onClick={handlePrevSection} sx={{ mt: 2 }}>
                    Previous
                  </Button> */}
                </>
              )}
              {role === "Employer" && section === 5 && (
                /* Employer Section 3 */
                <>
                  <Box>
                    {" "}
                    {/* Adjust maxHeight as needed */}
                    <div className="SkillSetEmployer">
                      <ProfileEmployer
                        employerUserData={employerUserData}
                        handleNextStepEmployer={handleNextStep}
                        handleBackStepEmployer={handleBackStep}
                        SetEmployerUserDataObject={SetEmployerUserDataObject}
                      />
                    </div>
                  </Box>
                </>
              )}

              {role === "Employer" && section === 6 && (
                /* Employer Section 3 */
                <>
                  <Box>
                    {" "}
                    {/* Adjust maxHeight as needed */}
                    <div className="SkillSetEmployer">
                      <BrowseEmployer
                        handleNextStepEmployer={handleNextStep}
                        handleBackStepEmployer={handleBackStep}
                        SetEmployerUserDataObject={SetEmployerUserDataObject}
                      />
                    </div>
                  </Box>
                </>
              )}

              {/* Add similar sections for each role 6  */}
              {role === "Employee" && section === 7 && (
                /* Employee Section 3 */
                <>
                  <Box>
                    {" "}
                    {/* Adjust maxHeight as needed */}
                    <div className="AboutEmployee">
                      <AboutEmployee
                        handleNextStepEmployee={handleNextStep}
                        handleBackStepEmployee={handleBackStep}
                        employeeUserData={employeeUserData}
                        SetEmployeeUserDataObject={SetEmployeeUserDataObject}
                      />
                    </div>
                  </Box>
                  {/* <Button onClick={handleNextSection} sx={{ mt: 2 }}>
                    Next
                  </Button>
                  <Button onClick={handlePrevSection} sx={{ mt: 2 }}>
                    Previous
                  </Button> */}
                </>
              )}

              {/* Add similar sections for each role 6  */}
              {role === "Employee" && section === 8 && (
                /* Employee Section 3 */
                <>
                  <Box>
                    {" "}
                    {/* Adjust maxHeight as needed */}
                    <div className="AboutEmployee">
                      <PaymentAddressEmployee
                        handleNextStepEmployee={handleNextStep}
                        handleBackStepEmployee={handleBackStep}
                        employeeUserData={employeeUserData}
                      />
                    </div>
                  </Box>
                  {/* <Button onClick={handleNextSection} sx={{ mt: 2 }}>
                    Next
                  </Button>
                  <Button onClick={handlePrevSection} sx={{ mt: 2 }}>
                    Previous
                  </Button> */}
                </>
              )}
              {role === "Employer" && section === 6 && (
                /* Employer Section 3 */
                <>
                  <Box>
                    {" "}
                    {/* Adjust maxHeight as needed */}
                    <div className="WhatYouNeedEmployer">
                      <WhatYouNeedEmployer
                        handleNextStepEmployer={handleNextStep}
                        handleBackStepEmployer={handleBackStep}
                        employerUserData={employerUserData}
                      />
                    </div>
                  </Box>
                  {/* <Button onClick={handleNextSection} sx={{ mt: 2 }}>
                    Next
                  </Button>
                  <Button onClick={handlePrevSection} sx={{ mt: 2 }}>
                    Previous
                  </Button> */}
                </>
              )}

              {/* Add sections 4 to 7 for both roles */}
              {/* Sections 4 to 7 */}
              {/* {section >= 4 && section <= 7 && (
              <Typography>
                <Button onClick={handleNextSection} sx={{ mt: 2 }}>
                  Next
                </Button>
                <Button onClick={handlePrevSection} sx={{ mt: 2 }}>
                  Previous
                </Button>
              </Typography>
            )} */}
            </Grid>
          </Grid>
          {/* {section === 7 && (
          <Button onClick={handleReset} sx={{ mt: 2 }}>
            Reset
          </Button>
        )} */}
        </Box>
      </Modal>
      {/* <SignIn
        open={showSignInModal}
        handleClose={toggleSignInModal}
        toggleSignUpModal={toggleSignUpModal}
      />{" "} */}
      {/* Render the sign-in modal */}
    </>
  );
};

export default Signup;
