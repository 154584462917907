import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, Button, useMediaQuery, useTheme, } from "@mui/material";
import Dropdown from "../../../../Components/Dropdown/Dropdown"; // Import the Dropdown component
import { GetUserDetails, LoaderHide, LoaderShow } from "../../../../Helpers/Utility";
import { postAPI } from "../../../../Services/Api";
import { toast } from "react-toastify";
import loader from "../../../../Assets/Images/loader.svg"
import axios from "axios";
import CoinPayments from "../CoinPayments/CoinPayments";


const SelectNetwork = ({ open, onClose, onSubmit, balance, openConinsModal, closeConinsModal }) => {

  const [selectedOption, setSelectedOption] = React.useState(""); // State to store selected option
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [coinsPaymentResponse, setCoinsPaymentResponse] = useState({
    // "amount": "0.02349000",
    // "txn_id": "CPIF088RJICURIMBITNEGMYPPR",
    // "address": "mkuHpiWhrdBQGXE2s5f4N6w7XGqTSwNxyP",
    // "confirms_needed": "0",
    // "timeout": 3600,
    // "checkout_url": "https://www.coinpayments.net/index.php?cmd=checkout&id=CPIF088RJICURIMBITNEGMYPPR&key=09b48ad24498e120521dc5785a7b37bc",
    // "status_url": "https://www.coinpayments.net/index.php?cmd=status&id=CPIF088RJICURIMBITNEGMYPPR&key=09b48ad24498e120521dc5785a7b37bc",
    // "qrcode_url": "https://www.coinpayments.net/qrgen.php?id=CPIF088RJICURIMBITNEGMYPPR&key=09b48ad24498e120521dc5785a7b37bc"
  })

  useEffect(() => {
    LoaderHide()
  }, [])

  // Function to handle option change
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };


  const submitNetwork = async () => {
    onSubmit()
    // LoaderShow()
    // if (selectedOption) {
    //   try {
    //     const token = "345|aL2j1aLwAEC6zoBTDOf6RXCn5bCbjt0qP6r3xamI20d68796"

    //     const dataToSend = {
    //       "amount": "2315",
    //       "currency": selectedOption
    //     };

    //     const response = await axios.post("https://1vpn4u.com/create-payment", dataToSend, { headers: { "Authorization": `Bearer ${token}` } });
    //     if (response.statusCode === 200) {
    //       LoaderHide()
    //       onSubmit()
    //     }
    //   } catch (error) {
    //     LoaderHide()
    //     toast.error(error.message);
    //   }
    // }else{
    //   toast.error("Please select network")
    //   LoaderHide()
    // }
  }

  return (
    <>
      {/* <div id="hideloding" className="loding-display">
        <img src={loader} alt='loader-img' />
      </div> */}
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="subscribe-modal-title"
        aria-describedby="subscribe-modal-description"
      >
        <Box
          className='membership-grid1'
          sx={{
            position: "absolute",
            width: isMobile ? '80%' : isDesktop ? 550 : '75%', // Adjust width for mobile and desktop devices
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: 24,
            p: 4,
            borderRadius: 5,
          }}
        >
          <Box>
            <Box className="SubHeader">
              <Typography
                variant="h5"
                id="subscribe-modal-title"
                sx={{ borderBottom: 1, py: 2, pt: 0, mb: 3, borderColor: "#E6E6E6" }}
                gutterBottom
              >
                Select Network
              </Typography>
            </Box>
            {/* Integrate Dropdown component here */}
            <Dropdown
              value={selectedOption}
              onChange={handleOptionChange}
              options={[
                { value: "BTC", label: "BTC" },
                { value: "ETH", label: "ETH" },
                { value: "LTCT", label: "LTCT" },
              ]}
              label="Select Network"
            />
            {/* You can add more content or form inputs here */}
            <Typography variant="p" sx={{ fontSize: 16, textAlign: 'center', display: 'block', py: 3 }}>Selected Network: {selectedOption}</Typography>
            <Button
              variant="contained"
              fullWidth={true}
              sx={{
                textTransform: "capitalize",
                fontSize: 18,
                bgcolor: "#0B3013 !important",
                color: "#fff",
                borderRadius: 2,
              }}
              onClick={submitNetwork} // Call onSubmit function when the button is clicked
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
      <CoinPayments
        coinsPayment={coinsPaymentResponse}
        open={openConinsModal}
        onClose={closeConinsModal}
      />
    </>
  );
};

export default SelectNetwork;
