import React from "react";
import { Box, Card, Container, Grid, Typography, Rating } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cat1 from "../../../Assets/Images/Category1.png";
import Cat2 from "../../../Assets/Images/Category2.png";
import Cat3 from "../../../Assets/Images/Category3.png";
import Cat4 from "../../../Assets/Images/Category4.png";
import ColBGREal from "../../../Assets/Images/ColBGREal.png";
// import ReviewSec from "../../../Assets/Images/ReviewSec.png";
import Review1 from "../../../Assets/Images/Review1.png";
import Review2 from "../../../Assets/Images/Review2.png";
import { useTranslation } from "react-i18next";
import "../../../Localization/i18n";
import "./HomeMakeReal.css";

export default function HomeMakeReal() {
  const { t } = useTranslation();

  const categories = [
    {
      image: Cat1,
      text: t("The"),
      subText: t("OurPlatform"),
    },
    {
      image: Cat2,
      text: t("Fast"),
      subText: t("WorkWaveHubs"),
    },
    {
      image: Cat3,
      text: t("Quality"),
      subText: t("Our"),
    },
    {
      image: Cat4,
      text: t("Be"),
      subText: t("WorkWaveHub2"),
    },
  ];

  return (
    <section className="mainSection">
      <Container maxWidth="xl" sx={{ px: { md: 8, xl: 4, xxl: 4 }, py: 3 }}>
        <Box>
          <Typography
            variant="h4"
            className="talentHeadingMain"
            sx={{ color: "black", fontWeight: 700 }}
          >
            {t("Talented")}
          </Typography>
          <Typography
            variant="p"
            className="realP"
            sx={{ display: "block", pt: 2, color: "black" }}
          >
            {t("Search")}
          </Typography>
        </Box>
      </Container>
      <section className="firstMakeSection">
        <Container maxWidth="xl" sx={{ py: 3 }}>
          <Grid
            container
            spacing={0}
            sx={{ py: 10, pt: 0 }}
            className="padTopZero  flexColumnRespo"
          >
            <Grid
              item
              xs={12}
              sm={12}
              xl={3}
              lg={3}
              className="makeReal"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
                paddingLeft: 3,
              }}
            >
              {/* 4 vertical cards with text */}
              {categories.map((category, index) => (
                <Card
                  className="cardMakeReal"
                  key={index}
                  sx={{
                    my: 2,
                    p: 2,
                    bgcolor: "#fffefe",
                    borderRadius: 3,
                    width: 250,
                    maxWidth: 250,
                    boxShadow:
                      "0px 4px 6px -1px rgba(0,0,0,0.07), 0px 4px 10px 0px rgba(0,0,0,0.1)",
                  }}
                >
                  <Typography variant="h6">{category.text}</Typography>
                  <Typography>{category.subText}</Typography>
                </Card>
              ))}
            </Grid>
            <Grid item xs={9} sx={{ display: "flex", justifyContent: "end" }}>
              {/* Image */}
              <img
                src={ColBGREal}
                className="makeBanner dNoneRespo alignItemsCenter"
                alt="Category"
              />
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className="secondMakeSection">
        <Container maxWidth="xl">
          <Grid
            className="flexColumnRespo padBotZero padTopZero"
            container
            sx={{
              py: 20,
              position: "relative",
              justifyContent: "space-evenly",
            }}
          >
            {/* Grid item with width 5 */}
            <Grid item xs={6} xl={5} lg={6} md={6}>
              <Box
                sx={{ position: "absolute", left: 80 }}
                className="reviewBox  dNoneRespo"
              >
                <div className="containerReview">
                  <Box sx={{}}>
                    <div className="card">
                      <div className="img-box">
                        <img src={Review1} alt="dasm" />
                      </div>
                      <div className="content">
                        {/* <h2>Leafs</h2> */}
                        <Rating name="leaf-rating" value={3} readOnly />

                        <p>All Over Great Experience !</p>
                      </div>
                    </div>
                  </Box>
                  <Box
                    className="card2"
                    sx={{
                      position: "absolute",
                      mr: "-60%",
                      mb: "-5%",
                      right: 0,
                      bottom: 0,
                    }}
                  >
                    <div className="card">
                      <div className="img-box">
                        <img src={Review2} alt="sf" />
                      </div>
                      <div className="content">
                        <Rating name="leaf-rating" value={3} readOnly />
                        <p>Good Application easy to work</p>
                      </div>
                    </div>
                  </Box>
                </div>
              </Box>
              {/* <img src={ReviewSec} alt="ReviewSec" /> */}
            </Grid>
            {/* Grid item with width 7 */}
            <Grid
              item
              xs={12}
              lg={6}
              xl={6}
              md={6}
              className="padTopZero globalNetSection"
            >
              <Box>
                <Typography
                  className="globalText"
                  variant="h3"
                  sx={{ color: "white", fontWeight: "normal", py: 3 }}
                >
                  {t("How")}
                </Typography>
              </Box>
              <Box sx={{ py: 5 }}>
                <Grid container className="flexColumnRespo">
                  {/* Grid item with width 5 */}
                  <Grid item xs={12} xl={6} lg={6} md={6}>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", mb: 3 }}
                    >
                      <Typography
                        variant="h5"
                        sx={{ color: "white", py: 0, my: 0 }}
                      >
                        {t("Create")}
                      </Typography>
                      <Typography
                        className="talenetP"
                        variant="p"
                        sx={{
                          color: "white",
                          fontWeight: "200",
                          fontSize: 18,
                          py: 3,
                          paddingRight: 6,
                        }}
                      >
                        {t("Showcase")}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="h5"
                        sx={{ color: "white", py: 0, my: 0 }}
                      >
                        {t("SimpleApp")}
                      </Typography>
                      <Typography
                        className="talenetP"
                        variant="p"
                        sx={{
                          color: "white",
                          fontWeight: "200",
                          fontSize: 18,
                          py: 3,
                          paddingRight: 6,
                        }}
                      >
                        {t("Apply")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} xl={6} lg={6} md={6}>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", mb: 3 }}
                    >
                      <Typography
                        variant="h5"
                        sx={{ color: "white", py: 0, my: 0 }}
                      >
                        {t("FindPerfect")}
                      </Typography>
                      <Typography
                        className="talenetP"
                        variant="p"
                        sx={{
                          color: "white",
                          fontWeight: "200",
                          fontSize: 18,
                          py: 3,
                          textAlign: "justify",
                        }}
                      >
                        {t("SearchBrowse")}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="h5"
                        sx={{ color: "white", py: 0, my: 0 }}
                      >
                        {t("Manage")}
                      </Typography>
                      <Typography
                        className="talenetP"
                        variant="p"
                        sx={{
                          color: "white",
                          fontWeight: "200",
                          fontSize: 18,
                          py: 3,
                        }}
                      >
                        {t("Track")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </section>
    </section>
  );
}
