// App.js
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppRoutes from './AppRoutes/AppRoutes';
import { I18nextProvider } from 'react-i18next';
import i18n from './Localization/i18n';

// Define your theme
const theme = createTheme();

export default function App() {
  return (
    <I18nextProvider i18n={i18n}>
    <ThemeProvider theme={theme}>
      <Router>
        <AppRoutes />
      </Router>
    </ThemeProvider>
    </I18nextProvider>
  );
}
