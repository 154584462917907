import { Box } from "@mui/system";
import React from "react";
import SendAnOffer from "./SendAnOffer";
import { GetUserDetails } from "../../Helpers/Utility";

export default function SendAnOfferAll({ userRole }) {
  return (
    <Box >
    
      {GetUserDetails()?.role === "employer" && (
        <SendAnOffer />
        // Render content specific to employers
      )}
    </Box>
  );
}
