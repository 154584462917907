import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Button,
  Radio,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import Paypal from "../../../../Assets/Images/Paypal.png";
import Stripe from "../../../../Assets/Images/Stripe.svg";
import Crypto from "../../../../Assets/Images/Crypto.png";
import { loadStripe } from "@stripe/stripe-js";
import { getAPI, postAPI } from "../../../../Services/Api";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../../Helpers/Utility";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  FUNDING,
  PayPalButtons,
  PayPalScriptProvider,
} from "@paypal/react-paypal-js";
import { useTranslation } from "react-i18next";
import CoinPayments from "../CoinPayments/CoinPayments";
import CloseIcon from "@mui/icons-material/Close";

const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISH_KEY_PRODUCTION}`
);

const SubscriptionModal = ({
  open,
  onClose,
  onCryptoSelected,
  balance,
  transactionId,
  milestoneId,
  userId,
  isNew,
  totalFee,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedValue, setSelectedValue] = React.useState("Stripe");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [milestone, setMilestone] = useState("");
  const [additionalTaxes] = useState(15); // Additional taxes amount
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openCoinPaymentDialog, setOpenCoinPaymentDialog] = useState(false);
  const [cryptoDetails, setCryptoDetails] = useState({});
  const [openPayPal, setOpenPayPal] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const projectDetails = JSON.parse(localStorage.getItem("projectData")) || "";

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    try {
      LoaderShow();
      const token = GetUserDetails().token;
      const response = await getAPI("user/getdetails", token);
      if (response.statusCode === 200) {
        setSelectedValue(response?.data?.defaultPaymentMethod || "Stripe");
      }
    } catch (error) {
      // toast.error(error.message)
    }
    LoaderHide();
  };

  // useEffect(() => {
  //   const setTransactionDetails = async (name) => {
  //     LoaderShow();
  //     try {
  //       const dataToSend = {
  //         amount: searchParams.get("amt"),
  //         projectId: projectDetails?._id || "",
  //         method: "card",
  //         peyeeId: projectDetails?.userId || "",
  //         transectionId: transactionId,
  //         milestoneIds: searchParams.get("milestone_id").split(","),
  //       };
  //       const token = GetUserDetails().token;

  //       const response = await postAPI("transection/create", dataToSend, token);
  //       const data = {
  //         email: searchParams.get("email"),
  //         projectId: projectDetails?._id,
  //         employeeId: searchParams.get("user_id"),
  //       };
  //       if (response.statusCode === 200) {
  //         if (!searchParams.get(isNew))
  //           await postAPI("award/projects/award", data, token);
  //         navigate("/dashboard/employer/myprojects");
  //       }
  //     } catch (error) {
  //       // toast.error(error.message)
  //       navigate("/dashboard/employer/myprojects");
  //     }
  //   };

  //   LoaderHide();

  //   if (transactionId) {
  //     setTransactionDetails(transactionId);
  //   }
  // }, [transactionId]);

  // useEffect(() => {
  //   if (milestoneId?.length) localStorage.setItem("milestone-ids", milestoneId);
  // }, [milestoneId]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const initiateStripePayment = async () => {
    setLoading(true);
    try {
      // const stripe = await loadStripe('pk_test_51LNAXqSHRoKsJ0YdCsPcfRlrqrZRufKJ51lnvg7lEEknm1tIQp08z8Fir9hzZgxPvm57OHaEtmxlBn9hurjwB7yc00U7it2i0i');
      // await stripe.redirectToCheckout({
      //   lineItems: [{ price: 'price_1P3ckKSHRoKsJ0YdWJZOTMyM', quantity: 1 }],
      //   mode: 'payment',
      //   successUrl: 'http://localhost:3000/sendanoffer',
      //   cancelUrl: 'http://localhost:3000/sendanoffer',
      // });

      const stripe = await stripePromise;

      const response = await fetch(
        "https://api.stripe.com/v1/checkout/sessions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECERT_KEY_PRODUCTION}`,
          },
          body: new URLSearchParams({
            "payment_method_types[]": "card",
            "line_items[0][price_data][currency]": "usd",
            "line_items[0][price_data][product_data][name]":
              "Milestone Payment",
            "line_items[0][price_data][unit_amount]": Math.trunc(
              balance.toFixed(2) * 100
            ), // Stripe expects the amount in cents
            "line_items[0][quantity]": "1",
            mode: "payment",
            success_url: `${
              process.env.REACT_APP_URL
            }/dashboard/employer/myprojects?payment_intent={CHECKOUT_SESSION_ID}&user_id=${
              userId._id
            }&email=${userId.email}&isNew=${Boolean(
              isNew
            )}&milestone_id=${milestoneId.toLocaleString()}&amt=${balance}&total_fee=${totalFee}`,
            cancel_url: `${process.env.REACT_APP_URL}`,
            // 'success_url': `http://localhost:3000/sendanoffer?payment_intent={CHECKOUT_SESSION_ID}`,
            // 'cancel_url': `http://localhost:3000/sendanoffer`,
          }).toString(),
        }
      );

      const session = await response.json();

      if (session.error) {
        throw new Error(session.error.message);
      }
      LoaderHide();
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        setError(result.error.message);
      }
    } catch (error) {
      LoaderHide();
      console.error("Error initiating Stripe payment:", error);
    }
  };

  const initiateCryptoPayment = async () => {
    setLoading(true);
    LoaderShow();
    try {
      const { token } = GetUserDetails();
      const data = {
        projectId: projectDetails?._id || "",
        // currency: "LTCT",
        employerId: projectDetails?.userId
          ? projectDetails?.userId
          : projectDetails?.user._id
          ? projectDetails?.user._id
          : "",
        employeeId: userId?._id ? userId?._id : userId,
        milestones: milestoneId,
        amount: balance,
      };
      const response = await postAPI("coinpayment/create-payment", data, token);
      if (response?.payment?.data) {
        setCryptoDetails(response?.payment?.data);
        setOpenCoinPaymentDialog(true);
      }
    } catch (error) {
      console.error("Error initiating Stripe payment:", error);
    }
    LoaderHide();
  };

  const closePayPalModal = () => {
    setOpenPayPal(false);
  };

  const handlePaymentSubmit = () => {
    LoaderShow();
    if (selectedValue === "Paypal") {
      setOpenPayPal(true);
      onClose();
      LoaderHide();
    } else if (selectedValue === "Stripe") {
      initiateStripePayment();
    } else if (selectedValue === "Crypto") {
      initiateCryptoPayment();
    }
  };

  const createTransaction = async (details) => {
    LoaderShow();
    if (details.message === "completed") {
      // Handle successful transaction here
      setOpenCoinPaymentDialog(false);
      try {
        const dataToSend = {
          amount: balance,
          projectId: projectDetails?._id || "",
          method: "crypto",
          peyeeId: projectDetails?.userId || "",
          transectionId: details?.id,
          milestoneIds: milestoneId,
          totalFee: totalFee,
        };
        const token = GetUserDetails().token;

        const data = {
          email: userId?.email,
          projectId: projectDetails?._id,
          employeeId: userId?._id,
          milestoneIds: milestoneId,
          totalFee: totalFee,
        };

        const response = await postAPI("transection/create", dataToSend, token);
        if (response.statusCode === 200) {
          if (!isNew) {
            await postAPI("award/projects/award", data, token);
          }
          // navigate("/dashboard/employer/myprojects");
          toast.success("Payment successfull");
          window.location.replace("/dashboard/employer/myprojects");
        }
      } catch (error) {
        // toast.error(error.message)
      }
    }
    LoaderHide();
  };

  return (
    <>
      {openCoinPaymentDialog && (
        <CoinPayments
          open={openCoinPaymentDialog}
          onClose={() => setOpenCoinPaymentDialog(false)}
          coinsPayment={cryptoDetails}
          onComplete={createTransaction}
        />
      )}
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="subscribe-modal-title"
        aria-describedby="subscribe-modal-description"
      >
        <Box
          className="employeeProjdetails"
          sx={{
            position: "absolute",
            width: isMobile ? "80%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: 24,
            p: 4,
            borderRadius: 5,
          }}
        >
          <Box>
            <Box className="SubHeader">
              <Typography
                variant="h5"
                id="subscribe-modal-title"
                sx={{ borderBottom: 1, py: 2, pt: 0, borderColor: "#E6E6E6" }}
                gutterBottom
              >
                {t("Payment")}
              </Typography>
              {isNew && (
                <CloseIcon
                  sx={{ cursor: "pointer", float: "right" }}
                  onClick={onClose}
                />
              )}
            </Box>
            <Box className="SubHeader">
              <Typography
                fontWeight={500}
                variant="h5"
                id="subscribe-modal-title"
                sx={{ borderBottom: 1, py: 2, pt: 0, borderColor: "#E6E6E6" }}
                gutterBottom
              >
                {t("Balance")} : ${balance}
              </Typography>
            </Box>
            <Box
              sx={{
                py: 1,
                border: 1,
                my: 2,
                mt: 4,
                borderColor: "#E6E6E6",
                borderRadius: 2,
              }}
            >
              <Box
                className="alignBaselineRsp flexColumnRespo"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Radio
                    {...controlProps("Paypal")}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 28,
                        color: "#0B3013",
                        m: 0,
                        p: 0,
                      },
                    }}
                  />
                  <img src={Paypal} alt="Paypal" />
                </Box>
                <Typography sx={{ px: 2 }}>{t("paypalPaymentDes")} </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                py: 1,
                border: 1,
                my: 2,
                borderColor: "#E6E6E6",
                borderRadius: 2,
              }}
            >
              <Box
                className="alignBaselineRsp flexColumnRespo"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Radio
                    {...controlProps("Stripe")}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 28,
                        color: "#0B3013",
                      },
                    }}
                  />
                  <img
                    src={Stripe}
                    alt="Stripe"
                    style={{
                      width: "auto",
                      height: "auto",
                      maxHeight: "100%",
                      maxWidth: "100%",
                    }}
                  />
                </Box>
                <Typography sx={{ px: 2 }}>{t("stripePaymentDes")}</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                py: 1,
                border: 1,
                my: 2,
                borderColor: "#E6E6E6",
                borderRadius: 2,
              }}
            >
              <Box
                className="alignBaselineRsp flexColumnRespo"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Radio
                    {...controlProps("Crypto")}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 28,
                        color: "#0B3013",
                      },
                    }}
                  />
                  <img src={Crypto} alt="Crypto" />
                </Box>
                <Typography sx={{ px: 2 }}>{t("cryptoPaymentDes")}</Typography>
              </Box>
            </Box>
            {/* You can add more content or form inputs here */}
            <Button
              variant="contained"
              fullWidth={true}
              sx={{
                textTransform: "capitalize",
                fontSize: 18,
                bgcolor: "#0B3013!important",
                color: "#fff",
                borderRadius: 2,
              }}
              onClick={handlePaymentSubmit}
            >
              {t("pay")} ${balance}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openPayPal}
        onClose={closePayPalModal}
        aria-labelledby="subscribe-modal-title"
        aria-describedby="subscribe-modal-description"
      >
        <Box
          className="employeeProjdetails"
          sx={{
            position: "absolute",
            width: isMobile ? "80%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: 24,
            p: 4,
            borderRadius: 5,
          }}
        >
          <Box>
            <Box className="SubHeader">
              <Typography
                variant="h5"
                id="subscribe-modal-title"
                sx={{ borderBottom: 1, py: 2, pt: 0, borderColor: "#E6E6E6" }}
                gutterBottom
              >
                PayPal {t("Payment")}
              </Typography>
              <PayPalScriptProvider
                options={{
                  "client-id":
                    process.env.REACT_APP_PAYPAL_CLIENT_KEY_PRODUCTION,
                  currency: "USD",
                }}
              >
                <PayPalButtons
                  fundingSource={FUNDING.PAYPAL}
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [
                        {
                          amount: {
                            value: balance,
                          },
                        },
                      ],
                      application_context: {
                        // Disable shipping address collection
                        shipping_preference: "NO_SHIPPING",
                      },
                    });
                  }}
                  onApprove={async (data, actions) => {
                    const details = await actions.order.capture();
                    console.log("details paypal=== --- ", details);
                    if (details.status === "COMPLETED") {
                      // Handle successful transaction here
                      try {
                        const dataToSend = {
                          amount: balance,
                          projectId: projectDetails?._id || "",
                          method: "paypal",
                          peyeeId: projectDetails?.userId || "",
                          transectionId: details?.id,
                          milestoneIds: milestoneId,
                          totalFee: totalFee,
                        };
                        const token = GetUserDetails().token;

                        const data = {
                          email: userId?.email,
                          projectId: projectDetails?._id,
                          employeeId: userId?._id,
                          milestoneIds: milestoneId,
                        };

                        const response = await postAPI(
                          "transection/create",
                          dataToSend,
                          token
                        );
                        if (response.statusCode === 200) {
                          if (!isNew) {
                            await postAPI("award/projects/award", data, token);
                            navigate("/dashboard/employer/myprojects");
                          }
                          closePayPalModal();
                        }
                      } catch (error) {
                        // toast.error(error.message)
                      }
                    }
                  }}
                />
              </PayPalScriptProvider>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SubscriptionModal;
