import React from "react";
import { Box, Typography } from "@mui/material";
import "./ModalBannerText1.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import "../../../Localization/i18n";


export default function ModalBannerText3() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start", // Align items to the start
        px: 11,
        mt: { md: 10, xl: 0 },
      }}
    >
      <Typography
        variant="div"
        sx={{
          fontSize: { xl: 30, md: 17 },
          display: "block",
          fontWeight: 400,
          textAlign: "start",
          pb: 2,
        }}
      >
        {t("IStrong")}
      </Typography>
      <ul className="ulListContent" style={{ textAlign: "start" }}>
        <li>
          Stay logged in for a faster application process and to receive job
          alerts tailored to your interests.
        </li>
        <li>Apply to jobs with a single click.</li>
        <li>Never miss an opportunity with personalized job alerts.</li>
        <li>Track your applications and manage your career progress.</li>
      </ul>
    </Box>
  );
}
