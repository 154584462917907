import React, { useEffect, useState } from "react";
import "./SendAnOffer.css";
import BrowseJobsBanner from "../../Assets/Images/BrowseJobsBanner.png";
import AvatarJob from "../../Assets/Images/BrowseJobsAvatar.png";
import chatIco from "../../Assets/Images/ChatIco.png";
import dollarIco from "../../Assets/Images/dollar-circle.png";
import indiaIco from "../../Assets/Images/indiaFlag.png";
import del from "../../Assets/Images/delet.png";
import doc from "../../Assets/Images/document-attachment.png";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import Paypal from "../../Assets/Images/Paypal.png";
import Stripe from "../../Assets/Images/Stripe.png";
import Crypto from "../../Assets/Images/Crypto.png";
import DeleteIcon from "../../Assets/Images/DeleteICon.png";
import EditIcon from "../../Assets/Images/EditIcon.png";
import close from "../../Assets/Images/cancel-circle-half-dot.png";
// import AddIcon from "@mui/icons-material/Add"; // Import the Material-UI icon component
import { DropzoneArea } from "material-ui-dropzone";

import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Rating,
  FormLabel,
  useTheme,
  TextField,
  useMediaQuery,
  Modal,
  Radio,
  Avatar,
  IconButton,
} from "@mui/material";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/material";

import StarIcon from "@mui/icons-material/Star";
import styled from "@emotion/styled";
import {
  getCommision,
  getCountryCode,
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../Helpers/Utility";
import { deleteAPI, postAPI, updateAPI } from "../../Services/Api";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import loader from "../../Assets/Images/loader.svg";
import SubscriptionModal from "../Home/HomeMembership/SubscriptionModal/SubscriptionModal";
import SelectNetwork from "../Home/HomeMembership/SelectNetwork/SelectNetwork";
import CoinPayments from "../Home/HomeMembership/CoinPayments/CoinPayments";
import { useTranslation } from "react-i18next";
// import SearchIcon from "@mui/icons-material/Search"; // Import SearchIcon properly

const labels = {
  0.5: "0.5",
  1: "1.0",
  1.5: "1.5",
  2: "2.0",
  2.5: "2.5",
  3: "3.0",
  3.5: "3.5",
  4: "4.0",
  4.5: "4.5",
  5: "5.0",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 4px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid #E0E0E0;
    };




  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export default function SendAnOffer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isSkillOpen, setIsSkillOpen] = useState(false);
  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);
  const isMobile = useMediaQuery("(max-width:768px)");
  const [milestonesList, setMileStonesList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [cryptoModalOpen, setCryptoModalOpen] = useState(false);
  const [newModalOpen, setNewModalOpen] = useState(false); // State for the new modal
  // const isNotEmpty = (value) => value.trim() !== "";
  const [transactionId, setTransactionId] = useState(null);

  const { t } = useTranslation();

  const [newMilestone, setNewMilestone] = useState({
    title: "",
    description: "",
    timeDuration: "",
    depositAmount: "",
  });

  const location = useLocation();

  useEffect(() => {
    const getQueryParam = (name) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(name);
    };
  }, []);

  const particularUser = location?.state?.particularUser;
  const projectData = location?.state?.projectData;
  const userData = location?.state?.userData;

  useEffect(() => {
    if (projectData) {
      localStorage.setItem("projectData", JSON.stringify(projectData));
    } else {
      const storedProjectDetails = localStorage.getItem("projectData");
      if (storedProjectDetails) {
        // handle the case where projectDetails is undefined
        // you might want to set the state with the stored data
      }
    }
  }, [projectData]);

  useEffect(() => {
    // localStorage.setItem("projectData", JSON.stringify(projectData))
    getMileStones();
  }, []);

  // const [milestoneData, setMileStoneData] = useState({
  //   title: "",
  //   description: "",
  //   timeDuration: "",
  //   depositeAmount: "",
  // });

  // const [milestoneErroMessages, setMileStoneErrorMessages] = useState({
  //   title: "",
  //   description: "",
  //   timeDuration: "",
  //   depositeAmount: "",
  // });

  const getMileStones = async () => {
    try {
      LoaderShow();
      // const dataToSend = { projectId: "6655d1c5e79618d5effb5051" }

      const projectId = projectData?._id;
      const token = GetUserDetails().token;

      const dataToSend = { projectId: projectId };
      const response = await postAPI("milestone/get", dataToSend, token);
      if (response.statusCode === 200) {
        LoaderHide();
        setMileStonesList(response?.data?.milestones);
      }
    } catch (error) {
      LoaderHide();
      // toast.error(error.message)
    }
  };
  const subTotal = milestonesList?.reduce((acc, milestone) => {
    return (
      acc + (milestone.status == "Payment pending" ? milestone?.amount : 0)
    );
  }, 0);
  const estimatedTotal = subTotal + getCommision(subTotal) || 0;

  const handleModalOpen = () => {
    if (subTotal > 0) {
      setOpenModal(true);
    }
    else {
      toast.error("Please add milestone!")
    }
  };

  const awardProject = async () => {
    LoaderShow();
    try {
      const projectDetails =
        JSON.parse(localStorage.getItem("projectData")) || "";
      const data = {
        email: userData.email,
        projectId: projectDetails?._id,
        employeeId: userData._id,
      };
      const token = GetUserDetails()?.token;
      const res = await postAPI("award/projects/award", data, token);
      if (res.statusCode == 200) {
        toast.success("Project awarded successfully");
        navigate("/dashboard/employer/myprojects");
      }
    } catch (err) {
      console.timeLog(err);
      toast.error(err.message);
    }
    LoaderHide();
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleCryptoModalOpen = () => {
    setCryptoModalOpen(true);
  };

  const handleCryptoModalClose = () => {
    setCryptoModalOpen(false);
  };
  const handleNewModalOpen = () => {
    setNewModalOpen(true);
    setCryptoModalOpen(false); // Close the Select Network modal
  };

  // Function to handle closing the new modal
  const handleNewModalClose = () => {
    setNewModalOpen(false);
  };

  // const handleMileStoneTitle = (event) => {
  //   const value = event.target.value;
  //   setMileStoneData({ ...milestoneData, title: value });
  //   setMileStoneErrorMessages({
  //     ...milestoneErroMessages,
  //     title:
  //       milestoneData.title.length < 5
  //         ? "Title should be at least 5 characters long."
  //         : "",
  //   });
  // };

  // const handleMileStoneDescription = (event) => {
  //   const value = event.target.value;
  //   setMileStoneData({ ...milestoneData, description: value });
  //   setMileStoneErrorMessages({
  //     ...milestoneErroMessages,
  //     description:
  //       milestoneData.description.length < 5
  //         ? "Description should be at least 5 characters long."
  //         : "",
  //   });
  // };

  // const handleTimeDuration = (event) => {
  //   const value = event.target.value;
  //   setMileStoneData({ ...milestoneData, timeDuration: value });
  //   setMileStoneErrorMessages({
  //     ...milestoneErroMessages,
  //     timeDuration:
  //       milestoneData.timeDuration.length === 0 ? "Please add time." : "",
  //   });
  // };

  // const handleDepositeAmount = (event) => {
  //   const value = event.target.value;
  //   setMileStoneData({ ...milestoneData, depositeAmount: value });
  //   setMileStoneErrorMessages({
  //     ...milestoneErroMessages,
  //     depositeAmount:
  //       milestoneData.depositeAmount.length === 0
  //         ? "Please add deposite amount."
  //         : "",
  //   });
  // };

  // const validateMileStoneForm = () => {
  //   const errors = {};
  //   let isValid = true;

  //   if (
  //     !isNotEmpty(milestoneData.title) ||
  //     milestoneData.title.length < 5
  //   ) {
  //     errors.title = "Title should be at least 5 characters long.";
  //     isValid = false;
  //   }

  //   if (
  //     !isNotEmpty(milestoneData.description) ||
  //     milestoneData.description.length < 5
  //   ) {
  //     errors.description = "Description should be at least 5 characters long.";
  //     isValid = false;
  //   }

  //   if (milestoneData.timeDuration.length === 0) {
  //     errors.timeDuration = "Please add time.";
  //     isValid = false;
  //   }

  //   if (milestoneData.depositeAmount.length === 0) {
  //     errors.depositeAmount = "Please add deposite amount.";
  //     isValid = false;
  //   }

  //   setMileStoneErrorMessages(errors);
  //   return isValid;
  // };

  // const addMileStone = async () => {
  //   if (validateMileStoneForm()) {
  //     LoaderShow()
  //     try {
  //       const token = GetUserDetails().token;

  //       const dataToSend = {
  //         projectId: "6655d1c5e79618d5effb5051",
  //         description: milestoneData.description,
  //         currency: "INR",
  //         amount: milestoneData.depositeAmount,
  //         title: milestoneData.title,
  //         dueDate: milestoneData.timeDuration,
  //       };

  //       const response = await postAPI("milestone/create", dataToSend, token);
  //       if (response.statusCode === 200) {
  //         LoaderHide()
  //         getMileStones()
  //         toast.success(response.message);
  //         setMileStoneData({
  //           depositeAmount: "",
  //           description: "",
  //           timeDuration: "",
  //         });
  //       }
  //     } catch (error) {
  //       LoaderHide()
  //       toast.error(error.message);
  //     }
  //   }
  // };

  const [milestones, setMilestones] = useState([
    { title: "", description: "", timeDuration: "", depositAmount: "" },
  ]);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [milestoneErrorMessages, setMilestoneErrorMessages] = useState([
    { title: "", description: "", timeDuration: "", depositAmount: "" },
  ]);

  const [touchedFields, setTouchedFields] = useState([]);

  useEffect(() => {
    setMilestoneErrorMessages(
      milestonesList.map(() => ({
        title: "",
        description: "",
        timeDuration: "",
        depositAmount: "",
      }))
    );
  }, [milestonesList]);
  const validateMilestone = (isFrmSubmitted = isFormSubmitted) => {
    const errors = {
      title: "",
      description: "",
      timeDuration: "",
      depositAmount: "",
    };

    if (isFrmSubmitted) {
      if (!newMilestone.title || newMilestone.title.length < 3) {
        errors.title = t("titleCharErr");
      }
      if (!newMilestone.description || newMilestone.description.length < 5) {
        errors.description = t("DescriptionShouldBe5Characters");
      }
      if (!newMilestone.timeDuration) {
        errors.timeDuration = t("PleaseAddTime");
      } else if (newMilestone.timeDuration <= 0) {
        errors.timeDuration = "Please add time duration greater than 0.";
      }
      if (!newMilestone.depositAmount || isNaN(newMilestone.depositAmount)) {
        errors.depositAmount = t("validDepoistAmt");
      } else if (Number(newMilestone.depositAmount) <= 0) {
        errors.depositAmount = t("greaterDepoistAmt");
      }
    }

    // return errors.every(error => Object.values(error).every(val => val === ''));
    return errors;
  };

  const handleMilestoneChange = (index, field, value) => {
    if (index === 0 && milestonesList.length > 1) {
      return; // Prevent updates to the first milestone if there's more than one
    }
    const newMilestones = [...milestonesList];
    newMilestones[index][field] = value;
    setMileStonesList(newMilestones);

    // const newTouchedFields = [...touchedFields];
    // newTouchedFields[index][field] = true;
    // setTouchedFields(newTouchedFields);

    // Validate input on change
    validateSingleMilestone(index, field, value);
  };

  const validateSingleMilestone = (index, field, value) => {
    const newErrorMessages = [...milestoneErrorMessages];
    const error = newErrorMessages[index];

    switch (field) {
      case "title":
        error.title = value.length < 3 ? t("titleCharErr") : "";
        break;
      case "description":
        error.description =
          value.length < 5 ? t("DescriptionShouldBe5Characters") : "";
        break;
      case "timeDuration":
        error.timeDuration = !value ? t("PleaseAddTime") : "";
        break;
      case "depositAmount":
        error.depositAmount =
          value === "" || isNaN(value)
            ? t("validDepoistAmt")
            : value <= 0
            ? t("greaterDepoistAmt")
            : "";
        break;
      default:
        break;
    }

    setMilestoneErrorMessages(newErrorMessages);
  };

  const addMilestone = async () => {
    setIsFormSubmitted(true);
    const error = validateMilestone(true);
    if (Object.values(error).some((val) => val !== "")) {
      setMilestoneErrorMessages([...milestoneErrorMessages, error]);
      return;
    }

    if (
      newMilestone.depositAmount === "" ||
      newMilestone.description === "" ||
      newMilestone.timeDuration === "" ||
      newMilestone.title === ""
    ) {
      setMilestoneErrorMessages([...milestoneErrorMessages, error]);
      return;
    }

    if (milestonesList.length >= 4) {
      toast.error("You can only add up to 4 milestones.");
      return;
    }

    if (milestonesList.length >= 4) {
      toast.error("You can only add up to 4 milestones.");
      return;
    }
    LoaderShow();

    try {
      const token = GetUserDetails().token;
      const dataToSend = {
        projectId: projectData?._id,
        description: newMilestone.description,
        currency: "USD",
        amount: newMilestone.depositAmount,
        title: newMilestone.title,
        dueDate: newMilestone.timeDuration,
        assignedTo: userData._id,
      };

      const response = await postAPI("milestone/create", dataToSend, token);
      if (response.statusCode === 200) {
        LoaderHide();
        getMileStones();
        toast.success(response.message);
        setIsFormSubmitted(false);
        setNewMilestone({
          title: "",
          description: "",
          timeDuration: "",
          depositAmount: "",
        });
      }
    } catch (error) {
      LoaderHide();
      toast.error(error.message);
    }
  };

  // Milestone edit and delete

  const [milestoneId, setMilestoneId] = useState("");
  const [milestoneDeleteModal, setMileStoneDeleteModal] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [particularMilestone, setParticularMilsetone] = useState({
    _id: "",
    title: "",
    description: "",
    dueDate: "",
    amount: "",
  });

  const [milestoneEditErroMessages, setMileStoneEditErrorMessages] = useState({
    title: "",
    description: "",
    dueDate: "",
    amount: "",
  });

  const [isFocused, setIsFocused] = useState(false);

  const handleOpenDeleteMileStone = (id) => {
    setMileStoneDeleteModal(true);
    setMilestoneId(id);
  };

  const handleCloseModal = () => {
    setMileStoneDeleteModal(false);
  };

  const deleteMileStone = async () => {
    try {
      LoaderShow();
      const response = await deleteAPI(`milestone/${milestoneId}`);
      if (response.statusCode === 200) {
        LoaderHide();
        toast.success(response?.message);
        getMileStones();
      }
    } catch (error) {
      LoaderHide();
      // toast.error(error.message)
    }
  };

  const handleFocus = () => {
    setIsFocused(false);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleEditMileStoneTitle = (event) => {
    const value = event.target.value;
    setParticularMilsetone({ ...particularMilestone, title: value });
    setMileStoneEditErrorMessages({
      ...milestoneEditErroMessages,
      title: value.length < 5 ? t("TitleShouldBe5Characters") : "",
    });
  };

  const handleEditMileStoneDescription = (event) => {
    const value = event.target.value;
    setParticularMilsetone({ ...particularMilestone, description: value });
    setMileStoneEditErrorMessages({
      ...milestoneEditErroMessages,
      description: value.length < 5 ? t("DescriptionShouldBe5Characters") : "",
    });
  };

  const handleEditTimeDuration = (event) => {
    const value = event.target.value;
    setParticularMilsetone({ ...particularMilestone, dueDate: value });
    setMileStoneEditErrorMessages({
      ...milestoneEditErroMessages,
      dueDate: !value ? t("PleaseAddTime") : "",
    });
  };

  const handleEditDepositeAmount = (event) => {
    const value = event.target.value;
    setParticularMilsetone({ ...particularMilestone, amount: value });
    setMileStoneEditErrorMessages({
      ...milestoneEditErroMessages,
      amount: value.length === 0 ? t("PleaseAddDeposite") : "",
    });
  };

  const toggleEditModal = (id) => {
    setIsEditModalOpen(!isEditModalOpen);
    setParticularMilsetone(milestonesList?.find((e) => e._id === id));
  };

  const isNotEmpty = (value) => value.trim() !== "";

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  const validateEditMileStoneForm = () => {
    const errors = {};
    let isValid = true;

    if (
      !isNotEmpty(particularMilestone.title) ||
      particularMilestone.title.length < 5
    ) {
      errors.title = t("TitleShouldBe5Characters");
      isValid = false;
    }

    if (
      !isNotEmpty(particularMilestone.description) ||
      particularMilestone.description.length < 5
    ) {
      errors.description = t("DescriptionShouldBe5Characters");
      isValid = false;
    }

    if (!particularMilestone.dueDate) {
      errors.dueDate = t("PleaseAddTime");
      isValid = false;
    }

    if (particularMilestone.amount.length === 0) {
      errors.amount = t("PleaseAddDeposite");
      isValid = false;
    }

    setMileStoneEditErrorMessages(errors);
    return isValid;
  };

  const updateMilestone = async () => {
    if (validateEditMileStoneForm()) {
      try {
        LoaderShow();
        const token = GetUserDetails().token;

        const dataToSend = {
          projectId: projectData?._id,
          milestones: [
            {
              description: particularMilestone.description,
              currency: "USD",
              amount: particularMilestone.amount,
              title: particularMilestone.title,
              dueDate: particularMilestone.dueDate,
            },
          ],
        };

        const response = await updateAPI(
          `milestone/${particularMilestone?._id}`,
          dataToSend,
          token
        );
        if (response.statusCode === 200) {
          LoaderHide();
          toast.success(response.message);
          setIsEditModalOpen(false);
          setParticularMilsetone({
            title: "",
            description: "",
            dueDate: "",
            amount: "",
          });
          getMileStones();
        }
      } catch (error) {
        LoaderHide();
        toast.error(error.message);
      }
    }
  };

  return (
    <section className="BrowseJobsEmployee">
      <Modal
        open={milestoneDeleteModal}
        onClick={handleCloseModal}
        aria-labelledby="delete-modal"
        aria-describedby="delete-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: 4,
          }}
        >
          {/* <IconButton
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
            }}
          >
            <img src={close} alt="db" />
          </IconButton> */}
          <Box>
            <Box
              sx={{
                display: "flex",
                margin: "auto",
                justifyContent: "center",
                py: 1,
              }}
            >
              {/* <img className="deleteVec" src={DeleteIcon} alt="db" /> */}
            </Box>
            <Typography
              className="employeeHead1"
              id="logout-modal-description"
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: 32,
                textAlign: "center",
                py: 2,
                pb: 1,
              }}
              gutterBottom
            >
              {t("DeleteMilestone")}
            </Typography>
            <Typography
              className="employeeHead"
              id="logout-modal-description"
              variant="body1"
              sx={{ fontSize: 18, textAlign: "center", display: "block" }}
              gutterBottom
            ></Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-around", mt: 4 }}>
            <Button
              variant="contained"
              onClick={handleCloseModal}
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                textTransform: "capitalize",
                px: 4,
                py: 1,
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={deleteMileStone}
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                textTransform: "capitalize",
                px: 6,
                py: 1,
              }}
              variant="contained"
            >
              {t("Yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
      {isEditModalOpen && (
        <Modal
          open={isEditModalOpen}
          onClose={toggleEditModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              borderRadius: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                py: 2,
                px: 3,
                alignItems: "center",
                borderBottom: 1,
                borderColor: "#d8d8d8",
              }}
            >
              <Typography variant="h6">{t("EditMileStone")}</Typography>
              <IconButton onClick={handleCloseEditModal}>
                <img src={close} alt="db" />
              </IconButton>
            </Box>
            <Box sx={{ py: 0, pb: 4 }}>
              <Box sx={{ px: 3 }}>
                <Box sx={{ py: 1 }}>
                  <FormLabel
                    sx={{
                      display: "block",
                      textAlign: "start",
                      py: 1.4,
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#000",
                    }}
                  >
                    {t("MileStoneTitle")}
                  </FormLabel>
                  <TextField
                    value={particularMilestone?.title}
                    onChange={handleEditMileStoneTitle}
                    variant="outlined"
                    sx={{
                      borderColor: "#8692A6!important",
                      borderRadius: 4,
                    }}
                    fullWidth={true}
                    placeholder={t("MileStoneTitle")}
                  />
                  {milestoneEditErroMessages.title && (
                    <Typography variant="p" sx={{ color: "red" }}>
                      {milestoneEditErroMessages.title}
                    </Typography>
                  )}
                </Box>

                <Grid container>
                  <Grid item xs={12}>
                    <Box>
                      <FormLabel
                        sx={{
                          display: "block",
                          textAlign: "start",
                          py: 2,
                          fontWeight: "600",
                          color: "#000",
                          fontSize: 17,
                        }}
                      >
                        {t("MileStoneDescription")}
                      </FormLabel>
                      <Textarea
                        value={particularMilestone?.description}
                        onChange={handleEditMileStoneDescription}
                        sx={{
                          width: "100%!important",
                          border: 1,
                          borderColor: "#cbcbcb",
                          borderRadius: 1,
                        }}
                        minRows={6}
                        maxRows={6}
                        placeholder={t("MileStoneDescription")}
                      />
                      {milestoneEditErroMessages.description && (
                        <Typography variant="p" sx={{ color: "red" }}>
                          {milestoneEditErroMessages.description}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ my: 1 }}>
                      <FormLabel
                        sx={{
                          display: "block",
                          textAlign: "start",
                          py: 1.4,
                          fontSize: 17,
                          color: "#000",
                          fontWeight: "600",
                        }}
                      >
                        {t("TimeDuration")}
                      </FormLabel>
                      <TextField
                        value={particularMilestone?.dueDate}
                        onChange={handleEditTimeDuration}
                        variant="outlined"
                        sx={{
                          background: isFocused ? "#FFFFFF" : "transparent",
                          borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                          boxShadow: isFocused
                            ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                            : "none",
                        }}
                        fullWidth={true}
                        placeholder={t("Duration")}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                      />
                      {milestoneEditErroMessages.dueDate && (
                        <Typography variant="p" sx={{ color: "red" }}>
                          {milestoneEditErroMessages.dueDate}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ my: 1 }}>
                      <FormLabel
                        sx={{
                          display: "block",
                          textAlign: "start",
                          py: 1.4,
                          fontSize: 17,
                          color: "#000",
                          fontWeight: "600",
                        }}
                      >
                        {t("DepositeAmount")}
                      </FormLabel>
                      {/* <InputLabel>Currency</InputLabel>
                        <Select
                          value={currency}
                          onChange={handleCurrencyChange}
                          label="Currency"
                        >
                          {currencies.map((currency) => (
                            <MenuItem key={currency.code} value={currency.code}>
                              {currency.label}
                            </MenuItem>
                          ))}
                        </Select> */}
                      <TextField
                        value={particularMilestone?.amount}
                        onChange={handleEditDepositeAmount}
                        variant="outlined"
                        sx={{
                          background: isFocused ? "#FFFFFF" : "transparent",
                          borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                          boxShadow: isFocused
                            ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                            : "none",
                        }}
                        fullWidth={true}
                        placeholder={t("DepositeAmount")}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                      />
                      {milestoneEditErroMessages.amount && (
                        <Typography variant="p" sx={{ color: "red" }}>
                          {milestoneEditErroMessages.amount}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  mt: 4,
                }}
              >
                <Button
                  fullWidth={true}
                  variant="contained"
                  sx={{
                    backgroundColor: "#0B3013!important",
                    fontSize: 16,
                    borderRadius: 2,
                    textTransform: "capitalize",
                    px: 6,
                    py: 1,
                    mx: 3,
                  }}
                  onClick={updateMilestone}
                >
                  {t("UpdateMileStone")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
      <div
        id="hideloding"
        className="loding-display"
        style={{ display: "none" }}
      >
        <img src={loader} alt="loader-img" />
      </div>
      <Modal
        open={isSkillOpen}
        onClose={() => setIsSkillOpen(false)}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: 600,
            flexWrap: "wrap",
            borderRadius: "15px",
            backgroundColor: "#f8f9f8 !important",
            boxShadow: 24,
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            px: 4,
            py: 3,
            gap: "10px",
          }}
        >
          {userData?.skills?.map((skill, index) => (
            <Typography
              variant="h6"
              className="reviewBannerHead"
              sx={{
                color: "#fff",
                fontSize: 18,
                fontWeight: 500,
                bgcolor: "#0B3013",
                py: 1,
                px: 2,
                borderRadius: "20px",
              }}
            >
              {skill?.name}
            </Typography>
          ))}
        </Box>
      </Modal>
      <Container maxWidth="xl">
        <Box sx={{ py: 2 }}>
          {isMobile ? (
            <Box
              sx={{
                backgroundColor: "#0B3013",
                p: 2,
                border: "1px solid #ccc",
                borderRadius: 4,
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  sx={{ color: "#fff", fontWeight: 500, mb: 1 }}
                >
                  {t("AwardProject")} :- {userData?.firstName}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <Rating
                  name="hover-feedback"
                  disabled
                  value={userData?.averageRating}
                  precision={0.5}
                  getLabelText={getLabelText}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  onChangeActive={(event, newHover) => {
                    setHover(newHover);
                  }}
                  emptyIcon={
                    <StarIcon
                      sx={{ color: "#fff" }}
                      style={{ opacity: 1 }}
                      fontSize="inherit"
                    />
                  }
                />
                {userData?.averageRating && (
                  <Box sx={{ color: "#fff", ml: 1 }}>
                    {userData?.averageRating}
                  </Box>
                )}
              </Box>
              <Typography variant="body2" sx={{ mb: 1, color: "#fff" }}>
                {/* John Doe. Place a proposal on your project Food Delivery Mobile */}
                {userData?.firstName} {userData?.lastName}. Place a proposal on
                your project {projectData?.title}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <Avatar
                  src={userData?.profile || AvatarJob}
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: "50%",
                    marginRight: 8,
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 500, color: "#fff" }}
                >
                  {userData?.email}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <img
                  src={chatIco}
                  alt="chat"
                  style={{ width: 20, marginRight: 8, filter: "invert(1)" }}
                />
                <Typography variant="body2" sx={{ color: "#fff" }}>
                  200
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <img
                  src={dollarIco}
                  alt="dollar"
                  style={{ width: 20, marginRight: 8, filter: "invert(1)" }}
                />
                <Typography variant="body2" sx={{ color: "#fff" }}>
                  8.7
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <img
                  src={indiaIco}
                  alt="location"
                  style={{ width: 20, marginRight: 8 }}
                />
                <Typography variant="body2" sx={{ color: "#fff" }}>
                  India
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexWrap: "wrap", mb: 1 }}>
                {userData?.skills?.map((skill, index) => (
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 500, mr: 1, color: "#fff" }}
                  >
                    {skill?.name}
                  </Typography>
                ))}
                {/* <Typography
                  variant="body2"
                  sx={{ fontWeight: 500, mr: 1, color: "#fff" }}
                >
                  Illustrations Designer
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 500, color: "#fff" }}
                >
                  Figma Expert
                </Typography> */}
              </Box>
            </Box>
          ) : (
            <Box sx={{ py: 10 }}>
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                }}
              >
                <img
                  src={BrowseJobsBanner}
                  className="bannerImageSize"
                  alt="BrowseJobsBanner"
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: isMobile ? "unset" : "50%",
                    bottom: isMobile ? "0" : "unset",
                    width: "100%",
                    maxWidth: isMobile ? "100%" : "50%",
                    left: 0,
                    transform: isMobile ? "unset" : "translateY(-50%)",
                    textAlign: isMobile ? "center" : "right",
                    color: "white",
                    paddingLeft: isMobile ? 2 : 12,
                    paddingBottom: isMobile ? 2 : "unset",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      position: "relative",
                      width: "100%",
                      flexDirection: isMobile ? "column" : "row",
                    }}
                  >
                    <Box sx={{ py: 2, position: "relative" }}>
                      <Typography
                        className="reviewRateTextResp"
                        variant={isMobile ? "h6" : "h4"}
                        sx={{ color: "#fff", fontWeight: 500, mb: 1 }}
                      >
                        {t("AwardProject")} {userData?.firstName}{" "}
                      </Typography>
                      {/* <Typography
                          variant="body1"
                          sx={{
                            textAlign: "start",
                            fontWeight: "normal",
                            display: "block",
                          }}
                        >
                          Lorem Ipsum
                        </Typography> */}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: isMobile ? "column" : "row",
                      alignItems: isMobile ? "center" : "flex-start",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Avatar
                        src={userData?.profile || AvatarJob}
                        width={100}
                        height={100}
                        style={{ width: "70px", height: "70px" }}
                      />
                    </Box>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", px: 2 }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: isMobile ? "column" : "row",
                          alignItems: isMobile ? "center" : "flex-start",
                        }}
                      >
                        <Typography
                          variant={isMobile ? "h6" : "h5"}
                          sx={{ color: "#fff", fontWeight: 400 }}
                        >
                          {userData?.email}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ display: "flex", py: 1, alignItems: "center" }}
                      >
                        <Rating
                          disabled
                          name="hover-feedback"
                          value={userData?.averageRating}
                          precision={0.5}
                          getLabelText={getLabelText}
                          onChange={(event, newValue) => {
                            setValue(newValue);
                          }}
                          onChangeActive={(event, newHover) => {
                            setHover(newHover);
                          }}
                          emptyIcon={
                            <StarIcon
                              style={{ opacity: 1 }}
                              fontSize="inherit"
                            />
                          }
                        />
                        {userData?.averageRating && (
                          <Box sx={{ mx: 2 }}>{userData?.averageRating}</Box>
                        )}
                        {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={chatIco}
                            className="filterCommonWhite"
                            alt="chat"
                          />
                          <Typography
                            variant="body1"
                            sx={{ color: "#fff", px: 1 }}
                          >
                            200
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={dollarIco}
                            className="filterCommonWhite"
                            alt="chat"
                          />
                          <Typography
                            variant="body1"
                            sx={{ color: "#fff", px: 1 }}
                          >
                            8.7
                          </Typography>
                        </Box> */}
                        <Box
                          sx={{ display: "flex", alignItems: "center", pl: 2 }}
                        >
                          {userData?.location &&
                          getCountryCode(userData.location) ? (
                            <img
                              width={25}
                              height={25}
                              src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${getCountryCode(
                                userData.location
                              )}.svg`}
                              alt="chat"
                            />
                          ) : (
                            ""
                          )}
                          <Typography
                            variant="body1"
                            sx={{ color: "#fff", px: 1 }}
                          >
                            {userData?.location}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          py: 0.5,
                          flexWrap: "wrap",
                          flexDirection: isMobile ? "column" : "row",
                          alignItems: isMobile ? "center" : "flex-start",
                        }}
                      >
                        {userData?.skills?.slice(0, 5)?.map((skill, index) => (
                          <Typography
                            variant="h6"
                            className="reviewBannerHead"
                            sx={{
                              color: "#fff",
                              fontSize: 18,
                              borderRight: isMobile ? "none" : 1,
                              fontWeight: 500,
                              px: 2,
                              pl: 0,
                              my: 1,
                              mr: 2,
                            }}
                          >
                            <div style={{ marginLeft: "15px" }}>
                              {skill?.name}
                            </div>
                          </Typography>
                        ))}
                        {userData?.skills?.length > 5 && (
                          <Button
                            onClick={(ev) => setIsSkillOpen(true)}
                            sx={{
                              textTransform: "capitalize",
                              color: "#fff",
                              fontSize: 18,
                              fontWeight: 500,
                              px: 2,
                              pl: 0,
                              mb: 1,
                              mt: 0,
                              mr: 2,
                              "&:hover": {
                                bgcolor: "transparent",
                              },
                              "&:active": {
                                bgcolor: "transparent",
                              },
                            }}
                          >
                            More...
                          </Button>
                        )}
                        {/* <Typography
                          variant="h6"
                          className="reviewBannerHead"
                          sx={{
                            color: "#fff",
                            fontSize: 18,
                            borderRight: isMobile ? "none" : 1,
                            fontWeight: 500,
                            px: 2,
                          }}
                        >
                          Illustrations Designer
                        </Typography>
                        <Typography
                          className="reviewBannerHead"
                          variant="h6"
                          sx={{
                            color: "#fff",
                            fontSize: 18,
                            fontWeight: 500,
                            px: 2,
                          }}
                        >
                          Figma Expert
                        </Typography> */}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        <Box sx={{ pb: 10 }}>
          <Grid container spacing={3}>
            {" "}
            <Grid item xs={12} md={4} lg={8} xl={8}>
              <Box>
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    py: 3,
                    borderRadius: 10,
                    border: 1,
                    borderColor: "#e3e3e3",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      borderBottom: 1,
                      borderColor: "#dadbda",
                      p: 2.5,
                      pt: 0,
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: 500, color: "#000" }}
                      >
                        {t("sendAnOffer")}
                      </Typography>
                    </Box>
                  </Box>

                  <Grid container spacing={2}>
                    {/* Left half */}
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          py: 2.5,
                          pb: 0,
                          px: 2.5,
                        }}
                      >
                        <Typography
                          variant="p"
                          sx={{ color: "#000", fontWeight: 600, fontSize: 18 }}
                        >
                          {t("projectMilestone")}
                        </Typography>{" "}
                        <br />
                        <br />
                        <Typography
                          variant="p"
                          sx={{
                            fontWeight: 400,
                            color: "#000",
                            fontSize: 16,
                          }}
                        ></Typography>
                        <br />
                        <br />
                      </Box>
                      <Box>
                        {milestonesList.map((milestone, index) => (
                          <Box key={index} sx={{ px: 2.5, mb: 3 }}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{
                                  color: "#000",
                                  fontWeight: 600,
                                  fontSize: 18,
                                }}
                              >
                                {index + 1}. {t("Milestone")}
                              </Typography>
                              <div>
                                <IconButton>
                                  <img
                                    src={EditIcon}
                                    alt="Edit"
                                    style={{ width: 33, height: 33 }}
                                    onClick={() =>
                                      toggleEditModal(milestone._id)
                                    }
                                  />
                                </IconButton>
                                <IconButton
                                  onClick={() =>
                                    handleOpenDeleteMileStone(milestone._id)
                                  }
                                  // onClick={() => deleteMileStone(milestone._id)}
                                >
                                  <img
                                    src={DeleteIcon}
                                    alt="Delete"
                                    style={{ width: 33, height: 33 }}
                                  />
                                </IconButton>
                              </div>
                            </Box>
                            <Box>
                              <Box sx={{ py: 1 }}>
                                <FormLabel
                                  sx={{
                                    display: "block",
                                    textAlign: "start",
                                    py: 1.4,
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: "#000",
                                  }}
                                >
                                  {t("MileStoneTitle")}
                                </FormLabel>
                                <TextField
                                  disabled
                                  value={milestone.title}
                                  onChange={(e) =>
                                    handleMilestoneChange(
                                      index,
                                      "title",
                                      e.target.value
                                    )
                                  }
                                  variant="outlined"
                                  sx={{
                                    borderColor: "#8692A6!important",
                                    borderRadius: 4,
                                  }}
                                  fullWidth
                                  placeholder={t("MileStoneTitle")}
                                  error={Boolean(
                                    milestoneErrorMessages[index]?.title
                                  )}
                                  helperText={
                                    milestoneErrorMessages[index]?.title
                                  }
                                />
                              </Box>

                              <Box sx={{ py: 1 }}>
                                <FormLabel
                                  sx={{
                                    display: "block",
                                    textAlign: "start",
                                    py: 1.4,
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: "#000",
                                  }}
                                >
                                  {t("MileStoneDescription")}
                                </FormLabel>
                                <TextField
                                  disabled
                                  value={milestone.description}
                                  onChange={(e) =>
                                    handleMilestoneChange(
                                      index,
                                      "description",
                                      e.target.value
                                    )
                                  }
                                  variant="outlined"
                                  sx={{
                                    borderColor: "#8692A6!important",
                                    borderRadius: 4,
                                  }}
                                  fullWidth
                                  placeholder={t("MileStoneDescription")}
                                  error={Boolean(
                                    milestoneErrorMessages[index]?.description
                                  )}
                                  helperText={
                                    milestoneErrorMessages[index]?.description
                                  }
                                />
                              </Box>

                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                  <Box sx={{ py: 1.5 }}>
                                    <FormLabel
                                      sx={{
                                        display: "block",
                                        textAlign: "start",
                                        py: 1,
                                        fontSize: 16,
                                        fontWeight: 600,
                                        color: "#000",
                                      }}
                                    >
                                      {t("TimeDuration")}
                                    </FormLabel>
                                    <TextField
                                      disabled
                                      value={milestone.dueDate}
                                      onChange={(e) =>
                                        handleMilestoneChange(
                                          index,
                                          "timeDuration",
                                          e.target.value
                                        )
                                      }
                                      variant="outlined"
                                      sx={{
                                        borderColor: "#8692A6!important",
                                        borderRadius: 4,
                                      }}
                                      fullWidth
                                      placeholder={t("TimeDuration")}
                                      error={Boolean(
                                        milestoneErrorMessages[index]?.dueDate
                                      )}
                                      helperText={
                                        milestoneErrorMessages[index]?.dueDate
                                      }
                                    />
                                  </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Box sx={{ py: 1.5 }}>
                                    <FormLabel
                                      sx={{
                                        display: "block",
                                        textAlign: "start",
                                        py: 1,
                                        fontSize: 16,
                                        fontWeight: 600,
                                        color: "#000",
                                      }}
                                    >
                                      {t("DepositeAmount")}
                                    </FormLabel>
                                    <TextField
                                      disabled
                                      type="number"
                                      value={milestone.amount}
                                      onChange={(e) =>
                                        handleMilestoneChange(
                                          index,
                                          "depositAmount",
                                          e.target.value
                                        )
                                      }
                                      variant="outlined"
                                      sx={{
                                        borderColor: "#8692A6!important",
                                        borderRadius: 4,
                                      }}
                                      fullWidth
                                      placeholder={t("DepositeAmount")}
                                      error={Boolean(
                                        milestoneErrorMessages[index]?.amount
                                      )}
                                      helperText={
                                        milestoneErrorMessages[index]?.amount
                                      }
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        ))}

                        {milestonesList.length < 4 && (
                          <Box sx={{ px: 2.5, mb: 3 }}>
                            <Typography
                              variant="h6"
                              sx={{
                                color: "#000",
                                fontWeight: 600,
                                fontSize: 18,
                              }}
                            >
                              {t("addNewMilestone")}
                            </Typography>

                            <Box>
                              <Box sx={{ py: 1 }}>
                                <FormLabel
                                  sx={{
                                    display: "block",
                                    textAlign: "start",
                                    py: 1.4,
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: "#000",
                                  }}
                                >
                                  {t("MileStoneTitle")}
                                </FormLabel>
                                <TextField
                                  value={newMilestone.title}
                                  onChange={(e) =>
                                    setNewMilestone({
                                      ...newMilestone,
                                      title: e.target.value,
                                    })
                                  }
                                  variant="outlined"
                                  sx={{
                                    borderColor: "#8692A6!important",
                                    borderRadius: 4,
                                  }}
                                  fullWidth
                                  placeholder={t("MileStoneTitle")}
                                  error={
                                    isFormSubmitted &&
                                    Boolean(validateMilestone().title)
                                  }
                                  helperText={
                                    isFormSubmitted && validateMilestone().title
                                  }
                                />
                              </Box>

                              <Box sx={{ py: 1 }}>
                                <FormLabel
                                  sx={{
                                    display: "block",
                                    textAlign: "start",
                                    py: 1.4,
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: "#000",
                                  }}
                                >
                                  {t("MileStoneDescription")}
                                </FormLabel>
                                <TextField
                                  value={newMilestone.description}
                                  onChange={(e) =>
                                    setNewMilestone({
                                      ...newMilestone,
                                      description: e.target.value,
                                    })
                                  }
                                  variant="outlined"
                                  sx={{
                                    borderColor: "#8692A6!important",
                                    borderRadius: 4,
                                  }}
                                  fullWidth
                                  placeholder={t("MileStoneDescription")}
                                  error={Boolean(
                                    validateMilestone().description
                                  )}
                                  helperText={validateMilestone().description}
                                />
                              </Box>

                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                  <Box sx={{ py: 1.5 }}>
                                    <FormLabel
                                      sx={{
                                        display: "block",
                                        textAlign: "start",
                                        py: 1,
                                        fontSize: 16,
                                        fontWeight: 600,
                                        color: "#000",
                                      }}
                                    >
                                      {t("TimeDuration")}
                                    </FormLabel>
                                    <TextField
                                      type="number"
                                      value={newMilestone.timeDuration}
                                      onChange={(e) =>
                                        setNewMilestone({
                                          ...newMilestone,
                                          timeDuration: e.target.value,
                                        })
                                      }
                                      variant="outlined"
                                      sx={{
                                        borderColor: "#8692A6!important",
                                        borderRadius: 4,
                                      }}
                                      fullWidth
                                      placeholder={t("Duration")}
                                      error={Boolean(
                                        validateMilestone().timeDuration
                                      )}
                                      helperText={
                                        validateMilestone().timeDuration
                                      }
                                    />
                                  </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Box sx={{ py: 1.5 }}>
                                    <FormLabel
                                      sx={{
                                        display: "block",
                                        textAlign: "start",
                                        py: 1,
                                        fontSize: 16,
                                        fontWeight: 600,
                                        color: "#000",
                                      }}
                                    >
                                      {t("DepositeAmount")}
                                    </FormLabel>
                                    <TextField
                                      type="number"
                                      value={newMilestone.depositAmount}
                                      onChange={(e) =>
                                        setNewMilestone({
                                          ...newMilestone,
                                          depositAmount: e.target.value
                                            ? parseFloat(
                                                Number(e.target.value).toFixed(
                                                  3
                                                )
                                              )
                                            : "",
                                        })
                                      }
                                      variant="outlined"
                                      sx={{
                                        borderColor: "#8692A6!important",
                                        borderRadius: 4,
                                      }}
                                      fullWidth
                                      placeholder={t("DepositeAmount")}
                                      error={Boolean(
                                        validateMilestone().depositAmount
                                      )}
                                      helperText={
                                        validateMilestone().depositAmount
                                      }
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>

                            <Button
                              sx={{
                                fontFamily: "inherit",
                                px: 4,
                                mt: 2,
                                fontSize: "16px !important",
                                pb: 1,
                                fontWeight: 400,
                                borderRadius: 10,
                                backgroundColor: "#0B3013",
                                width: "auto",
                                maxWidth: "fit-content",
                                textTransform: "capitalize",
                                color: "#fff",
                                display: "flex",
                                alignItems: "center",
                                "&:hover": {
                                  backgroundColor: "#0B3013",
                                },
                              }}
                              onClick={addMilestone}
                            >
                              {t("AddMileStone")}
                            </Button>
                          </Box>
                        )}
                      </Box>

                      {/* <Box
                        sx={{
                          px: 2.5,
                        }}
                      >
                        <Typography
                          variant="p"
                          sx={{ color: "#000", fontWeight: 600, fontSize: 18 }}
                        >
                          1. First Milestone
                        </Typography>

                        <Box>
                          <Box sx={{ py: 1 }}>
                            <FormLabel
                              sx={{
                                display: "block",
                                textAlign: "start",
                                py: 1.4,
                                fontSize: 16,
                                fontWeight: 600,
                                color: "#000",
                              }}
                            >
                              Milestone Title
                            </FormLabel>
                            <TextField
                              value={milestoneData.title}
                              onChange={handleMileStoneTitle}
                              variant="outlined"
                              sx={{
                                borderColor: "#8692A6!important",
                                borderRadius: 4,
                              }}
                              fullWidth={true}
                              placeholder="Enter Milestone Title"
                            />
                            {milestoneErroMessages.title && (
                              <Typography variant="p" sx={{ color: "red" }}>
                                {milestoneErroMessages.title}
                              </Typography>
                            )}
                          </Box>

                          <Box sx={{ py: 1 }}>
                            <FormLabel
                              sx={{
                                display: "block",
                                textAlign: "start",
                                py: 1.4,
                                fontSize: 16,
                                fontWeight: 600,
                                color: "#000",
                              }}
                            >
                              Milestone Description
                            </FormLabel>
                            <TextField
                              value={milestoneData.description}
                              onChange={handleMileStoneDescription}
                              variant="outlined"
                              sx={{
                                borderColor: "#8692A6!important",
                                borderRadius: 4,
                              }}
                              fullWidth={true}
                              placeholder="Enter Milestone Description"
                            />
                            {milestoneErroMessages.description && (
                              <Typography variant="p" sx={{ color: "red" }}>
                                {milestoneErroMessages.description}
                              </Typography>
                            )}
                          </Box>

                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <Box sx={{ py: 1.5 }}>
                                <FormLabel
                                  sx={{
                                    display: "block",
                                    textAlign: "start",
                                    py: 1,
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: "#000",
                                  }}
                                >
                                  Time Duration
                                </FormLabel>
                                <TextField
                                  value={milestoneData.timeDuration}
                                  onChange={handleTimeDuration}
                                  variant="outlined"
                                  sx={{
                                    borderColor: "#8692A6!important",
                                    borderRadius: 4,
                                  }}
                                  fullWidth={true}
                                  placeholder="Enter Time Duration"
                                />
                                {milestoneErroMessages.timeDuration && (
                                  <Typography variant="p" sx={{ color: "red" }}>
                                    {milestoneErroMessages.timeDuration}
                                  </Typography>
                                )}
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Box sx={{ py: 1.5 }}>
                                <FormLabel
                                  sx={{
                                    display: "block",
                                    textAlign: "start",
                                    py: 1,
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: "#000",
                                  }}
                                >
                                  Deposit Amount
                                </FormLabel>
                                <TextField
                                  value={milestoneData.depositeAmount}
                                  onChange={handleDepositeAmount}
                                  variant="outlined"
                                  sx={{
                                    borderColor: "#8692A6!important",
                                    borderRadius: 4,
                                  }}
                                  fullWidth={true}
                                  placeholder="Enter  Deposit Amount"
                                />
                                {milestoneErroMessages.depositeAmount && (
                                  <Typography variant="p" sx={{ color: "red" }}>
                                    {milestoneErroMessages.depositeAmount}
                                  </Typography>
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        <Button
                          sx={{
                            fontFamily: "inherit",
                            px: 4,
                            mt: 2,
                            fontSize: "16px !important",
                            pb: 1,
                            fontWeight: 400,
                            borderRadius: 10,
                            backgroundColor: "#0B3013",
                            width: "auto",
                            maxWidth: "fit-content",
                            textTransform: "capitalize",
                            color: "#fff",
                            display: "flex",
                            alignItems: "center", // Align icon vertically with text
                            "&:hover": {
                              backgroundColor: "#0B3013", // Keep the same color as default
                            },
                          }}
                          onClick={addMileStone}
                        >
                          Add Milestone
                        </Button>
                      </Box> */}

                      {/* <Box
                        sx={{
                          px: 2.5,
                          my: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            variant="p"
                            sx={{
                              color: "#000",
                              fontWeight: 600,
                              fontSize: 18,
                            }}
                          >
                            2. Second Milestone
                          </Typography>
                          <Box sx={{ cursor: "pointer" }}>
                            <img src={del} alt="dd" />
                          </Box>
                        </Box>

                        <Box>
                          <Box sx={{ py: 1 }}>
                            <FormLabel
                              sx={{
                                display: "block",
                                textAlign: "start",
                                py: 1.4,
                                fontSize: 16,
                                fontWeight: 600,
                                color: "#000",
                              }}
                            >
                              Milestone Description
                            </FormLabel>
                            <TextField
                              variant="outlined"
                              sx={{
                                borderColor: "#8692A6!important",
                                borderRadius: 4,
                              }}
                              fullWidth={true}
                              placeholder="Enter Milestone Description"
                            />
                          </Box>

                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <Box sx={{ py: 1.5 }}>
                                <FormLabel
                                  sx={{
                                    display: "block",
                                    textAlign: "start",
                                    py: 1,
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: "#000",
                                  }}
                                >
                                  Time Duration
                                </FormLabel>
                                <TextField
                                  variant="outlined"
                                  sx={{
                                    borderColor: "#8692A6!important",
                                    borderRadius: 4,
                                  }}
                                  fullWidth={true}
                                  placeholder="Enter Time Duration"
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Box sx={{ py: 1.5 }}>
                                <FormLabel
                                  sx={{
                                    display: "block",
                                    textAlign: "start",
                                    py: 1,
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: "#000",
                                  }}
                                >
                                  Deposit Amount
                                </FormLabel>
                                <TextField
                                  variant="outlined"
                                  sx={{
                                    borderColor: "#8692A6!important",
                                    borderRadius: 4,
                                  }}
                                  fullWidth={true}
                                  placeholder="Enter  Deposit Amount"
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        <Button
                          sx={{
                            fontFamily: "inherit",
                            px: 4,
                            mt: 2,
                            fontSize: "16px !important",
                            pb: 1,
                            fontWeight: 400,
                            borderRadius: 10,
                            backgroundColor: "#0B3013",
                            width: "auto",
                            maxWidth: "fit-content",
                            textTransform: "capitalize",
                            color: "#fff",
                            display: "flex",
                            alignItems: "center", // Align icon vertically with text
                            "&:hover": {
                              backgroundColor: "#0B3013", // Keep the same color as default
                            },
                          }}
                        >
                          <AddIcon style={{ marginRight: "8px" }} /> Add
                          Milestone 
                        </Button>
                      </Box> */}

                      <Grid container>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              px: 2.5,
                            }}
                          >
                            {/* <FormLabel
                              sx={{
                                display: "block",
                                textAlign: "start",
                                py: 1.4,
                                fontSize: 16,
                                fontWeight: 600,
                                color: "#000",
                              }}
                            >
                              Work Description{" "}
                            </FormLabel>
                            <Textarea
                              sx={{
                                width: "100%!important",
                                border: 1,
                                borderColor: "#cbcbcb",
                                borderRadius: 1,
                              }}
                              minRows={6}
                              maxRows={6}
                              placeholder="Work Description"
                            /> */}
                          </Box>
                        </Grid>
                      </Grid>

                      <Box
                        sx={{
                          px: 4,
                          my: 4,
                        }}
                      >
                        {/* <Typography
                          variant="h6"
                          sx={{
                            color: "#000",
                            fontWeight: 600,
                            pb: 0,
                            fontSize: 18,
                            px: 0,
                          }}
                        >
                          Upload Attachments
                        </Typography>
                        <Box
                          className="uploadSec"
                          sx={{
                            py: 3,
                            pb: 0,
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <img
                              src={doc}
                              alt="Delete"
                              style={{ cursor: "pointer" }}
                            />

                            <Typography
                              variant="p"
                              sx={{ color: "#000", px: 2, wordBreak: 'break-all' }}
                            >
                              84c110f299f31bad53eee9731d43cbca.jpg (154kB)
                            </Typography>
                            <img
                              src={del}
                              alt="Delete"
                              style={{ cursor: "pointer" }}
                            />
                          </Box>
                          <Box sx={{ py: 4, pb: 0 }}>
                            <DropzoneArea
                              acceptedFiles={["image/*"]}
                              dropzoneText="Drag and drop an image here or click"
                              // onDelete={(file) => console.log("File deleted:", file)}
                              showPreviewsInDropzone={false}
                              showAlerts={false}
                              dropzoneclassName="custom-dropzone" // Custom CSS class for DropzoneArea
                            />
                          </Box>
                        </Box> */}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item md={8} lg={4} xl={4}>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  border: 1,
                  my: 4,
                  mt: 0,
                  p: 3,
                  py: 2,
                  borderRadius: 10,
                  borderColor: "#e3e3e3",
                }}
              >
                <Box sx={{ py: 1.5, borderBottom: 1, borderColor: "#d6d6d6" }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 500, color: "#000" }}
                  >
                    {t("hireFor")} : {projectData?.title}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    py: 2,
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
                  >
                    {t("subtotal")}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
                  >
                    ${subTotal}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    py: 0,
                    pb: 3,
                    borderBottom: 1,
                    borderColor: "#d6d6d6",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
                  >
                    {t("marketPlaceFee")}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
                  >
                    {subTotal ? "$" + getCommision(subTotal) : "$0"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    py: 2,
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
                  >
                    {t("estimatedTotal")}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 500, color: "#222", fontSize: 17 }}
                  >
                    {subTotal === 0 ? "$0" : "$" + estimatedTotal}
                  </Typography>
                </Box>
                <Button
                  fullWidth={true}
                  sx={{
                    textTransform: "capitalize",
                    fontFamily: "inherit",
                    fontSize: 16,
                    backgroundColor: "#0B3013",
                    color: "#fff",
                    py: 1.2,
                    my: 2,
                    px: 3,
                    borderRadius: 20,
                    "&:hover": {
                      bgcolor: "#0a3114",
                      color: "#fff",
                    },
                  }}
                  onClick={handleModalOpen}
                >
                  {t("pay")}
                </Button>
                {milestonesList.some(
                  (ele) => ele.status != "Payment pending"
                ) && (
                  <Button
                    fullWidth={true}
                    sx={{
                      textTransform: "capitalize",
                      fontFamily: "inherit",
                      fontSize: 16,
                      backgroundColor: "#0B3013",
                      color: "#fff",
                      py: 1,
                      my: 2,
                      px: 3,
                      borderRadius: 20,
                      "&:hover": {
                        bgcolor: "#0a3114",
                        color: "#fff",
                      },
                    }}
                    onClick={awardProject}
                  >
                    Award
                  </Button>
                )}
              </Box>
              {/* <Box
                sx={{
                  backgroundColor: "#fff",
                  border: 1,
                  p: 3,
                  py: 2,
                  borderRadius: 10,
                  borderColor: "#e3e3e3",
                }}
              >
                <Box sx={{ py: 1.5, borderBottom: 1, borderColor: "#e3e3e3" }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 500, color: "#000" }}
                  >
                    Top Skills
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", flexWrap: "wrap", py: 2 }}>
                  {
                    particularUser?.skills?.map((skill, index) => (
                      <Box
                        sx={{
                          width: "auto",
                          height: "fit-content",
                          maxWidth: "fit-content",
                          backgroundColor: "#0B3013",
                          color: "#fff",
                          py: 1.2,
                          px: 3,
                          m: 0.5,
                          mr: 2,
                          borderRadius: 20,
                        }}
                      >
                        {skill.name}
                      </Box>
                    ))
                  }
                </Box>
              </Box> */}
            </Grid>
          </Grid>
        </Box>
      </Container>

      <SubscriptionModal
        totalFee={getCommision(subTotal)}
        open={openModal}
        onClose={handleModalClose}
        onCryptoSelected={handleCryptoModalOpen}
        balance={estimatedTotal}
        transactionId={transactionId}
        projectId={projectData?._id}
        userId={userData}
        milestoneId={milestonesList
          .filter((ele) => ele.status == "Payment pending")
          .map((ele) => ele._id)}
      />
      <SelectNetwork
        open={cryptoModalOpen}
        onClose={handleCryptoModalClose}
        onSubmit={handleNewModalOpen}
        balance={estimatedTotal}
        openConinsModal={newModalOpen}
        closeConinsModal={handleNewModalClose}
      />
      {/* <CoinPayments open={newModalOpen} onClose={handleNewModalClose} /> */}
    </section>
  );
}
