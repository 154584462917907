import React from "react";
import { Route, Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ element }) => {
  const location = useLocation();
  try {
    const isLoggedIn = JSON.parse(localStorage.getItem("loginData")); // Check if token exists in local storage
    const { token, role } = isLoggedIn; // Assuming you have a way to get the token
    if (!token) {
      return <Navigate to="/" replace />;
    }

    if (location.pathname.includes("accountant") && role != "accountant")
      return <Navigate to="/" replace />;

    if (location.pathname.includes("employer") && role != "employer")
      return <Navigate to="/" replace />;

    if (location.pathname.includes("employee") && role != "employee")
      return <Navigate to="/" replace />;

    return element;
  } catch (err) {
    return <Navigate to="/" replace />;
  }
};

export default ProtectedRoute;
