import React, { useState } from "react";
import { Box, Typography, Button, FormLabel } from "@mui/material";
import { styled } from "@mui/system";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/material";
// import { TextareaAutosize } from '@mui/material';

import { postAPI } from "../../../../../Services/Api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "../../../../../Localization/i18n";
import { useNavigate } from "react-router-dom";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { db } from "../../../../Firebase/firebase";
// import "./AboutEmployee.css";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${blue[400]};
  }

  

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

export default function WhatYouNeedEmployer(props) {
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const navigate = useNavigate();

  const { t } = useTranslation();
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    if (!event.target.value) {
      setDescriptionError("Description is required");
    } else {
      setDescriptionError("");
    }
  };

  const RegisterUser = async () => {
    const newlineCount = description.trim().length;

    if (newlineCount >= 30) {
      setDescriptionError("");
    } else {
      setDescriptionError("Please enter minimum 30 characters.");
    }

    // if (!description) {
    //   setDescriptionError("Description is required");
    // }

    if (newlineCount >= 3) {
      try {
        const employerUserData = {
          firstName: props.employerUserData.registrationDataEmployer.firstName,
          lastName: props.employerUserData.registrationDataEmployer.lastName,
          userName: props.employerUserData.registrationDataEmployer.userName,
          email: props.employerUserData.registrationDataEmployer.email,
          isEmailVerified: false,
          password: props.employerUserData.registrationDataEmployer.password,
          phoneNumber:
            props.employerUserData.registrationDataEmployer.phoneNumber,
          isPhoneNumberVerified: false,
          // "skills": props.employerUserData.skillsetEmployer,
          skills: props.employerUserData.skillsetIDEmployer,
          isSubscribed: false,
          profile: "",
          aboutMe: description,
          DOB: "",
          location:
            props?.employerUserData?.registrationDataEmployer?.location || "D",
          role: "employer",
        };

        const response = await postAPI("user/register", employerUserData);
        if (response.statusCode === 200) {
          await addDoc(collection(db, "users"), {
            firstName:
              props.employerUserData.registrationDataEmployer.firstName || "",
            lastName:
              props.employerUserData.registrationDataEmployer.lastName || "",
            imageUrl: "",
            createdAt: Timestamp.now(),
            lastSeen: Timestamp.now(),
            metadata: null,
            role: null,
            updatedAt: Timestamp.now(),
          });
          toast.success(response.message, {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          var loginDetails = response.data;
          var objLoginData = {
            userId: loginDetails._id,
            firstName: loginDetails.firstName,
            token: response.token,
            role: loginDetails.role,
            userProfile: loginDetails.profile,
            email: loginDetails.email,
          };
          localStorage.setItem("loginData", JSON.stringify(objLoginData));
          navigate("/dashboard/employer/browseprofiles");
          // window.location.reload();
          props.handleNextStepEmployer();
        } else {
          toast.error("failed to register user!", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (error) {
        console.error("Failed to post data:", error.message);
        toast.error("failed to register user!", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  return (
    <Box
      sx={{
        p: 8,
        pt: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
      className="sideBg"
    >
      <Box sx={{ visibility: "hidden" }}>
        <p className="beginText">
          For the purpose of industry regulation, your details are required.{" "}
        </p>
      </Box>
      <Box sx={{ pt: 3 }}>
        <Typography variant="h5" sx={{ textAlign: "start", fontWeight: 600 }}>
          {t("Describe")}
        </Typography>

        <Textarea
          sx={{ mt: 3, width: "100%", borderRadius: 1, minHeight: "70px" }}
          maxRows={6}
          fullWidth={true}
          aria-label="maximum height"
          placeholder={t("MaxRow")}
          value={description} // Use the description state value
          onChange={handleDescriptionChange}
          rows={4}
        />
        {descriptionError && (
          <Typography variant="body2" sx={{ color: "red" }}>
            {descriptionError}
          </Typography>
        )}
      </Box>

      <Box sx={{ mt: 4 }}>
        <Button
          // onClick={props.handleNextStepEmployer}
          onClick={RegisterUser}
          className="SignUpBtn"
          sx={{ textTransform: "capitalize", width: 100, color: "white" }}
        >
          {t("Submite")}
        </Button>
      </Box>
      <Box sx={{ my: 1 }}>
        <FormLabel
          sx={{
            display: "flex",
            textAlign: "center",
            py: 1,
            alignItems: "center",
            justifyContent: "center",
            fontSize: 14,
          }}
        >
          <LockOutlinedIcon fontSize="12" sx={{ px: 1 }} />
          {t("YourInfo")}
        </FormLabel>
      </Box>
    </Box>
  );
}
