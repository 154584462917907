import React from "react";
import { Box, Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import girlBG from "../../../Assets/Images/GirlBanner.png";
// import SearchIcon from "@mui/icons-material/Search";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import Button from "@mui/material/Button";
// import { styled, alpha } from "@mui/material/styles";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import freelanceIcon from "../../../Assets/Images/FreelanceIcon.png";
import ProjectIcon from "../../../Assets/Images/ProjectIcon.png";
import SecureIcon from "../../../Assets/Images/SecureIcon.png";
import { makeStyles } from "@material-ui/core/styles";
import "./HomeBanner.css";
import { useTranslation } from "react-i18next";
import "../../../Localization/i18n";

const useStyles = makeStyles({
  subHead: {
    color: "#fff",
    listStyleType: "disc", // You can change this to 'circle' or 'square' as needed
    marginLeft: "0px", // Adjust the margin as needed
    paddingLeft: 25,
    fontSize: 24,
    lineHeight: 1.5,
  },
});

// const StyledMenu = styled((props) => (
//   <Menu
//     elevation={0}
//     anchorOrigin={{
//       vertical: "bottom",
//       horizontal: "right",
//     }}
//     transformOrigin={{
//       vertical: "top",
//       horizontal: "right",
//     }}
//     {...props}
//   />
// ))(({ theme }) => ({
//   "& .MuiPaper-root": {
//     borderRadius: 6,
//     marginTop: theme.spacing(1),
//     minWidth: 180,
//     color:
//       theme.palette.mode === "light"
//         ? "rgb(55, 65, 81)"
//         : theme.palette.grey[300],
//     boxShadow:
//       "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
//     "& .MuiMenu-list": {
//       padding: "4px 0",
//     },
//     "& .MuiMenuItem-root": {
//       "& .MuiSvgIcon-root": {
//         fontSize: 18,
//         color: theme.palette.text.secondary,
//         marginRight: theme.spacing(1.5),
//       },
//       "&:active": {
//         backgroundColor: alpha(
//           theme.palette.primary.main,
//           theme.palette.action.selectedOpacity
//         ),
//       },
//     },
//   },
// }));

const HomeBanner = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  return (
    <div className="HomeBanner">
      <Container maxWidth="xl" sx={{ px: { md: 8, xl: 4, xxl: 4 }, }}>
        <Box sx={{ flexGrow: 1, pt: 10, paddingLeft: 4, paddingRight: 5 }} className='resposiveTop'>
          <Grid container spacing={2}>
            <Grid xs={12} xl={7} lg={7} md={7} sx={{ py: 4 }}>
              <div className="heroBannerText">
                <h1 className="heroheading">
                  {t("OneStop")}
                </h1>

                <ul className={`${classes.subHead} customClass`}>
                  <li>{t("Match")}</li>
                  <li>{t("Simple")}</li>
                  <li>{t("Build")}</li>
                  <li>{t("Connect")}</li>
                </ul>
              </div>
              {/* <div className="mainCustomCat">
                <Grid container>
                  <Grid
                    md={5}
                    lg={6}
                    xl={6}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <SearchIcon sx={{ fontSize: 30 }} />
                    <input
                      type="text"
                      className="catTextBox"
                      placeholder="What are you looking for?"
                    />
                  </Grid>
                  <Grid
                    md={4}
                    lg={4}
                    xl={3}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <div>
                      <Button
                        id="demo-customized-button"
                        aria-controls={
                          open ? "demo-customized-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        disableElevation
                        onClick={handleClick}
                        sx={{
                          textTransform: "capitalize",
                          color: "#222222",
                          fontSize: 16,
                          marginLeft: 2,
                        }}
                        endIcon={<KeyboardArrowDownIcon />}
                      >
                        Choose Category
                      </Button>
                      <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        disableScrollLock // Add disableScrollLock prop here
                      >
                        <MenuItem onClick={handleClose} disableRipple>
                          IT & Software
                        </MenuItem>
                        <MenuItem onClick={handleClose} disableRipple>
                          Medical
                        </MenuItem>
                        <MenuItem onClick={handleClose} disableRipple>
                          Architecture
                        </MenuItem>
                      </StyledMenu>
                    </div>
                  </Grid>
                  <Grid md={3} lg={2} xl={3}>
                    <Button className="SearchBtnCat">Search</Button>
                  </Grid>
                </Grid>
              </div> */}
              <Box className="counterDivBanner">
                <div className="stCounter">
                  <div className="counter">
                    <h1 className="stCounter">500M</h1>
                    <p className="stCounter">{t("TotalFreelance")}</p>
                  </div>
                  <div className="counter">
                    <h1 className="stCounter">300M</h1>
                    <p className="stCounter">{t("Positive")}</p>
                  </div>
                  <div className="counter">
                    <h1 className="stCounter">100M</h1>
                    <p className="stCounter">{t("Order")}</p>
                  </div>
                  <div className="counter">
                    <h1 className="stCounter">50M</h1>
                    <p className="stCounter">{t("Projects")} </p>
                  </div>
                </div>
              </Box>
            </Grid>
            <Grid xs={5} className="girlPosBlock dNoneRespo">
              <div className="mainGirlBlock">
                <div className="floatingCounterContainer">
                  <div className="floatingCounter freelanceCounter counterGirl">
                    <div className="insideFreelance">
                      <div>
                        <img src={freelanceIcon} alt="c" />
                      </div>
                      <h2 className="activeTxt">20M+</h2>
                      <h4 className="activeTxt">{t('Active')}</h4>
                    </div>
                  </div>
                </div>
                <img src={girlBG} alt="dn" className="girlBanner" />
                <div className="secCounterContainer">
                  <div className="projCounter counterGirl">
                    <div className="insideFreelance1">
                      <div className="secue">
                        <img src={SecureIcon} alt="c" />
                      </div>
                      <div className="miniCount">
                        <h4 className="activeTxt">{t("Safe")}</h4>
                        <h4 className="activeTxt1">
                        {t("Trustworthy")}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="projCounterContainer">
                  <div className="projCounter counterGirl">
                    <div className="insideFreelance">
                      <div>
                        <img src={ProjectIcon} alt="c" />
                      </div>
                      <h2 className="activeTxt">20M+</h2>
                      <h4 className="activeTxt">{t('TwentyM')}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default HomeBanner;
