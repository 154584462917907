import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import DashboardHeader from "./DashboardHeader/DashboardHeader";
import DashboardSidebar from "./DashboardSidebar/DashboardSidebar";
import { GetUserDetails } from "../../Helpers/Utility";
import Box from "@mui/material/Box";

const userDetails = GetUserDetails();

const drawerWidth = 290;

const DashboardLayout = (props) => {
  const { window } = props;
  const [open, setOpen] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isMobile] = useState(
    window && window.innerWidth ? window.innerWidth <= 600 : false
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const toggleSidebar = () => {
    if (isMobile) {
      setMobileOpen(!mobileOpen); // Toggle mobile sidebar
    } else {
      setOpen(!open); // Toggle desktop sidebar
    }
  };

  // useEffect(() => {
  //   console.log("mobileOpen state:", mobileOpen);
  // }, [mobileOpen]);

  // useEffect(() => {
  //   console.log("open state:", open);
  // }, [open]);

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "#0B3013 !important",
          boxShadow: "none",
          borderBottom: 1,
          borderColor: "#000",
        }}
      >
        <DashboardHeader handleDrawerToggle={handleDrawerToggle} />
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#0B3013 !important",
            },
          }}
        >
          <DashboardSidebar
            userRole={userDetails?.role}
            closeSidebar={handleDrawerToggle} // Pass closeSidebar function here
            />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#0B3013 !important",
            },
          }}
          open={open} // Ensure that the open prop is set correctly
        >
          <DashboardSidebar
            handleDrawerOpen={handleDrawerOpen}
            userRole={userDetails?.role}
            closeSidebar={toggleSidebar}
          />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: { xs: 12, sm: 14, md: 15 }, // Adjust padding top for different breakpoints
          px: { xs: 2, sm: 3, md: 8 }, // Adjust padding x for different breakpoints
          width: { md: `calc(100% - ${drawerWidth}px)` },
          background: "#f8f8f9",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          position: "relative",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default DashboardLayout;
