import React from "react";
import { Box, Typography } from "@mui/material";
import "./ModalBannerText1.css";
import { useTranslation } from "react-i18next";
import "../../../Localization/i18n";

export default function ModalBannerText1() { 
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start", // Align items to the start
        px: 11,
      }}
    >
      <Typography
        variant="div"
        sx={{
          fontSize: { xl: 28, md: 22 },
          display: "block",
          fontWeight: 600,
          textAlign: "start",
          pb:4
        }}
      >
     {t('Explore')}
      </Typography>
      <ul className="ulListContent">
        <li className="listContent">
        {t('FindJob')}
        </li>
        <li className="listContent">
        {t('ShowcaseSkill')}
        </li>
        <li className="listContent">
        {t('Get')}
        </li>
        <li className="listContent">
        {t('ManageJob')}
        </li>
      </ul>
    </Box>
  );
}
