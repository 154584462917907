import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Accordion,
  Typography,
  useMediaQuery,
  useTheme,
  Modal,
  AccordionSummary,
  AccordionDetails,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
// Assuming close and logoutVect are imported from their respective sources
import close from "../../../../Assets/Images/cancel-circle-half-dot.png";
import logoutVect from "../../../../Assets/Images/LogoutVector.png";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled, alpha } from "@mui/material/styles";

const StyledAccordion = styled(Accordion)({
  boxShadow: "none", // Remove box shadow from accordion
});

// Assuming open, handleClose, isMobile, isDesktop, handleCloseModal, and handleLogout are props passed from parent component
export default function FilterModal({ open, handleClose }) {
  const [expanded, setExpanded] = useState("panel1"); // Set the first panel to be expanded by default
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [rateType, setRateType] = useState(""); // Define rateType state

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  const handleRateTypeChange = (event) => {
    setRateType(event.target.value);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="logout-modal"
      aria-describedby="logout-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          width: isMobile ? "90%" : isDesktop ? 510 : "75%", // Adjust width for mobile and desktop devices
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" sx={{ mb: 1, fontWeight: 500 }}>
          Filter
        </Typography>
        <IconButton
          sx={{
            position: "absolute",
            top: 14,
            right: 10,
            pb: 4,
          }}
          onClick={handleClose}
        >
          <img src={close} alt="Close" />
        </IconButton>
        <Box sx={{ borderTop: 1, borderColor: "#dededf" }}>
          <Box>
            <div>
              <StyledAccordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography
                    variant="p"
                    sx={{ fontWeight: 500, color: "#000" }}
                  >
                    Project Type
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="rate-type"
                      name="rate-type"
                      value={rateType}
                      onChange={handleRateTypeChange}
                    >
                      <FormControlLabel
                        value="hourly"
                        control={<Radio sx={{ color: "#0B3013!important" }} />}
                        label="Hourly Rate"
                      />
                      <FormControlLabel
                        value="fixed"
                        control={<Radio sx={{ color: "#0B3013!important" }} />}
                        label="Fixed Rate"
                      />
                    </RadioGroup>
                  </FormControl>
                </AccordionDetails>
              </StyledAccordion>

              <StyledAccordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography variant="p" sx={{ color: "#000" }}>
                    Budget
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography> */}
                </AccordionDetails>
              </StyledAccordion>
              <StyledAccordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  <Typography variant="p" sx={{ color: "#000" }}>
                    Location
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </StyledAccordion>

              <Box sx={{ my: 3 }}>
                <Button
                  onClick={handleClose}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                    textTransform: "capitalize",
                    bgcolor: "#0a3114",
                    fontSize: 16,
                    color: "#fff",
                    borderRadius: 10,
                    px: 5,
                    py: 1,
                    "&:hover": {
                      bgcolor: "#0a3114",
                    },
                  }}
                  endIcon={<ArrowOutwardIcon color="#fff" />}
                >
                  Clear
                </Button>{" "}
              </Box>
            </div>
          </Box>
        </Box>

      </Box>
    </Modal>
  );
}
