import React from "react";
import { Grid, Container, Box, Typography, Button } from "@mui/material";
import donateBg from "../../../Assets/Images/donateBG.png";
import { useTranslation } from "react-i18next";
import "../../../Localization/i18n";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import "./HomeDonation.css";

export default function HomeDonation() {
    const { t } = useTranslation();

  return (
    <section className="HomeTestinomials" style={{marginTop: "30px"}}>
      <Container maxWidth="xl">
        <Grid
          container
          spacing={4} // Add spacing between grid items
          sx={{ px: { xs: 2, md: 6, xl: 6, xxl: 6 }, pb: 10, alignItems: "center" }}
          className="testinomialPaddingTop"
        >
          <Grid item xs={12} lg={6}>
            <img src={donateBg} className="donateBG" alt="df" />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box>
              <Typography
                className="LoveHeadeText"
                variant="h4"
                sx={{ color: "black", py: 1, fontWeight: 600 }}
              >
                {t('DonateOneTake')}
              </Typography>
              <Typography
                className="LoveHeadeText"
                variant="body1" // Use body1 instead of p
                sx={{ color: "black", py: 2, display: "block" }}
              >
             {t('DonateTwoTake')}
              </Typography>

              <Box sx={{ display: "flex", justifyContent: "start", }}>
                <Button
                  className="catBtnTop"
                  sx={{
                    textTransform: "capitalize",
                    bgcolor: "#0B3013",
                    fontSize: 16,
                    color: "#fff",
                    borderRadius: 10,
                    px: 8,
                    py: 1,
                    '&:hover':{bgcolor: "#0B3013"},
                  }}
                  endIcon={<ArrowOutwardIcon color="#fff" />}
                >
                  {t('Dontae')}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
