import React, { useState, useEffect } from "react";
import "./BrowseJobsEmployer.css";
import BrowseJobsBanner from "../../../Assets/Images/BrowseJobsBanner.png";
import AvatarJob from "../../../Assets/Images/BrowseJobsAvatar.png";
import chatIco from "../../../Assets/Images/ChatIco.png";
import dollarIco from "../../../Assets/Images/dollar-circle.png";
// import indiaIco from "../../../Assets/Images/indiaFlag.png";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  Rating,
  FormLabel,
} from "@mui/material";

import StarIcon from "@mui/icons-material/Star";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search"; // Import SearchIcon properly
import Menu from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import { getAPI, postAPI } from "../../../Services/Api.js";
import { GetUserDetails, LoaderHide, LoaderShow } from "../../../Helpers/Utility";
import loader from "../../../Assets/Images/loader.svg"
import { useNavigate } from "react-router-dom";
import NodataFound from "../../../Components/NodataFound/NodataFound.jsx";
import Dropdown from "../../../Components/Dropdown/Dropdown.jsx";
import { countries } from "../../../Helpers/common_constants.js";
import { useTranslation } from "react-i18next";
import "../../../Localization/i18n";
import { getDropDownCategory, getDropDownValue, getProjectByCategory } from "../../../Services/CategoryApi.js";
import { useBrowserJob } from "../../../context/BrowserJob.jsx";
import Signin from "../../../Auth/Signin/Signin.jsx";

const StyledAccordion = styled(Accordion)({
  boxShadow: "none", // Remove box shadow from accordion
});
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    backgroundColor: "#F7F7F7",
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const labels = {
  0.5: "0.5",
  1: "1.0",
  1.5: "1.5",
  2: "2.0",
  2.5: "2.5",
  3: "3.0",
  3.5: "3.5",
  4: "4.0",
  4.5: "4.5",
  5: "5.0",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export default function BrowseJobsEmployer() {

  // signup modal
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [joinModalOpen, setJoinModalOpen] = useState(false);
  
  const handleOpenLoginModal = () => {
    setLoginModalOpen(true);
  };
  const handleCloseLoginModal = () => {
    setLoginModalOpen(false);
  };
  const toggleSignUpModal = () => {
    setJoinModalOpen(!joinModalOpen);
  };


  const [expanded, setExpanded] = useState("panel1"); // Set the first panel to be expanded by default

  const [anchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const [rateType, setRateType] = useState("hourly"); // Define rateType state
  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);
  const [isFavorite, setIsFavorite] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(t("Choose"));

  const [formData, setFormData] = useState({
    budget: "",
    location: ""
  });



  const { category, setCategory, projectData, setProjectData } = useBrowserJob()

  const fetchDropDownValue = async () => {
    let response = await getDropDownValue()
    setCategory(response?.data?.categories)
    fetchProjectByCat(response?.data?.categories[0])
  }
  const fetchProjectByCat = async (catId) => {
    // console.log("cat ", catId)
    let response = await getProjectByCategory(catId?._id)
    setProjectData(response?.data)
  }

  useEffect(() => {
    fetchDropDownValue()
  }, [])


  const handleBudget = (event) => {

    const value = event.target.value
    setFormData((prevFormData) => ({
      ...prevFormData,
      budget: value,
    }));

    getFilteredEmployeeList(value)

  };

  const handleLocation = (event) => {
    const value = event.target.value
    setFormData((prevFormData) => ({
      ...prevFormData,
      location: value,
    }));

    getFilteredEmployeeList(value)
  };

  const budgetValues = [
    100, 200, 300, 400, 500, 600, 700, 800, 900
  ];

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (category) => {
    if (category) {
      fetchProjectByCat(category)
      setSelectedCategory(category?.name);
      getFilteredEmployeeList(category?.name)
    }
    setAnchorEl(null);
  };


  useEffect(() => {
    getEmployeeList();
    LoaderHide()
  }, []);

  const navigate = useNavigate();

  const getEmployeeList = async (pageNumber) => {
    LoaderShow()
    try {
      LoaderShow()
      const token = GetUserDetails().token;

      var pn
      if (pageNumber === undefined || pageNumber === "") {
        pn = 1
      } else {
        pn = pageNumber
      }

      const response = await getAPI(`user/EmployeeList?page=${pn}`, token);
      if (response.statusCode === 200) {
        LoaderHide()
        setEmployeeList(response.data);
        setTotalPages(response?.pagination?.totalPages);
      }
    } catch (error) {
      LoaderHide()
      // toast.error(error.message)
    }
    LoaderHide()
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getEmployeeList(value)
  };

  const handleFavoriteClick = () => {
    setIsFavorite(!isFavorite);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  const handleRateTypeChange = (event) => {
    setRateType(event.target.value);
    getFilteredEmployeeList(event.target.value)
  };

  const navigateToPraposeEmployer = (user) => {
    navigate(
      "/reviewratings",
      {
        state: {
          user
        }
      }
    )
  }

  const [query, setQuery] = useState('');

  const handleSearch = async () => {
    try {
      LoaderShow()
      const dataToSend = { skill: query }
      const response = await getAPI(`user/search?search=${query}`, dataToSend)
      if (response.statusCode === 200) {
        setEmployeeList(response.data)
        setTotalPages(response?.pagination?.totalPages);
        LoaderHide()
      }
    } catch (error) {
      LoaderHide()
      console.error("Error fetching data: ", error);
    }
  };

  const getFilteredEmployeeList = async (filters) => {
    try {
      // const dataToSend = { rateType: filters }
      LoaderShow()
      const dataToSend = {
        rateType: filters
      }
      const response = await postAPI("user/filter", dataToSend);
      if (response.statusCode === 200) {
        LoaderHide()
        setEmployeeList(response.data);
        setTotalPages(response?.pagination?.totalPages);
      }
    } catch (error) {
      LoaderHide()
      // Handle error (e.g., show a toast message)
      console.error("Error fetching filtered employees:==", error);
    }
  };


  return (
    <section className="BrowseJobsEmployee">
      <div id="hideloding" className="loding-display">
        <img src={loader} alt="loader-img" />
      </div>
      <Container maxWidth="xl">
        <Box className="padBotZero catBtnTop padTopZero" sx={{ py: 10 }}>
          <Box sx={{ position: "relative" }}>
            <img
              src={BrowseJobsBanner}
              className="bannerImageSize"
              alt="BrowseJobsBanner"
            />
            <Box
              className="DesignAbs"
              sx={{
                position: "absolute",
                top: "55%",
                width: "100%",
                maxWidth: "50%",
                left: 0,
                transform: "translateY(-50%)",
                textAlign: "right",
                color: "white",
                paddingLeft: 6, // Add padding to separate text from the image
              }}
            >
              <Typography
                sx={{
                  textAlign: "start",
                  py: 2,
                  typography: { xl: "h3", lg: "h3", md: "h3", xs: "h8" },
                }}
                className="designBigHead"
              >
                {t("DesignCreative")}
              </Typography>
              <Typography
                className="dNoneRespo"
                variant="p"
                sx={{
                  color: "#fff",
                  textAlign: "start",
                  fontSize: 18,
                  fontWeight: 300,
                  display: "block",
                }}
              >
                {t("Give")}{" "}
              </Typography>
              <Box
                sx={{
                  display: { xs: "none", md: "block" }, // Hide on extra-small screens
                }}
                className="mainCustomCat"
              >
                <Grid container>
                  <Grid
                    md={5}
                    lg={6}
                    xl={6}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <SearchIcon sx={{ color: "#000", fontSize: 36 }} />{" "}
                    {/* Render the SearchIcon */}
                    <input
                      type="text"
                      className="catTextBox"
                      placeholder={t("WhatLook")}
                      onChange={(e) => setQuery(e.target.value)}
                    />
                  </Grid>
                  <Grid
                    md={4}
                    lg={4}
                    xl={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <Button
                        id="demo-customized-button"
                        aria-controls={
                          open ? "demo-customized-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        disableElevation
                        onClick={handleClick}
                        sx={{
                          textTransform: "capitalize",
                          color: "#222222",
                          fontSize: 16,
                          marginLeft: 0,
                        }}
                        endIcon={<KeyboardArrowDownIcon />}
                      >
                        {selectedCategory}
                      </Button>
                      <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => handleClose()}
                        disableScrollLock
                      >
                        {category?.map((data) => {
                          return (
                            <MenuItem
                              onClick={() => handleClose(data)}
                              disableRipple
                            >
                              {t(data?.name)}
                            </MenuItem>
                          );
                        })}
                      </StyledMenu>
                    </div>
                  </Grid>
                  <Grid
                    md={3}
                    lg={2}
                    xl={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button className="SearchBtnCat" onClick={handleSearch}>
                      {t("SearchText")}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ pb: 10 }}>
          <Grid container spacing={3} className="filterSec">
            {" "}
            {/* Add spacing prop here */}
            <Grid item md={4} lg={4} xl={4}>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  border: 1,
                  p: 3,
                  py: 2,
                  borderRadius: 10,
                  borderColor: "#e3e3e3",
                }}
              >
                <Box sx={{ py: 2 }}>
                  <Typography
                    variant="h6"
                    className="langText"
                    sx={{ fontWeight: 500, color: "#000" }}
                  >
                    Filter
                  </Typography>
                </Box>
                <Box>
                  <div>
                    <StyledAccordion
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography
                          variant="p"
                          sx={{ fontWeight: 500, color: "#000" }}
                        >
                          Project Type
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="rate-type"
                            name="rate-type"
                            value={rateType}
                            onChange={handleRateTypeChange}
                          >
                            <FormControlLabel
                              value="hourly"
                              control={
                                <Radio sx={{ color: "#0B3013!important" }} />
                              }
                              label="Hourly Rate"
                            />
                            <FormControlLabel
                              value="fixed"
                              control={
                                <Radio sx={{ color: "#0B3013!important" }} />
                              }
                              label="Fixed Rate"
                            />
                          </RadioGroup>
                        </FormControl>
                      </AccordionDetails>
                    </StyledAccordion>

                    <StyledAccordion
                      expanded={expanded === "panel2"}
                      onChange={handleChange("panel2")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography variant="p" sx={{ color: "#000" }}>
                          Budget
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse malesuada lacus ex, sit amet blandit
                          leo lobortis eget.
                        </Typography> */}
                        <Grid item xs={12} md={6}>
                          <Box>
                            <FormLabel
                              sx={{
                                display: "block",
                                textAlign: "start",
                                py: 1.4,
                                fontSize: 14,
                              }}
                            >
                              Budget
                            </FormLabel>
                            <Dropdown
                              value={formData.budget}
                              onChange={handleBudget}
                              options={budgetValues?.map((value) => ({
                                value,
                                label: `${value}`,
                              }))}
                              label="Price"
                            />
                          </Box>
                        </Grid>
                      </AccordionDetails>
                    </StyledAccordion>
                    <StyledAccordion
                      expanded={expanded === "panel3"}
                      onChange={handleChange("panel3")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                      >
                        <Typography variant="p" sx={{ color: "#000" }}>
                          Location
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse malesuada lacus ex, sit amet blandit
                          leo lobortis eget.
                        </Typography> */}
                        <Grid item xs={12} md={6}>
                          <Box>
                            <FormLabel
                              sx={{
                                display: "block",
                                textAlign: "start",
                                py: 1.4,
                                fontSize: 14,
                              }}
                            >
                              Location
                            </FormLabel>
                            <Dropdown
                              value={formData.location}
                              onChange={handleLocation}
                              options={countries}
                              label="Location"
                            />
                          </Box>
                        </Grid>
                      </AccordionDetails>
                    </StyledAccordion>

                    <Box sx={{ my: 3 }}>
                      <Button
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "auto",
                          textTransform: "capitalize",
                          bgcolor: "#0a3114",
                          fontSize: 16,
                          color: "#fff",
                          borderRadius: 10,
                          px: 5,
                          py: 1,
                          "&:hover": {
                            bgcolor: "#0a3114",
                          },
                        }}
                        endIcon={<ArrowOutwardIcon color="#fff" />}
                      >
                        Clear
                      </Button>{" "}
                    </Box>
                  </div>
                </Box>
              </Box>
            </Grid>
            <Grid item md={8} lg={8} xl={8} xs={12}>
              <Box>
                <Box>
                  <Box
                    sx={{
                      backgroundColor: "#fff",
                      py: 3,
                      borderRadius: 10,
                      border: 1,
                      borderColor: "#e3e3e3",
                    }}
                  >
                    <Box
                      className="filterTop"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        // borderBottom: 1,
                        // borderColor: "#dadbda",
                        p: 2.5,
                        pt: 0,
                        pb: 0,
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h6"
                          className="langText  "
                          sx={{ fontWeight: 500, color: "#000" }}
                        >
                          {t("Top")}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          className="langText "
                          variant="p"
                          sx={{
                            color: "#000",
                            fontSize: 17,
                            fontWeight: 500,
                            display: "block",
                            px: 2,
                          }}
                        >
                          {/* Sort by */}
                        </Typography>
                        {/* <div>
                          <Button
                            className="filterDrop"
                            sx={{
                              backgroundColor: "#F7F7F7!important",
                              color: "#000",
                              fontSize: 17,
                              textTransform: "capitalize",
                              borderRadius: 12,
                            }}
                            id="demo-customized-button1"
                            aria-controls={
                              open ? "demo-customized-menu1" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDownIcon />}
                          >
                            Latest
                          </Button>
                          <StyledMenu
                            id="demo-customized-menu1"
                            MenuListProps={{
                              "aria-labelledby": "demo-customized-button1",
                            }}
                            anchorEl={anchorEl1}
                            open={open1}
                            onClose={handleClose}
                          >
                            <MenuItem onClick={handleClose} disableRipple>
                              Default
                            </MenuItem>
                            <MenuItem onClick={handleClose} disableRipple>
                              Duplicate
                            </MenuItem>
                          </StyledMenu>
                        </div> */}
                      </Box>
                    </Box>

                    {/* <Box
                      className="flexColumnRespo"
                      sx={{
                        borderTop: 1,
                        borderColor: "#dadbda",
                        p: 2.5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <Box sx={{ display: "flex" }}>
                          <img
                            src={AvatarJob}
                            className="responsive-image avatarImg"
                            alt="dfh"
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            px: 2,
                          }}
                        >
                          <Box sx={{ display: "flex" }}>
                            <Typography
                              variant="h5"
                              className="userNameText"
                              sx={{ color: "#000", fontWeight: 500 }}
                            >
                              John Doe
                            </Typography>
                            <Typography
                              variant="h5"
                              className="userNameText"
                              sx={{ color: "#222", fontWeight: 400, px: 2 }}
                            >
                              @johndoe
                            </Typography>
                          </Box>
                          <Box
                            className="flexWrapResp"
                            sx={{
                              display: "flex",
                              py: 1,
                              alignItems: "center",
                            }}
                          >
                            <Rating
                              name="hover-feedback"
                              value={value}
                              precision={0.5}
                              getLabelText={getLabelText}
                              onChange={(event, newValue) => {
                                setValue(newValue);
                              }}
                              onChangeActive={(event, newHover) => {
                                setHover(newHover);
                              }}
                              emptyIcon={
                                <StarIcon
                                  style={{ opacity: 0.55 }}
                                  fontSize="inherit"
                                />
                              }
                            />
                            {value !== null && (
                              <Box sx={{ mx: 2 }}>
                                {labels[hover !== -1 ? hover : value]}
                              </Box>
                            )}

                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <img src={chatIco} alt="chat" />
                              <Typography
                                variant="p"
                                sx={{ color: "#000", px: 1 }}
                              >
                                200
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                py: 1,
                                alignItems: "center",
                              }}
                            >
                              <img src={dollarIco} alt="chat" />
                              <Typography
                                variant="p"
                                sx={{ color: "#000", px: 1 }}
                              >
                                8.7
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                py: 1,
                                alignItems: "center",
                              }}
                            >
                              <img src={indiaIco} alt="chat" />
                              <Typography
                                variant="p"
                                sx={{ color: "#000", px: 1 }}
                              >
                                India
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="" sx={{ display: "flex", py: 0.5 }}>
                            <Typography
                              variant="h6"
                              className="langText skillText"
                              sx={{
                                color: "#222",
                                fontSize: 18,
                                borderRight: 1,
                                fontWeight: 500,
                                px: 2,
                                pl: 0,
                              }}
                            >
                              UI/UX Designer
                            </Typography>
                            <Typography
                              variant="h6"
                              className="langText skillText"
                              sx={{
                                color: "#222",
                                fontSize: 18,
                                borderRight: 1,
                                fontWeight: 500,
                                px: 2,
                              }}
                            >
                              Illustrations Designer
                            </Typography>
                            <Typography
                              variant="h6"
                              className="langText skillText"
                              sx={{
                                color: "#222",
                                fontSize: 18,
                                fontWeight: 500,
                                px: 2,
                              }}
                            >
                              Figma Expert
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className="flexDirectionRowResp catBtnTop"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="h5"
                          className="userNameText "
                          sx={{ color: "#000", px: 2, fontWeight: 500 }}
                        >
                          $150
                        </Typography>
                        <Typography
                          variant="p"
                          sx={{ color: "#000", px: 2, fontWeight: 500 }}
                        >
                          Per Hour
                        </Typography>
                        <Box sx={{ pt: 2 }}>
                          {isFavorite ? (
                            <FavoriteIcon
                              sx={{ color: "red", cursor: "pointer" }}
                              onClick={handleFavoriteClick}
                            />
                          ) : (
                            <FavoriteBorderIcon
                              sx={{ color: "#000", cursor: "pointer" }}
                              onClick={handleFavoriteClick}
                            />
                          )}
                        </Box>
                      </Box>
                    </Box>

                    <Grid container spacing={2} className="flexColumnRespo">
                      <Grid item xs={12} xl={8} lg={8} md={8}>
                        <Box
                          sx={{
                            p: 2.5,
                            pt: 0,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            variant="p"
                            sx={{
                              fontWeight: 400,
                              color: "#222",
                              fontSize: 16,
                            }}
                          >
                            I'm seeking an experienced and efficient app
                            developer to create an innovative social networking
                            application for iOS platform. Details about the
                            target audience are currently confidential. Since
                            the completion I'm seeking an experienced and
                            efficient app developer to create more....
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid
                        item
                        xs={4}
                        sx={{
                          display: "flex",
                          alignItems: "end",
                          justifyContent: "end",
                          ml: "auto",
                        }}
                      >
                        <Box sx={{ p: 2.5, pt: 0 }}>
                          <Button
                            component={Link} // Use Link component
                            to="/reviewratings" // Specify the target path
                            sx={{
                              display: "flex",

                              textTransform: "capitalize",
                              bgcolor: "#0a3114",
                              fontSize: 16,
                              color: "#fff",
                              borderRadius: 10,
                              px: 5,
                              py: 1,
                              "&:hover": {
                                bgcolor: "#0a3114",
                              },
                            }}
                          >
                            Contact
                          </Button>
                        </Box>
                      </Grid>
                    </Grid> */}
                  </Box>
                </Box>

                {projectData?.length === 0 ? (
                  <Box sx={{ py: 2, display: "flex", justifyContent: "end" }}>
                    <NodataFound />
                  </Box>
                ) : (
                  projectData?.slice(0, 5)?.map((user, index) => (
                    <Box
                      sx={{
                        backgroundColor: "#fff",
                        my: 4,
                        py: 1,
                        borderRadius: 10,
                        border: 1,
                        borderColor: "#e3e3e3",
                        cursor: "pointer",
                      }}
                      onClick={() => navigateToPraposeEmployer(user)}
                    >
                      <Box
                        className="flexColumnRespo"
                        sx={{
                          p: 2.5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Box sx={{ display: "flex" }}>
                            <img
                              src={AvatarJob}
                              className="responsive-image avatarImg"
                              alt="img"
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              px: 2,
                            }}
                          >
                            <Box sx={{ display: "flex" }}>
                              <Typography
                                variant="h5"
                                className="userNameText"
                                sx={{ color: "#000", fontWeight: 500 }}
                              >
                                {user?.firstName}
                              </Typography>
                              <Typography
                                variant="h5"
                                className="userNameText"
                                sx={{ color: "#222", fontWeight: 400, px: 2 }}
                              >
                                {user?.email}
                              </Typography>
                            </Box>
                            <Box
                              className="flexWrapResp"
                              sx={{
                                display: "flex",
                                py: 1,
                                alignItems: "center",
                              }}
                            >
                              <Rating
                                name="hover-feedback"
                                value={value}
                                precision={0.5}
                                getLabelText={getLabelText}
                                onChange={(event, newValue) => {
                                  setValue(newValue);
                                }}
                                onChangeActive={(event, newHover) => {
                                  setHover(newHover);
                                }}
                                emptyIcon={
                                  <StarIcon
                                    style={{ opacity: 0.55 }}
                                    fontSize="inherit"
                                  />
                                }
                              />
                              {value !== null && (
                                <Box sx={{ mx: 2 }}>
                                  {labels[hover !== -1 ? hover : value]}
                                </Box>
                              )}

                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <img src={chatIco} alt="chat" />
                                <Typography
                                  variant="p"
                                  sx={{ color: "#000", px: 1 }}
                                >
                                  200
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  py: 1,
                                  alignItems: "center",
                                }}
                              >
                                <img src={dollarIco} alt="chat" />
                                <Typography
                                  variant="p"
                                  sx={{ color: "#000", px: 1 }}
                                >
                                  8.7
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  py: 1,
                                  alignItems: "center",
                                }}
                              >
                                {/* <img src={indiaIco} alt="chat" /> */}
                                <Typography
                                  variant="p"
                                  sx={{ color: "#000", px: 1 }}
                                >
                                  {user.location}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              className=""
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                py: 0.5,
                              }}
                            >
                              {user?.skillsRequired.map((skill, index) => (
                                <Typography
                                  variant="h6"
                                  className="langText skillText"
                                  sx={{
                                    color: "#222",
                                    fontSize: 18,
                                    borderRight: 1,
                                    fontWeight: 500,
                                    px: 2,
                                    my: 1,
                                    pl: 0,
                                    mr: 2,
                                  }}
                                >
                                  {skill?.name}
                                </Typography>
                              ))}
                              {/* <Typography
                                variant="h6"
                                className="langText skillText"
                                sx={{
                                  color: "#222",
                                  fontSize: 18,
                                  borderRight: 1,
                                  fontWeight: 500,
                                  px: 2,
                                }}
                              >
                                Illustrations Designer
                              </Typography>
                              <Typography
                                variant="h6"
                                className="langText skillText"
                                sx={{
                                  color: "#222",
                                  fontSize: 18,
                                  fontWeight: 500,
                                  px: 2,
                                }}
                              >
                                Figma Expert
                              </Typography> */}
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          className="flexDirectionRowResp catBtnTop"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="h5"
                            className="userNameText "
                            sx={{ color: "#000", px: 2, fontWeight: 500 }}
                          >
                            $150
                          </Typography>
                          <Typography
                            variant="p"
                            sx={{ color: "#000", px: 2, fontWeight: 500 }}
                          >
                            {user?.rateType}
                          </Typography>
                          <Box sx={{ pt: 2 }}>
                            {isFavorite ? (
                              <FavoriteIcon
                                sx={{ color: "red", cursor: "pointer" }}
                                onClick={handleFavoriteClick}
                              />
                            ) : (
                              <FavoriteBorderIcon
                                sx={{ color: "#000", cursor: "pointer" }}
                                onClick={handleFavoriteClick}
                              />
                            )}
                          </Box>
                        </Box>
                      </Box>

                      <Grid container spacing={2} className="flexColumnRespo">
                        <Grid item xs={12} xl={8} lg={8} md={8}>
                          <Box
                            sx={{
                              p: 2.5,
                              pt: 0,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              variant="p"
                              sx={{
                                fontWeight: 400,
                                color: "#222",
                                fontSize: 16,
                              }}
                            >
                              {user?.description}
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid
                          item
                          xs={4}
                          sx={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            ml: "auto",
                          }}
                        >
                          <Box sx={{ p: 2.5, pt: 0 }}>
                            <Button
                              component={Link} // Use Link component
                              to="/reviewratings" // Specify the target path
                              sx={{
                                display: "flex",

                                textTransform: "capitalize",
                                bgcolor: "#0a3114",
                                fontSize: 16,
                                color: "#fff",
                                borderRadius: 10,
                                px: 5,
                                py: 1,
                                "&:hover": {
                                  bgcolor: "#0a3114",
                                },
                              }}
                            >
                              {t("Contact2")}
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ))
                )}
              </Box>

              <Box sx={{ py: 2, display: "flex", justifyContent: "center" }}>
                {projectData?.length > 0 ? (
                  <Typography onClick={() => handleOpenLoginModal()}>
                    Load More
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
              {/* <Box sx={{ py: 2, display: "flex", justifyContent: "center" }}>
                {
                  projectData?.length > 0 ?
                    <CustomPagination count={totalPages} page={currentPage} onChange={handlePageChange} />
                    :
                    ""
                }
              </Box> */}
            </Grid>
          </Grid>
        </Box>
      </Container>
      {/* <Signin
        open={loginModalOpen}
        handleClose={handleCloseLoginModal}
        toggleSignUpModal={toggleSignUpModal}
      /> */}
    </section>
  );
}
