import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { createContext, useContext, useEffect, useState } from "react";
import { db } from "../Auth/Firebase/firebase";
import { GetUserDetails, getUserDetailsFromAPI } from "../Helpers/Utility";

// Create a context
const BrowserJobContext = createContext();

// Create a custom hook to access the context
export const useBrowserJob = () => {
  return useContext(BrowserJobContext);
};

// Create a provider component
export const BrowserJobProvider = ({ children }) => {
  // Define your state and functions here
  const [category, setCategory] = useState([]);
  const [projectData, setProjectData] = useState();
  const [currentUserDetail, setCurrentUserDetail] = useState({});

  const [rooms, setRooms] = useState([]);
  const [isNew, setIsNew] = useState(-1);

  const getUser = async () => {
    if (GetUserDetails()?.token) {
      const userResponse = await getUserDetailsFromAPI();
      if (userResponse.statusCode == 200) {
        setCurrentUserDetail(userResponse.data);
      }
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (currentUserDetail?.firebaseUID) {
      const roomRef = collection(db, "rooms");
      const roomQuery = query(
        roomRef,
        // orderBy("updatedAt", "desc"),
        where("userIds", "array-contains", currentUserDetail?.firebaseUID)
      );
      const unsubscribe = onSnapshot(roomQuery, (response) => {
        setIsNew((dt) => dt + 1);
        setRooms(response.docs);
      });

      return () => unsubscribe();
    }
  }, [currentUserDetail]);

  return (
    <BrowserJobContext.Provider
      value={{
        category,
        setCategory,
        projectData,
        setProjectData,
        rooms,
        isNew,
        setIsNew,
      }}
    >
      {children}
    </BrowserJobContext.Provider>
  );
};
