import { Box, Container, Typography } from "@mui/material";
import "./PricingFees.css";
import { useTranslation } from "react-i18next";

export default function PricingFees() {
  const { t } = useTranslation();
  return (
    <section className="mainSection">
      <Container maxWidth="xl" sx={{ px: { md: 8, xl: 4, xxl: 4 }, py: 3 }}>
        <Box className="terms-main" sx={{ py: 5 }}>
          <h4 className="terms-heading">{t("pricingPlans")}</h4>
        </Box>
        <div
          className="terms-content"
          style={{
            backgroundColor: "#fff",
            borderRadius: "20px",
          }}
        >
          <div style={{ marginBottom: "20px" }}>
            <div style={{ padding: "5px 0 5px 0" }}>
              <h2
                style={{
                  paddingBottom: "15px",
                  borderBottom: "1px solid #dbdbdb",
                  marginBottom: "20px",
                }}
              >
                {t("pricingFees")}
              </h2>
              <p style={{ marginBottom: "20px" }}>{t("pricingFees1")}</p>
              <ul>
                <li style={{ marginBottom: "5px" }}>
                  <b>{t("pricingFees2")}</b>: {t("pricingFees3")}
                  <ul style={{ marginTop: "10px" }}>
                    <li style={{ marginBottom: "5px" }}>
                      <b>{t("pricingFees4")}</b>: {t("pricingFees5")}
                    </li>
                    <li style={{ marginBottom: "5px" }}>
                      <b>{t("pricingFees4")}</b>: {t("pricingFees6")}
                    </li>
                  </ul>
                </li>
                <li style={{ marginTop: "10px" }}>
                  <b>{t("pricingFees7")}</b>: {t("pricingFees8")}
                  <ul style={{ marginTop: "10px" }}>
                    <li style={{ marginBottom: "5px" }}>
                      <b>{t("pricingFees4")}</b>: {t("pricingFees9")}
                    </li>
                  </ul>
                </li>
              </ul>
              <p style={{ marginBottom: "20px" }}>{t("pricingFees10")}</p>

              <h2
                style={{
                  padding: "15px 0",
                  borderBottom: "1px solid #dbdbdb",
                  marginBottom: "20px",
                }}
              >
                {t("pricingFees11")}
              </h2>
              <p style={{ marginBottom: "20px" }}>{t("pricingFees12")}</p>
              <ul>
                <li style={{ marginBottom: "5px" }}>
                  <b>{t("pricingFees2")}</b>: {t("pricingFees14")}
                  <ul style={{ marginTop: "10px" }}>
                    <li style={{ marginBottom: "5px" }}>
                      <b>{t("pricingFees4")}</b>: {t("pricingFees15")}
                    </li>
                    <li style={{ marginBottom: "5px" }}>
                      <b>{t("pricingFees4")}</b>: {t("pricingFees16")}
                    </li>
                  </ul>
                </li>
                <li style={{ marginTop: "10px" }}>
                  <b>{t("pricingFees7")}</b>: {t("pricingFees17")}
                  <ul style={{ marginTop: "10px" }}>
                    <li style={{ marginBottom: "5px" }}>
                      <b>{t("pricingFees4")}</b>: {t("pricingFees18")}
                    </li>
                  </ul>
                </li>
              </ul>
              <p style={{ marginBottom: "20px" }}>{t("pricingFees19")}</p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
