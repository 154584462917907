import React from "react";
import { Container, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <section className="mainSection">
      <Container maxWidth="xl" sx={{ px: { md: 8, xl: 4, xxl: 4 }, py: 3 }}>
        <Box sx={{ py: 2 }}>
          <Box className="terms-main" sx={{ py: 2 }}>
            <h4 className="terms-heading">{t("privacyPolicy")}</h4>
          </Box>
        </Box>
        <Box
          className="terms-content"
          style={{
            backgroundColor: "#fff",
            borderRadius: "20px",
          }}
        >
          <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy1")}</p>
          <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy2")}</p>
          <Box sx={{ py: 2 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy3")}</h2>
            <h3 style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy4")}</h3>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy5")}</p>
            <h3 style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy6")}</h3>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy7")}</p>
            <ul style={{ marginBottom: "0.5rem" }}>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy8")}</strong> {t("privacyPolicy9")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy10")}</strong> {t("privacyPolicy11")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy12")}</strong> {t("privacyPolicy13")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy14")}</strong> {t("privacyPolicy15")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy16")}</strong> {t("privacyPolicy17")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy18")}</strong> {t("privacyPolicy19")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy20")}</strong> {t("privacyPolicy21")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy22")}</strong> {t("privacyPolicy23")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy24")}</strong> {t("privacyPolicy25")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy26")}</strong> {t("privacyPolicy27")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy28")}</strong> {t("privacyPolicy29")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy30")}</strong> {t("privacyPolicy31")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy32")}</strong> {t("privacyPolicy33")}
                https://www.WorkWaveHub.com/.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy34")}</strong> {t("privacyPolicy35")}
              </li>
            </ul>
          </Box>

          <Box sx={{ py: 2 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy36")}</h2>
            <h3 style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy37")}</h3>
            <h4 style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy38")}</h4>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy39")}</p>
            <ul style={{ marginBottom: "0.5rem" }}>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy40")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy41")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy42")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy43")}</li>
              {/* <li style={{ marginBottom: "2rem" }}>{t("privacyPolicy30")}</li> */}
            </ul>
            {/* <h3 style={{ marginBottom: "0.5rem" }}>
              Use of Your Personal Data
            </h3>
            <p style={{ marginBottom: "0.5rem" }}>
              The Company may use Personal Data for the following purposes:
            </p>
            <ul style={{ marginBottom: "0.5rem" }}>
              <li style={{ marginBottom: "1rem" }}>
                <strong>To provide and maintain our Service</strong> , including
                to monitor the usage of our Service.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>To manage Your Account:</strong> to manage Your
                registration as a user of the Service. The Personal Data You
                provide can give You access to different functionalities of the
                Service that are available to You as a registered user.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>For the performance of a contract:</strong> the
                development, compliance and undertaking of the purchase contract
                for the products, items or services You have purchased or of any
                other contract with Us through the Service.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>To contact You:</strong> To contact You by email,
                telephone calls, SMS, or other equivalent forms of electronic
                communication, such as a mobile application's push notifications
                regarding updates or informative communications related to the
                functionalities, products or contracted services, including the
                security updates, when necessary or reasonable for their
                implementation.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>To provide You</strong> with news, special offers and
                general information about other goods, services and events which
                we offer that are similar to those that you have already
                purchased or enquired about unless You have opted not to receive
                such information.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>To manage Your requests:</strong> To attend and manage
                Your requests to Us.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>For business transfers: </strong> We may use Your
                information to evaluate or conduct a merger, divestiture,
                restructuring, reorganization, dissolution, or other sale or
                transfer of some or all of Our assets, whether as a going
                concern or as part of bankruptcy, liquidation, or similar
                proceeding, in which Personal Data held by Us about our Service
                users is among the assets transferred.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>For other purposes:</strong> We may use Your information
                for other purposes, such as data analysis, identifying usage
                trends, determining the effectiveness of our promotional
                campaigns and to evaluate and improve our Service, products,
                services, marketing and your experience.
              </li>
            </ul>
            <p style={{ marginBottom: "0.5rem" }}>
              We may share Your personal information in the following
              situations:
            </p>
            <ul style={{ marginBottom: "0.5rem" }}>
              <li style={{ marginBottom: "1rem" }}>
                <strong>With Service Providers: </strong> We may share Your
                personal information with Service Providers to monitor and
                analyze the use of our Service, to contact You.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>For business transfers: </strong> We may share or
                transfer Your personal information in connection with, or during
                negotiations of, any merger, sale of Company assets, financing,
                or acquisition of all or a portion of Our business to another
                company.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>For the performance of a contract:</strong> the
                development, compliance and undertaking of the purchase contract
                for the products, items or services You have purchased or of any
                other contract with Us through the Service.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>With Affiliates: </strong> We may share Your
                information with Our affiliates, in which case we will require
                those affiliates to honor this Privacy Policy. Affiliates
                include Our parent company and any other subsidiaries, joint
                venture partners or other companies that We control or that are
                under common control with Us.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>With business partners: </strong> We may share Your
                information with Our business partners to offer You certain
                products, services or promotions.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>To manage Your requests:</strong> To attend and manage
                Your requests to Us.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>With other users: </strong> when You share personal
                information or otherwise interact in the public areas with other
                users, such information may be viewed by all users and may be
                publicly distributed outside. If You interact with other users
                or register through a Third-Party Social Media Service, Your
                contacts on the Third-Party Social Media Service may see Your
                name, profile, pictures and description of Your activity.
                Similarly, other users will be able to view descriptions of Your
                activity, communicate with You and view Your profile.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>With Your consent</strong> : We may disclose Your
                personal information for any other purpose with Your consent.
              </li>
            </ul> */}
            {/* <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy45")}</p>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy46")}</p>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy47")}</p>
            <h4 style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy48")}</h4>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy49")}</p>
            <ul style={{ marginBottom: "0.5rem" }}>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy50")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy51")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy52")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy53")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy54")}</li>
            </ul>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy55")}</p>
            <p style={{ marginBottom: "1rem" }}>{t("privacyPolicy56")}</p>
            <h4 style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy57")}</h4>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy58")}</p>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy59")}</p>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy60")}</p>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy61")}</p> */}
          </Box>
          {/* <Box sx={{ py: 2 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy62")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy63")}</p>
            <ul style={{ marginBottom: "0.5rem" }}>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy64")}</strong> {t("privacyPolicy65")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy66")}</strong> {t("privacyPolicy67")}
              </li>
            </ul>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy68")}</p>
            <h3 style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy69")}</h3>
            <ul style={{ marginBottom: "0.5rem" }}>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy70")}</strong> {t("privacyPolicy71")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy72")}</strong> {t("privacyPolicy73")}
              </li>
              <li style={{ marginBottom: "1rem" }}>
                <strong>{t("privacyPolicy74")}</strong> {t("privacyPolicy75")}
              </li>
            </ul>
            <p style={{ marginBottom: "1rem" }}>{t("privacyPolicy76")}</p>
          </Box> */}

          <Box sx={{ py: 2 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy77")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy78")}</p>
            <ul style={{ marginBottom: "0.5rem" }}>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy79")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy80")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy81")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy82")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy83")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy84")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy85")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy86")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy87")}</li>
            </ul>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy88")}</p>
            <ul style={{ marginBottom: "0.5rem" }}>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy89")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy90")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy91")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy92")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy93")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy94")}</li>
            </ul>
          </Box>

          <Box sx={{ py: 2 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy95")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy96")}</p>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy97")}</p>
          </Box>

          <Box sx={{ py: 2 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy98")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy99")}</p>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy100")}</p>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy101")}</p>
          </Box>

          <Box sx={{ py: 2 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy131")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy132")}</p>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy133")}</p>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy134")}</p>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy135")}</p>
          </Box>

          <Box sx={{ py: 2 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy102")}</h2>
            <h3 style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy103")}</h3>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy104")}</p>
            <h3 style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy105")}</h3>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy106")}</p>
            <h3 style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy107")}</h3>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy108")}</p>
            <ul style={{ marginBottom: "0.5rem" }}>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy109")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy110")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy111")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy112")}</li>
              <li style={{ marginBottom: "1rem" }}>{t("privacyPolicy113")}</li>
            </ul>
          </Box>

          <Box sx={{ py: 2 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy114")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy115")}</p>
          </Box>

          <Box sx={{ py: 2 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy116")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy117")}</p>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy118")}</p>
          </Box>

          <Box sx={{ py: 2 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy119")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy120")}</p>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy121")}</p>
          </Box>

          <Box sx={{ py: 2 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy122")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy123")}</p>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy130")}</p>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy124")}</p>
          </Box>

          <Box sx={{ py: 2 }}>
            <h2 style={{ marginBottom: "1rem" }}>{t("privacyPolicy125")}</h2>
            <p style={{ marginBottom: "0.5rem" }}>{t("privacyPolicy126")}</p>
            <ul style={{ marginBottom: "0.5rem" }}>
              <li style={{ marginBottom: "1rem" }}>
                {t("privacyPolicy127")}{" "}
                <a
                  style={{ color: "black" }}
                  href="mailto:contact@workwavehub.com"
                >
                  contact@workwavehub.com
                </a>
              </li>
              <li style={{ marginBottom: "1rem" }}>
                {t("privacyPolicy128")}{" "}
                <a style={{ color: "black" }} href="/contact">
                  https://www.workwavehub.com/contact
                </a>
              </li>
              <li style={{ marginBottom: "1rem" }}>
                {t("privacyPolicy129")} 2365919207
              </li>
            </ul>
          </Box>
        </Box>
      </Container>
    </section>
  );
};

export default PrivacyPolicy;
