import React, { useEffect, useState } from "react";
import "./Services.css";
import BrowseJobsBanner from "../../Assets/Images/BrowseJobsBanner.png";
import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  Rating,
} from "@mui/material";

// import FavoriteIcon from '@mui/icons-material/Favorite';
import AvatarJob from "../../Assets/Images/BrowseJobsAvatar.png";
import chatIco from "../../Assets/Images/ChatIco.png";
import dollarIco from "../../Assets/Images/dollar-circle.png";
import indiaIco from "../../Assets/Images/indiaFlag.png";
// import EditIcon from "../../Assets/Images/EditIcon.png";
import pieChart from "../../Assets/Images/pie-chart.png";
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search"; // Import SearchIcon properly
import Menu from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import CustomPagination from "../../Components/CustomPagination/CustomPagination";
// import { Link } from "react-router-dom";
// import { toast } from 'react-toastify';
import { getAPI } from "../../Services/Api";
import { GetUserDetails } from "../../Helpers/Utility";
import { useNavigate } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import NodataFound from "../../Components/NodataFound/NodataFound";

const StyledAccordion = styled(Accordion)({
  boxShadow: "none", // Remove box shadow from accordion
});
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    backgroundColor: "#F7F7F7",
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
const labels = {
  0.5: "0.5",
  1: "1.0",
  1.5: "1.5",
  2: "2.0",
  2.5: "2.5",
  3: "3.0",
  3.5: "3.5",
  4: "4.0",
  4.5: "4.5",
  5: "5.0",
};
function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export default function Services() {
  const [expanded, setExpanded] = useState("panel1"); // Set the first panel to be expanded by default
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const [rateType, setRateType] = useState("hourly"); // Define rateType state
  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);
  const [allProjects, setAllProjects] = useState([]);
  // const [bookmarkedProjects, setBookmarkedProjects] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getAllProjects();
  }, []);

  const getAllProjects = async () => {
    try {
      const token = GetUserDetails().token;
      const response = await getAPI("user/EmployeeList", token);
      if (response.statusCode === 200) {
        setAllProjects(response.data);
      }
    } catch (error) {
      // toast.error(error.message)
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  const handleRateTypeChange = (event) => {
    setRateType(event.target.value);
  };
  // const handleBookmarkClick = async (project, index) => {
  //   console.log("index======", project, index)
  //   const updatedBookmarks = [...bookmarkedProjects];
  //   updatedBookmarks[index] = !updatedBookmarks[index];
  //   setBookmarkedProjects(updatedBookmarks);

  //   try {
  //     const token = GetUserDetails().token
  //     const dataToSend = { projectId: project.id }
  //     const response = await postAPI("project/bookmark", dataToSend, token)
  //     if (response.statusCode === 200) {
  //       toast.success(response.message)
  //     }
  //   } catch (error) {
  //     toast.error(error.message)
  //   }
  // };

  const sendProjectDetails = (project) => {
    navigate("/projectdetails", {
      state: {
        project,
      },
    });
  };

  return (
    <section className="BrowseJobsEmployee">
      <Container maxWidth="xl">
        <Box className="padBotZero catBtnTop padTopZero" sx={{ py: 10 }}>
          <Box sx={{ position: "relative" }}>
            <img
              src={BrowseJobsBanner}
              className="bannerImageSize"
              alt="BrowseJobsBanner"
            />
            <Box
              className="DesignAbs"
              sx={{
                position: "absolute",
                top: "55%",
                width: "100%",
                maxWidth: "50%",
                left: 0,
                transform: "translateY(-50%)",
                textAlign: "right",
                color: "white",
                paddingLeft: 6, // Add padding to separate text from the image
              }}
            >
              <Typography
                sx={{
                  textAlign: "start",
                  py: 2,
                  typography: { xl: "h3", lg: "h3", md: "h3", xs: "h8" },
                }}
                className="designBigHead"
              >
                Design & Creative
              </Typography>
              <Typography
                className="dNoneRespo"
                variant="p"
                sx={{
                  color: "#fff",
                  textAlign: "start",
                  fontSize: 18,
                  fontWeight: 300,
                  display: "block",
                }}
              >
                Give your visitor a smooth online experience with a solid UX
                design{" "}
              </Typography>
              <Box
                sx={{
                  display: { xs: "none", md: "block" }, // Hide on extra-small screens
                }}
                className="mainCustomCat"
              >
                <Grid container>
                  <Grid
                    md={5}
                    lg={6}
                    xl={6}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <SearchIcon sx={{ color: "#000", fontSize: 36 }} />{" "}
                    {/* Render the SearchIcon */}
                    <input
                      type="text"
                      className="catTextBox"
                      placeholder="What are you looking for?"
                    />
                  </Grid>
                  <Grid
                    md={4}
                    lg={4}
                    xl={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <Button
                        id="demo-customized-button"
                        aria-controls={
                          open ? "demo-customized-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        disableElevation
                        onClick={handleClick}
                        sx={{
                          textTransform: "capitalize",
                          color: "#222222",
                          fontSize: 16,
                          marginLeft: 0,
                        }}
                        endIcon={<KeyboardArrowDownIcon />}
                      >
                        Choose Category
                      </Button>
                      <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        disableScrollLock // Add disableScrollLock prop here
                      >
                        <MenuItem onClick={handleClose} disableRipple>
                          IT & Software
                        </MenuItem>
                        <MenuItem onClick={handleClose} disableRipple>
                          Medical
                        </MenuItem>
                        <MenuItem onClick={handleClose} disableRipple>
                          Architecture
                        </MenuItem>
                      </StyledMenu>
                    </div>
                  </Grid>
                  <Grid
                    md={3}
                    lg={2}
                    xl={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button className="SearchBtnCat">Search</Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ pb: 10 }}>
          <Grid container spacing={3} className="filterSec">
            {" "}
            {/* Add spacing prop here */}
            <Grid item md={8} lg={4} xl={4}>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  border: 1,
                  p: 3,
                  py: 2,
                  borderRadius: 10,
                  borderColor: "#e3e3e3",
                }}
              >
                <Box sx={{ py: 2 }}>
                  <Typography
                    variant="h6"
                    className="langText"
                    sx={{ fontWeight: 500, color: "#000" }}
                  >
                    Filter
                  </Typography>
                </Box>
                <Box>
                  <div>
                    <StyledAccordion
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography
                          variant="p"
                          sx={{ fontWeight: 500, color: "#000" }}
                        >
                          Project Type
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="rate-type"
                            name="rate-type"
                            value={rateType}
                            onChange={handleRateTypeChange}
                          >
                            <FormControlLabel
                              value="hourly"
                              control={
                                <Radio sx={{ color: "#0B3013!important" }} />
                              }
                              label="Hourly Rate"
                            />
                            <FormControlLabel
                              value="fixed"
                              control={
                                <Radio sx={{ color: "#0B3013!important" }} />
                              }
                              label="Fixed Rate"
                            />
                          </RadioGroup>
                        </FormControl>
                      </AccordionDetails>
                    </StyledAccordion>

                    <StyledAccordion
                      expanded={expanded === "panel2"}
                      onChange={handleChange("panel2")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography variant="p" sx={{ color: "#000" }}>
                          Budget
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse malesuada lacus ex, sit amet blandit
                          leo lobortis eget.
                        </Typography> */}
                      </AccordionDetails>
                    </StyledAccordion>
                    <StyledAccordion
                      expanded={expanded === "panel3"}
                      onChange={handleChange("panel3")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                      >
                        <Typography variant="p" sx={{ color: "#000" }}>
                          Location
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse malesuada lacus ex, sit amet blandit
                          leo lobortis eget.
                        </Typography> */}
                      </AccordionDetails>
                    </StyledAccordion>

                    <Box sx={{ my: 3 }}>
                      <Button
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "auto",
                          textTransform: "capitalize",
                          bgcolor: "#0a3114",
                          fontSize: 16,
                          color: "#fff",
                          borderRadius: 10,
                          px: 5,
                          py: 1,
                          "&:hover": {
                            bgcolor: "#0a3114",
                          },
                        }}
                        endIcon={<ArrowOutwardIcon color="#fff" />}
                      >
                        Clear
                      </Button>{" "}
                    </Box>
                  </div>
                </Box>
              </Box>
            </Grid>
            <Grid item md={8} lg={8} xl={8} xs={12}>
              <Box>
                <Box>
                  <Box
                    sx={{
                      backgroundColor: "#fff",
                      py: 3,
                      borderRadius: 10,
                      border: 1,
                      borderColor: "#e3e3e3",
                    }}
                  >
                    <Box
                      className="filterTop"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        borderBottom: 1,
                        borderColor: "#dadbda",
                        p: 2.5,
                        pt: 0,
                        pb: 2,
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h6"
                          className="langText  "
                          sx={{ fontWeight: 500, color: "#000" }}
                        >
                          Top Results
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          className="langText "
                          variant="p"
                          sx={{
                            color: "#000",
                            fontSize: 17,
                            fontWeight: 500,
                            display: "block",
                            px: 2,
                          }}
                        >
                          Sort by
                        </Typography>
                        <div>
                          <Button
                            sx={{
                              backgroundColor: "#F7F7F7!important",
                              color: "#000",
                              fontSize: 17,
                              textTransform: "capitalize",
                              borderRadius: 12,
                            }}
                            id="demo-customized-button1"
                            aria-controls={
                              open ? "demo-customized-menu1" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDownIcon />}
                          >
                            Latest
                          </Button>
                          <StyledMenu
                            id="demo-customized-menu1"
                            MenuListProps={{
                              "aria-labelledby": "demo-customized-button1",
                            }}
                            anchorEl={anchorEl1}
                            open={open1}
                            onClose={handleClose}
                          >
                            <MenuItem onClick={handleClose} disableRipple>
                              Default
                            </MenuItem>
                            <MenuItem onClick={handleClose} disableRipple>
                              Duplicate
                            </MenuItem>
                          </StyledMenu>
                        </div>
                      </Box>
                    </Box>

                    {
                      allProjects && allProjects.length > 0 ?
                        (
                          allProjects.map((project, index) => (
                            <Box
                              key={index}
                              sx={{
                                backgroundColor: "#fff",
                                borderRadius: 7.5,
                              }}
                            >
                              <Box onClick={() => sendProjectDetails(project)}>
                                <Box
                                  sx={{
                                    backgroundColor: "#fff",
                                    py: 1,
                                    borderBottom: 1,
                                    borderColor: "#e3e3e3",
                                  }}
                                >
                                  <Box
                                    className="flexColumnRespo"
                                    sx={{
                                      p: 2.5,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Box sx={{ display: "flex" }}>
                                      <Box sx={{ display: "flex" }}>
                                        <img
                                          src={AvatarJob}
                                          className="responsive-image avatarImg"
                                          alt="dfh"
                                        />
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          px: 2,
                                        }}
                                      >
                                        <Box
                                          className="flexWrapResp"
                                          sx={{ display: "flex" }}
                                        >
                                          <Typography
                                            className="userNameText"
                                            variant="h5"
                                            sx={{ color: "#000", fontWeight: 500 }}
                                          >
                                            {project.firstName}
                                          </Typography>
                                          <Typography
                                            variant="h5"
                                            className="userNameText respoEmail"
                                            sx={{
                                              color: "#222",
                                              fontWeight: 400,
                                              px: 2,
                                            }}
                                          >
                                            {project.email}
                                          </Typography>
                                        </Box>
                                        <Box
                                          className="flexWrapResp"
                                          sx={{
                                            display: "flex",
                                            py: 1,
                                            alignItems: "center",
                                          }}
                                        >
                                          <Rating
                                            name="hover-feedback"
                                            value={value}
                                            precision={0.5}
                                            getLabelText={getLabelText}
                                            onChange={(event, newValue) => {
                                              setValue(newValue);
                                            }}
                                            onChangeActive={(event, newHover) => {
                                              setHover(newHover);
                                            }}
                                            emptyIcon={
                                              <StarIcon
                                                style={{ opacity: 0.55 }}
                                                fontSize="inherit"
                                              />
                                            }
                                          />
                                          {value !== null && (
                                            <Box sx={{ mx: 2 }}>
                                              {labels[hover !== -1 ? hover : value]}
                                            </Box>
                                          )}

                                          <Box
                                            className="filterSec"
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <img src={chatIco} alt="chat" />
                                            <Typography
                                              variant="p"
                                              sx={{ color: "#000", px: 1 }}
                                            >
                                              200
                                            </Typography>
                                          </Box>
                                          <Box
                                            className="filterSec"
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <img src={dollarIco} alt="chat" />
                                            <Typography
                                              variant="p"
                                              sx={{ color: "#000", px: 1 }}
                                            >
                                              8.7
                                            </Typography>
                                          </Box>
                                          <Box
                                            className="filterSec"
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <img src={pieChart} alt="chat" />
                                            <Typography
                                              variant="p"
                                              sx={{ color: "#000", px: 1 }}
                                            >
                                              98%
                                            </Typography>
                                          </Box>
                                          <Box
                                            className="filterSec"
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <img src={indiaIco} alt="chat" />
                                            <Typography
                                              variant="p"
                                              sx={{ color: "#000", px: 1 }}
                                            >
                                              India
                                            </Typography>
                                          </Box>
                                        </Box>
                                        <Box sx={{ display: "flex",flexWrap:'wrap', py: 0.5 }}>
                                          {project.skills.map((skill, index) => (
                                            <Typography
                                              key={index}
                                              className="langText skillText"
                                              variant="h6"
                                              sx={{
                                                color: "#222",
                                                fontSize: 18,
                                                borderRight: 1,
                                                fontWeight: 500,
                                                px: 1,
                                                my:1
                                              }}
                                            >
                                              {skill?.name}
                                            </Typography>
                                          ))}
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box
                                      className="flexDirectionRowResp catBtnTop"
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        className="userNameText "
                                        variant="h5"
                                        sx={{ color: "#000", px: 2, fontWeight: 500 }}
                                      >
                                        $150
                                      </Typography>
                                      <Typography
                                        variant="p"
                                        sx={{ color: "#222", px: 2, fontWeight: 500 }}
                                      >
                                        In 7 Days
                                      </Typography>
                                    </Box>
                                  </Box>

                                  <Grid
                                    container
                                    spacing={2}
                                    className="flexColumnRespo"
                                  >
                                    {/* Left half */}
                                    <Grid item xs={12} xl={8} lg={8} md={8}>
                                      <Box
                                        sx={{
                                          p: 2.5,
                                          pt: 0,
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Typography
                                          variant="p"
                                          sx={{
                                            fontWeight: 400,
                                            color: "#222",
                                            fontSize: 16,
                                          }}
                                        >
                                          I'm seeking an experienced and efficient app
                                          developer to create an innovative social
                                          networking application for iOS platform.
                                          Details about the target audience are
                                          currently confidential. Since the completion
                                          I'm seeking an experienced and efficient app
                                          developer to create more....
                                        </Typography>
                                      </Box>
                                    </Grid>

                                    {/* Right half */}
                                    <Grid
                                      item
                                      xs={4}
                                      sx={{
                                        display: "flex",
                                        alignItems: "end",
                                        justifyContent: "end",
                                        ml: "auto",
                                      }}
                                    >
                                      <Box sx={{ display: "flex", p: 2.5, pt: 0 }}>
                                        <Button
                                          sx={{
                                            display: "flex",
                                            mx: 1,
                                            textTransform: "capitalize",
                                            border: 1,
                                            borderColor: "#0a3114",
                                            fontSize: 16,
                                            color: "#000",
                                            borderRadius: 10,

                                            px: 5,
                                            py: 1,
                                            "&:hover": {
                                              bgcolor: "#0a3114",
                                            },
                                          }}
                                        >
                                          Chat
                                        </Button>
                                        <Button
                                          sx={{
                                            display: "flex",
                                            fontFamily: "inherit",
                                            textTransform: "capitalize",
                                            bgcolor: "#0a3114",
                                            fontSize: 16,
                                            color: "#fff",
                                            borderRadius: 10,
                                            px: 5,
                                            py: 1,
                                            "&:hover": {
                                              bgcolor: "#fff",
                                            },
                                          }}
                                        >
                                          Award
                                        </Button>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Box>
                            </Box>
                          ))
                        )
                        :
                        (
                          <Box>
                            <NodataFound />
                          </Box>
                        )}

                  </Box>
                </Box>
              </Box>
              <Box sx={{ py: 2, display: "flex", justifyContent: "center" }}>
                <CustomPagination count={10} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
}
