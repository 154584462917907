import { Box, Container, Typography } from "@mui/material";
import "./Terms.css";
import { getAPI } from "../../Services/Api";
import { useEffect, useState } from "react";
import { LoaderHide, LoaderShow } from "../../Helpers/Utility";
import loader from "../../Assets/Images/loader.svg";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

export default function Terms() {
  const [terms, setTerms] = useState("");
  const { t, i18n } = useTranslation();
  useEffect(() => {
    getTC();
  }, [i18n, t]);

  const getTC = async () => {
    LoaderShow();
    try {
      const res = await getAPI(
        `termandcondition/terms-and-conditions?lang=${i18n.language}`,
        ""
      );
      const regex = /<p>(.*?)<\/p>/s;
      const match = regex.exec(res);

      if (match) {
        setTerms(
          match[1].trim().replace(/\n/g, "<br/>").replace(/[·]/g, "• ")
        ); // Capture group 1 (content) and trim
      }
    } catch (err) {
      console.log(err.message);
    }
    LoaderHide();
  };
  return (
    <section className="mainSection">
      <div
        id="hideloding"
        className="loding-display"
        style={{ display: "none" }}
      >
        <img src={loader} alt="loader-img" />
      </div>
      <Container maxWidth="xl" sx={{ px: { md: 8, xl: 4, xxl: 4 }, py: 3 }}>
        <Box className="terms-main" sx={{ py: 5 }}>
          <h4 className="terms-heading">{t("termsAndCondition")}</h4>
        </Box>
        <div
          className="terms-content"
          style={{
            backgroundColor: "#fff",
            borderRadius: "20px",
          }}
        >
          {parse(terms)}
        </div>
        {/* <div
          className="terms-content"
          style={{
            backgroundColor: "#fff",
            borderRadius: "20px",
          }}
        >
          <div style={{ marginBottom: "20px" }}>
            <h2
              style={{
                paddingBottom: "15px",
                borderBottom: "1px solid #dbdbdb",
              }}
            >
              Terms & Conditions
            </h2>
            <div style={{ padding: "15px 0 15px 0", marginTop: "10px" }}>
              <p style={{ marginBottom: "20px" }}>
                These Terms of Service (“Terms”) govern your use of and access
                to our website (“Website”), applications (“Apps”) and other
                services (collectively, the “Services”).
              </p>
              <p>
                The Services are provided by WorkWaveHub team (“we,” “us,”
                “our,” or “WorkWaveHub”). Please note that the Terms constitute
                a binding legal agreement between you ("User" or "You") and
                WorkWaveHub By visiting our websites, installing, and/or using
                the Services, you acknowledge that you have read the Terms,
                understand them, and agree to be bound by these Terms. If you do
                not agree with these Terms or any provisions hereof, please do
                not install the Apps and do not use our Services. If you are
                accepting these Terms on behalf of your employer or another
                entity, you represent and warrant that: (i) you have full legal
                authority to bind your employer or such entity to the Terms; and
                (ii) you agree and consent to the Terms on behalf of the party
                that you represent.
              </p>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <h2
              style={{
                paddingBottom: "15px",
              }}
            >
              1. General
            </h2>
            <div style={{ padding: "0 0 15px 0", marginTop: "10px" }}>
              <p style={{ marginBottom: "20px" }}>
                Our Services are providing a job platform where registered
                individual users can get opportunities to work and offering a
                platform for business houses and agencies where they can hire
                individuals, which enhances job opportunities.
              </p>
              <p>
                In order to use some of the Services, you will be asked to set
                up an account. You are solely responsible for all usage or
                activity on your account including, but not limited to, use of
                the account by any person who uses your account, with or without
                authorization, or who has access to any computer or mobile
                device on which your account resides or is accessible.
              </p>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <h2>Your Responsibilities</h2>
            <div style={{ padding: "0 0 15px 0", marginTop: "10px" }}>
              <ul style={{ marginBottom: "20px" }}>
                <li>
                  Creating an Account: You are responsible for providing
                  accurate and complete information when creating your profile.
                </li>
                <li>
                  Content: You are responsible for all content you post on
                  WorkWaveHub, including job postings, profiles, and messages.
                  This content must be truthful, legal, and not violate anyone's
                  rights.
                </li>
                <li>
                  Communication: Communicate professionally and respectfully
                  with all users on the platform.
                </li>
                <li>
                  Following the Law: Comply with all applicable Iranian laws and
                  regulations when using WorkWaveHub.
                </li>
              </ul>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <h2> Business Responsibilities</h2>
            <div style={{ padding: "0 0 15px 0", marginTop: "10px" }}>
              <ul style={{ marginBottom: "20px" }}>
                <li>
                  Job Postings: Job postings must be accurate, clear, and comply
                  with Iranian labor laws.
                </li>
                <li>
                  Payment: Businesses are responsible for timely payment to
                  freelancers as agreed upon in contracts.
                </li>
                <li>
                  Communication: Businesses should communicate clearly and
                  professionally with all applicants and freelancers.
                </li>
              </ul>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <h2> Prohibited Activities</h2>
            <div style={{ padding: "0 0 15px 0", marginTop: "10px" }}>
              <ul style={{ marginBottom: "20px" }}>
                <li>Creating multiple accounts.</li>
                <li>Posting misleading or false information.</li>
                <li>Discriminatory or offensive content.</li>
                <li>Spam or unsolicited messages.</li>
                <li>Sharing personal information of others.</li>
                <li>Using WorkWaveHub for illegal activities.</li>
              </ul>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <h2>Fees and Payment</h2>
            <div style={{ padding: "15px 0 15px 0", marginTop: "10px" }}>
              <h3>a. Job Seekers:</h3>
              <ul style={{ marginBottom: "20px" }}>
                <li>
                  Currently, WorkWaveHub is free for Job Seekers to use the
                  platform to search for and apply to jobs.
                </li>
              </ul>
              <h3>b. Employers:</h3>
              <ul style={{ marginBottom: "20px" }}>
                <li>
                  WorkWaveHub may offer various paid plans for Employers with
                  different features and benefits. The specific fees and terms
                  associated with each plan will be available on the Platform.
                </li>
                <li>
                  Employers are responsible for paying all applicable fees
                  associated with their use of the Services in a timely manner.
                </li>
              </ul>
              <p style={{ marginBottom: "20px" }}>
                We log or store each and every records on user’s activity using
                our Services. If you choose to create a user account, you must
                provide some basic information, such as an email address and
                password. If you want to subscribe to our premium services
                through the official website, you will need to provide certain
                additional information, such as billing information and
                country/address. You must maintain and update this information
                current, truthful, complete, and accurate. You warrant that you
                are authorized to provide such information. You authorize us to
                verify your information at any time. If it is found to be
                untrue, inaccurate, not current or incomplete, we retain the
                right, in our sole discretion, to suspend or terminate your user
                account and your access to the Services.
              </p>
              <p style={{ marginBottom: "20px" }}>
                You are responsible for maintaining the confidentiality of your
                account, username and password and for restricting access to
                your account. You must not share your password or other account
                access information with any other party, temporarily or
                permanently, and you agree to accept responsibility for all
                activities that occur under your account or password, whether or
                not authorized by you. You agree to immediately notify us of any
                unauthorized use of your account, username or password, as the
                case may be.
              </p>
              <p style={{ marginBottom: "20px" }}>
                Your use of the Services is at your own risk. We develop our
                Services by introducing new features or modifying current ones
                constantly. The Services may be modified, updated, interrupted
                or suspended at any time without notice or liability. We do not
                bear any liability for any harm or other adverse consequences to
                you, caused by this. WorkWaveHub, its owners, employees, agents
                and others that are involved with the Services are not in any
                way or form liable for any harm of any kind executed or
                intended, resulting from or arising through or from the use of
                any account registered with Services.
              </p>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <h2>2. Modification</h2>
            <div style={{ padding: "15px 0 15px 0", marginTop: "10px" }}>
              <p style={{ marginBottom: "20px" }}>
                All users of WorkWaveHub Services are obliged to ensure that
                they are familiar with the most current wording of the Terms.
                The change of the Terms may be communicated to you by publishing
                the updated Terms on WorkWaveHub website and app, where the date
                of the most current wording of the Terms is indicated at the top
                of the web page. Since you agree to be bound by these Terms each
                time you use the WorkWaveHub services, please consider reviewing
                the terms each time when you use the WorkWaveHub services. Each
                update of the terms comes into force as of the moment when it is
                published on this web page. You understand and agree that any
                continued use and access to the WorkWaveHub Services after any
                updates to these terms are published, means that you voluntarily
                agree to be bound by the updated Terms.
              </p>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <h2>3. Privacy Policy</h2>
            <div style={{ padding: "15px 0 15px 0", marginTop: "10px" }}>
              <p style={{ marginBottom: "20px" }}>
                We log every user activity (pages visited, locations, links
                clicked, login attempts, emails etc.) to our servers (for
                security and troubleshooting). We do not get involved in any
                form of censorship. Except for the items described in our
                Privacy Policy, we will not provide your personal information to
                any third party. We do not cooperate with any requests for
                information unless we are ordered by a court of competent
                jurisdiction and most of these requests would not be from a
                court of competent jurisdiction. We will protect you to the max
                and our system is setup to automatically do so.
              </p>
              <p style={{ marginBottom: "20px" }}>
                For information about how we process information about you and
                your use of our Services, please see our Privacy Policy.
              </p>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <h2>4. Third-Party Websites and Services</h2>
            <div style={{ padding: "15px 0 15px 0", marginTop: "10px" }}>
              <p style={{ marginBottom: "20px" }}>
                We may provide you with content{" "}
                {"{Employer’s job post or Blog posts or Articles or News}"}{" "}
                belonging to Third Parties (“Third Parties”) or links leading to
                third-party websites. We are not responsible for the
                availability of the content provided by Third Parties as they
                are not under the control or supervision of WorkWaveHub, and
                they may have different terms of use and policies. Your access
                through our Services to any website, service, or content
                provided by Third Parties does not indicate any relationship
                between us and such Third Parties.
              </p>
              <p style={{ marginBottom: "20px" }}>
                Also, we may use the services provided by other third parties,
                from time to time, include computer software supplied by third
                parties, which is utilized by permission of the respective
                licensors and/or copyright holders on the terms provided by such
                parties. We expressly disclaim any warranty or other assurance
                to you regarding such third-party’s services.
              </p>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <h2>5. License</h2>
            <div style={{ padding: "15px 0 15px 0", marginTop: "10px" }}>
              <p style={{ marginBottom: "20px" }}>
                Subject to the terms and conditions of these Terms, we grant you
                a limited, revocable, non-exclusive, personal, non-transferable,
                non-sublicensable, fixed-term license to: (1) download our Apps;
                and (2) enter into the Website; and (3) use the Services,
                including, without limitation, the products and services made
                available on or through our Apps or our Website. No other right
                or license, express or implied, of any kind is granted to you
                hereunder with respect to the Services. The license provided
                herein is effective until terminated. This license automatically
                terminates if you fail to comply with these Terms.
              </p>
              <p style={{ marginBottom: "20px" }}>
                {" "}
                The Services, including, but not limited to, our Apps and
                Website, are owned and copyrighted by WorkWaveHub and protected
                in Iran and worldwide. We retain all rights, titles, and
                interest in and to the Services and any portion thereof,
                including, without limitation, all copyrights, trademarks,
                service marks, patents, trade secrets and other intellectual
                property rights. You shall not take any action to jeopardize,
                limit or interfere in any manner with our ownership of and
                rights with respect to the Services. All rights are reserved
                unless otherwise noted.
              </p>
              <p style={{ marginBottom: "20px" }}>
                By accessing and using this website and by using the Services,
                you accept and acknowledge that the Services, including the
                appearance, content, selection, assembly and functionality and
                any other parts or specifics of our Website and the Apps, is the
                ownership of WorkWaveHub (despite whether the specific content
                is individually protected by copyright), and you are forbidden
                from registration, adoption or any other use of trade names,
                symbols or signs that are either identical or confusingly
                similar to any trademarks owned by WorkWaveHub.
              </p>
              <p style={{ marginBottom: "20px" }}>
                {" "}
                You hereby grant WorkWaveHub and/or its affiliated companies a
                perpetual, irrevocable, worldwide license to use Feedback (as
                defined below) you communicate to us without reimbursement or
                compensation, without any obligation to report on such use, and
                without any other restriction. You waive (or agree not to
                enforce) any and all rights that may now or in future exist
                (including moral and equivalent rights) in Feedback. (“Feedback”
                refers to any recommendation, idea, proposal, suggestion,
                feedback, review, or other input related to our Services.)
              </p>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <h2>6. Subscriptions</h2>
            <div style={{ padding: "15px 0 15px 0", marginTop: "10px" }}>
              <p style={{ marginBottom: "20px" }}>
                By registration to the Services, you agree to become a user
                (“registered user”) for the period you have elected. A full list
                of subscription plans and pricing is available on the Website
                and Apps. We reserve the right to amend subscription fees or
                institute new fees at any time upon reasonable advance notice
                posted on the Website or Apps or sent via email. We may suspend
                or cancel the Services if we do not receive a full payment from
                you on time. Any changes to the pricing will not affect the
                Subscriber’s current subscription period, but may become
                effective upon subscription renewal, which will be decided
                solely at the discretion of WorkWaveHub. Subscription purchases
                and refunds are handled via multiple third-party payment
                companies. To find out more about these third-party payment
                companies and how they process your data, please refer to our
                Privacy Policy.
              </p>
              <p style={{ marginBottom: "20px" }}>
                Subscriptions will automatically renew unless you turned off the
                auto-renew at least 24-hours before the end of the current
                subscription period. The subscription fee will be charged for
                renewal within 24-hours prior to the end of the current
                subscription period to the payment method you last selected.
                Subscriptions may be managed by the user and auto-renewal may be
                turned off by going to the user's Account Settings after
                purchase.
              </p>
              <p style={{ marginBottom: "20px" }}>
                {" "}
                We reserve the right to verify credit/debit card payments before
                completing the subscription purchase. We also reserve the right
                to:
              </p>
              <p style={{ marginBottom: "20px" }}>
                {" "}
                (i) retry failed payments to complete the transaction,
                <br />
                (ii) Inquire and continue to use the updated credit card account
                information (if applicable) through the card brand,
                <br />
                (iii) change or modify authorized payment companies to assist
                with payment processing.
              </p>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <h2>7. Prohibited and Restricted Uses</h2>
            <div style={{ padding: "15px 0 15px 0", marginTop: "10px" }}>
              <p style={{ marginBottom: "20px" }}>
                We do not condone any unlawful use of the Services. Our Services
                shall not be used for any criminal, illicit and illegal acts,
                and you accept and agree not to violate any law of any
                jurisdiction that you are originating from and any other
                applicable law. It is your responsibility to know and comprehend
                any and all relevant laws related to any jurisdiction or venue
                that concerns you, your actions and your use of Services. We
                reserve the right to limit, in our sole discretion, the
                availability of the Services or any portion thereof, to any
                person, entity, geographic area, or jurisdiction, at any time.
              </p>
              <p style={{ marginBottom: "20px" }}>
                {" "}
                To protect the Services from being misused or used to harm
                someone, we reserve the right to take appropriate measures when
                our Services are being used contrary to these Terms and
                applicable laws. You agree that we may terminate your account or
                take any other legal measure provided by law, without providing
                a refund for Services already paid, if you misuse the Service.
              </p>
              <p style={{ marginBottom: "20px" }}>
                You agree that you shall not:
              </p>
              <ul style={{ marginBottom: "20px" }}>
                <li>
                  {" "}
                  Send or transmit unsolicited advertisements or content (i.e.,
                  “spam”) over the Services.
                </li>

                <li>
                  {" "}
                  Send, post, or transmit over the Services any content which is
                  illegal, hateful, threatening, insulting, or defamatory;
                  infringes on intellectual property rights; invades privacy; or
                  incites violence.
                </li>

                <li>
                  {" "}
                  Upload, download, post, reproduce, or distribute any content
                  protected by copyright or any other proprietary right without
                  first having obtained permission from the owner of the
                  proprietary content.
                </li>

                <li>
                  {" "}
                  Upload, download, post, reproduce, or distribute any content
                  that includes sexual or explicit depictions of minors.
                </li>

                <li>
                  {" "}
                  Engage in any conduct that restricts or inhibits any other
                  Subscriber from using or enjoying the Services.
                </li>

                <li>
                  {" "}
                  Attempt to access, probe, or connect to computing devices
                  without proper authorization (i.e., any form of “hacking”).
                </li>

                <li>
                  {" "}
                  Use the Services for any military purpose, including cyber
                  warfare, weapons development, design, manufacture, or
                  production of missiles, nuclear, chemical, or biological
                  weapons。
                </li>

                <li>
                  {" "}
                  Violate, infringe, or misappropriate our licensors and/or any
                  other third parties' copyright, other intellectual property
                  rights, privacy, or other legal rights.
                </li>

                <li>
                  {" "}
                  Use the Service for anything other than lawful purposes.
                </li>
              </ul>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <h2>8. Disclaimer of Warranties</h2>
            <div style={{ padding: "15px 0 15px 0", marginTop: "10px" }}>
              <p style={{ marginBottom: "20px" }}>
                WorkWaveHub is a platform that connects users. We are not
                responsible for the outcome of any interactions between users.
              </p>
              <p style={{ marginBottom: "20px" }}>
                The services (including, without limitation, our applications,
                services, and websites) are provided “as is” and with all
                faults. We make no representation or warranty whatsoever
                regarding the completeness, accuracy, adequacy, suitability,
                functionality, availability, or operation of the services.
              </p>
              <p style={{ marginBottom: "20px" }}>
                {" "}
                You acknowledge that we do not have control over your use of the
                services, and we do not warrant the performance or results that
                may be obtained through your use of the services. You assume all
                risks and responsibility for your use of the services and for
                any loss of or errors in any data or information.
              </p>
              <p style={{ marginBottom: "20px" }}>
                {" "}
                To the full extent permissible by applicable law, we disclaim
                all warranties, express or implied, including, but not limited
                to, the implied warranties of merchantability, fitness for a
                particular purpose, and non-infringement, and any warranties
                arising through course of dealing or usage of trade. Without
                limiting the foregoing, we neither warrant nor represent that
                your use of the services will not infringe the rights of any
                third parties, nor that the services will be available for your
                access or use, nor that operation of the services will be
                error-free or uninterrupted. Please note that some jurisdictions
                may not allow the exclusion of implied warranties, so some of
                the above exclusions may not apply to you. In addition, you may
                also have other er rights that vary from one jurisdiction to
                another jurisdiction.
              </p>
              <p style={{ marginBottom: "20px" }}>
                WorkWaveHub does not guarantee the quality of work performed by
                Freelancers or the success of any project. We are not
                responsible for any disputes arising between Freelancers and
                Employers.
              </p>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <h2>9. Limitation of Liability</h2>
            <div style={{ padding: "15px 0 15px 0", marginTop: "10px" }}>
              <p style={{ marginBottom: "20px" }}>
                Your use of the services is at your own risk. Neither we nor any
                of its parents, subsidiaries or affiliates, nor any of their
                employees, officers or directors, shall be liable for any
                direct, indirect, punitive, incidental, special, consequential,
                or other damages (including, without limitation, loss of data or
                information of any kind, loss of business, lost profits,
                interruption of business, cost of cover or any other damages)
                arising out of or in any way related to this agreement or the
                use or inability to use the services, whether based on contract,
                tort, strict liability or otherwise, even if we have been
                advised of the possibility of such damages.
              </p>
              <p style={{ marginBottom: "20px" }}>
                {" "}
                In any case, the liability of WorkWaveHub shall not exceed what
                the user paid to WorkWaveHub and shall not include attorney fees
                or court costs irrespective of any laws or statutes that may
                prescribe otherwise.
              </p>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <h2>10. Indemnification</h2>
            <div style={{ padding: "15px 0 15px 0", marginTop: "10px" }}>
              <p style={{ marginBottom: "20px" }}>
                You agree, at your own expense, to indemnify, defend and hold
                harmless WorkWaveHub, its parents, subsidiaries and affiliates,
                and their officers, directors, employees, agents, distributors
                and licensees, from and against any judgments, losses,
                deficiencies, damages, liabilities, costs, claims, demands,
                suits, and expenses (including, without limitation, reasonable
                attorneys’ fees, expert witness fees and expenses) incurred in,
                arising out of or in any way related to your breach of these
                Terms, your use of the Services, or any of your other acts or
                omissions.
              </p>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <h2>11. Notices</h2>
            <div style={{ padding: "15px 0 15px 0", marginTop: "10px" }}>
              <p style={{ marginBottom: "20px" }}>
                All notices and other communications hereunder shall be in
                writing and shall be deemed to have been duly given when
                delivered personally, sent by certified or registered mail,
                return receipt requested, postage prepaid, or sent by overnight
                courier, addressed as follows:
              </p>
              <ul style={{ marginBottom: "20px" }}>
                <li>
                  If to you: At the email address or physical address that you
                  have provided to us in your account.
                </li>
                <li>
                  If to WorkWaveHub:{" "}
                  <a
                    style={{ color: "black" }}
                    href="mailto:contact@workwavehub.com"
                  >
                    contact@workwavehub.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <h2>12. Choice of Law</h2>
            <div style={{ padding: "15px 0 15px 0", marginTop: "10px" }}>
              <p style={{ marginBottom: "20px" }}>
                These Terms will be governed by and construed in accordance with
                the laws of Iran, without regard to its conflict of laws
                provisions. Any dispute arising out of or relating to these
                Terms will be subject to the exclusive jurisdiction of the
                courts of Iran.
              </p>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <h2>13. Changes to the Terms</h2>
            <div style={{ padding: "15px 0 15px 0", marginTop: "10px" }}>
              <p style={{ marginBottom: "20px" }}>
                We may revise these Terms at any time by posting the revised
                Terms on the Platform. You are responsible for checking the
                Terms periodically for updates. Your continued use of the
                Platform following the posting of revised Terms means that you
                accept and agree to be bound by the revisions.
              </p>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <h2>14. Privacy Policy</h2>
            <div style={{ padding: "15px 0 15px 0", marginTop: "10px" }}>
              <p style={{ marginBottom: "20px" }}>
                Our Privacy Policy explains how we collect, use, and disclose
                your personal information. You can find our Privacy Policy{" "}
                <a href="/privacypolicy">
                  https://dev.workwavehub.com/privacypolicy
                </a>
              </p>
              <p style={{ marginBottom: "20px" }}>
                BY ACCESSING OR USING THE PLATFORM, YOU ACKNOWLEDGE THAT YOU
                HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THESE TERMS
              </p>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <h2>Last Updated:</h2>
            <div style={{ padding: "0 0 15px 0", marginTop: "10px" }}>
              <p style={{ marginBottom: "20px" }}>2024.05.15</p>
            </div>
          </div>
        </div> */}
      </Container>
    </section>
  );
}
