import { Box, Typography, Grid, Paper, Modal, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import Employee from "../../../../../Assets/Images/CreateProjects.png"; // Import your image component here
import Employeer from "../../../../../Assets/Images/BrowseProfile.png"; // Import your image component here
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ModalAuthBanner from "../../../../../Assets/Images/signinauthBanner.png"; // Import your image component here
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../../../../Localization/i18n";
import ModalBannerText1 from "../../../ModalBannerText/ModalBannerText1";

export default function BrowseEmployer({ open, handleClose }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const bgImage = ModalAuthBanner; // Assuming YourImage is the variable containing the image URL
  const navigate = useNavigate();

  const navigateToCreateProject = () => {
    navigate("/dashboard/employer/createproject");
  }

  const navigateToBrowseProjects = () => {
    navigate("/dashboard/employer/browseprofiles");
    window.location.reload(true)
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? '95%' : isDesktop ? 1200 : '75%', // Adjust width for mobile and desktop devices
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          px: 0,
          py: 0,
        }}
      >
        <Grid
          container
          component="main"
          sx={{ height: "100vh", maxHeight: "90vh" }}
        >
           <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: { xs: "none", md: "block" }, // Hide on extra-small screens
                backgroundImage: `url(${ModalAuthBanner})`,
                backgroundRepeat: "no-repeat",
                backgroundColor: (t) =>
                  t.palette.mode === "light"
                    ? t.palette.grey[50]
                    : t.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "top",
                position: "relative", // Add relative positioning for the text container
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white", // Adjust text color based on background
                  textAlign: "center",
                  padding: "16px",
                  width: "100%",
                  mt: 4,
                  // backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background for text readability
                  borderRadius: "8px",
                }}
              >
                <ModalBannerText1 />
              </Box>
            </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className="sideBg"
            sx={{
              display: "flex",
              paddingLeft: 0,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: '#f8f8f9'
            }}
            component={Paper}
            elevation={6}
            square
          >
            <Box className='plZero prZero' sx={{ paddingTop: 0, px: 8 }}>
              <div className="signupIntro sognupIntro browseChange">
                <h2 className="">
                  {t("Browse")}
                </h2>
                <Typography
                  className=""
                  variant="p"
                  sx={{
                    display: "block",
                    color: "#777777",
                    textAlign: "start",
                    py: 1,
                  }}
                >
                  {t("BeginJourney")}
                </Typography>
              </div>

              <Box
                className="radioSelection"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: 4,
                }}
              >
                <Box className="employee">
                  <Grid container className="employeeInner" >
                    <Grid item xs="auto">
                      <img
                        className="dNoneRespo"
                        height={50}
                        width={50}
                        src={Employee}
                        alt="Employee"
                      />
                    </Grid>
                    <Grid
                      item
                      xs="auto"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "space-between",
                      }}

                    >
                      <div className="employeeText" onClick={navigateToCreateProject}>
                        <Box sx={{ px: 2 }} className="">
                          <h4 className="textStart">{t("CreateProj")}</h4>
                        </Box>
                        <Box sx={{ px: 2 }} className="beginText">
                          {t("CreateProjectAndStartActivities")}
                        </Box>
                      </div>
                      <div className="iconArrow">
                        <ArrowForwardIcon />
                      </div>
                    </Grid>
                  </Grid>
                </Box>

                <Box className="employee" onClick={navigateToBrowseProjects}>
                  <Grid
                    container
                    className="employeeInner"
            
                  >
                    <Grid item xs="auto">
                      <img
                        className="dNoneRespo"
                        height={50}
                        width={50}
                        src={Employeer}
                        alt="Employer"
                      />
                    </Grid>
                    <Grid
                      item
                      xs="auto"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="employeeText">
                        <Box sx={{ px: 2 }} className="">
                          <h4 className="textStart">{t("BrowseProf")}</h4>
                        </Box>
                        <Box sx={{ px: 2 }} className="beginText">
                          {t("BrowseProfileAndStartActivities")}
                        </Box>
                      </div>
                      <div className="iconArrow">
                        <ArrowForwardIcon />
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
