import { Box } from "@mui/system";
import React from "react";
import BrowseJobsEmployee from "./BrowseJobsEmployee/BrowseJobsEmployee";
import BrowseJobsEmployer from "./BrowseJobsEmployer/BrowseJobsEmployer";

export default function BrowseJobs({ userRole }) {
  userRole = userRole || "employer"
  return (
    <Box >
      {userRole === "employee" && (
        <BrowseJobsEmployee />
        // Render content specific to employees
      )}
      {userRole === "employer" && (
        <BrowseJobsEmployer />
        // Render content specific to employers
      )}
      {/* Add more conditions for other roles if necessary */}
    </Box>
  );
}
