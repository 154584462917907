import {
  Box,
  Button,
  FormLabel,
  Grid,
  TextField,
  Typography,
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Stack,
  Chip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useMemo, useState } from "react";
import Dropdown from "../../../Components/Dropdown/Dropdown";
import styled from "@emotion/styled";
import arrowCross from "../../../Assets/Images/arrow-up-right-01-round.png";
// import doc from "../../../Assets/Images/document-attachment.png";
import delete1 from "../../../Assets/Images/delet.png";
import { DropzoneArea } from "material-ui-dropzone";
import "./EmployeerCreateProject.css";

import { TextareaAutosize as BaseTextareaAutosize } from "@mui/material";
import { getAPI, postAPI } from "../../../Services/Api";
import { toast } from "react-toastify";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { countries } from "../../../Helpers/common_constants";
import loader from "../../../Assets/Images/loader.svg";
import CustomCaretIcon from "./CustomCaretIcon";
// import { TextareaAutosize } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../../Localization/i18n";

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 4px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid #cbcbcb;
  box-shadow: none
    };


  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

export default function EmployeerCreateProject() {
  const { t } = useTranslation();

  const [isFocused, setIsFocused] = useState(false);
  const [formData, setFormData] = useState({
    specialization: "",
    type: "",
    gender: "",
    skills: [],
    location: "",
    requiredEmployee: "",
    payBy: "",
    projectDuration: "",
    price: "",
    projectTitle: "",
    projectDetail: "",
    minBudget: "",
    maxBudget: "",
  });

  const [errorMessages, setErrorMessages] = useState({
    specialization: "",
    gender: "",
    skills: t("selectCategoryFirst"),
    location: "",
    requiredEmployee: "",
    payBy: "",
    projectDuration: "",
    price: "",
    projectTitle: "",
    projectDetail: "",
    minBudget: "",
    maxBudget: "",
  });

  const [skills, setSkills] = useState([]);
  const [projectData, setProjectData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getAllSkills();
    getProjectData();
    LoaderHide();
  }, []);

  const isDropdownSelected = (value) => value !== "";

  const isNotEmpty = (value) => value.trim() !== "";

  const getAllSkills = async () => {
    try {
      const response = await getAPI("skills/all");
      if (response.statusCode === 200) {
        setSkills(response?.data);
      }
    } catch (error) {
      console.log("error===", error);
    }
  };

  const filteredSkills = useMemo(() => {
    if (!projectData?.categories || !formData?.gender?.length) return [];
    let arr = [];
    formData.gender.map((category) => {
      const categ = projectData.categories.find((ele) => ele._id == category);
      if (categ && categ?.skills?.length) arr = [...arr, ...categ.skills];
    });
    return arr;
  }, [formData, projectData]);

  const getProjectData = async () => {
    LoaderShow();
    try {
      const response = await getAPI("project/dropdown");
      if (response.statusCode === 200) {
        setProjectData(response?.data);
      }
    } catch (error) {
      console.log("error===", error);
    }
    LoaderHide();
  };

  const [selectedNames, setSelectedNames] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  // const renderSkillOptions = () => {
  //   return skills?.map(skill => ({ label: skill.name, value: skill._id }))

  // };

  // const hourlyOptions = [
  //   { value: "hourly", label: "hourly" },
  //   { value: "fixed price", label: "fixed price" },
  // ];

  // const requiredEmployee = [
  //   { value: "one", label: "One" },
  //   { value: "two", label: "Two" },
  //   { value: "three", label: "Three" },
  //   { value: "four", label: "Four" },
  //   { value: "five", label: "Five" },
  // ];

  // const projectDuration = [
  //   { value: "15days", label: "15 Days" },
  //   { value: "oneMonth", label: "One Month" },
  //   { value: "twoMonths", label: "Two Months" },
  // ];

  // const priceRange = [
  //   { value: "1-5", label: "1-5" },
  //   { value: "5-10", label: "5-10" },
  //   { value: "10-15", label: "10-15" },
  //   { value: "15-20", label: "15-20" }
  // ];

  // const genderOptions = [
  //   { value: "IT", label: "IT" },
  //   { value: "finance", label: "Finance" },
  //   { value: "banking", label: "Banking" },
  //   { value: "marketing", label: "Marketing" },
  // ];

  // const countries = [
  //   { value: 'US', label: 'United States' },
  //   { value: 'CA', label: 'Canada' },
  //   { value: 'GB', label: 'United Kingdom' },
  //   { value: 'AU', label: 'Australia' },
  // ];

  const handleFocus = () => {
    setIsFocused(false);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleGender = (event) => {
    const value = event.target.value;
    setSelectedCategories(value);
    setFormData({ ...formData, gender: value });
    setErrorMessages({
      ...errorMessages,
      gender: value.length ? "" : <>{t("PleaseSelectCategory")}</>,
      skills: value.length ? "" : "Please select skill first",
    });
  };

  const handleProjectType = (event) => {
    const value = event.target.value;
    setFormData({ ...formData, type: value });
    setErrorMessages({
      ...errorMessages,
      type: isDropdownSelected(value) ? (
        ""
      ) : (
        <>{t("PleaseSelectPaymentType")}</>
      ),
    });
  };

  // const handleminBudget = (event) => {

  //   const value = event.target.value;

  //   if (value <= formData.price) {
  //     setFormData({ ...formData, minBudget: value });
  //     setErrorMessages({ ...errorMessages, minBudget: isDropdownSelected(value) ? "" : "Please select min budget." });
  //   } else {
  //     setErrorMessages((prevErrorMessages) => ({
  //       ...prevErrorMessages,
  //       minBudget: "Min Budget should not be less than the selected Estimate Budget",
  //     }));
  //   }
  // };

  // const handlemaxBudget = (event) => {
  //   const value = event.target.value;
  //   if (value <= formData.price) {
  //     setFormData({ ...formData, maxBudget: value });
  //     setErrorMessages({ ...errorMessages, maxBudget: isDropdownSelected(value) ? "" : "Please select max budget." });
  //   } else {
  //     setErrorMessages((prevErrorMessages) => ({
  //       ...prevErrorMessages,
  //       maxBudget: "Max Budget should not be less than the selected Estimate Budget",
  //     }));
  //   }
  // };

  // const handleSkills = (event) => {
  //   const value = event.target.value;
  //   setFormData({ ...formData, skills: value });
  //   setErrorMessages({ ...errorMessages, skills: isDropdownSelected(value) ? "" : "Please select skills." });
  // };
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for menu open/close
  const [isCategoryMenuOpen, setIsCategoryMenuOpen] = useState(false); // State for category menu open/close

  const handleSkills = (event) => {
    const value = event.target.value;
    setSelectedNames(value);
    setSelectedNames(event.target.value); // Update selected values

    // setSkillErroMessage({
    //   ...skillErrorMessage,
    //   skills: value.length ? "" : "Please select skills."
    // });
    setErrorMessages({
      ...errorMessages,
      skills: value.length ? "" : <>{t("PleaseSelectSkills")}</>,
    });
  };

  const handleCloseSelect = () => {
    setIsMenuOpen(false); // Close the select menu
  };

  const handleLocation = (event) => {
    const value = event.target.value;
    setFormData({ ...formData, location: value });
    setErrorMessages({
      ...errorMessages,
      location: isDropdownSelected(value) ? (
        ""
      ) : (
        <>{t("PleaseSelectLocation")}</>
      ),
    });
  };

  // const handleRequiredEmployee = (event) => {
  //   const value = event.target.value;
  //   setFormData({ ...formData, requiredEmployee: value });
  //   setErrorMessages({ ...errorMessages, requiredEmployee: isDropdownSelected(value) ? "" : "Please select required employee." });
  // };

  // const handlePayBy = (event) => {
  //   const value = event.target.value;
  //   setFormData({ ...formData, payBy: value });
  //   setErrorMessages({ ...errorMessages, payBy: isDropdownSelected(value) ? "" : "Please select payby." });
  // };

  const handleProjectDuration = (event) => {
    const value = event.target.value;
    setFormData({ ...formData, projectDuration: value });
    setErrorMessages({
      ...errorMessages,
      projectDuration: isDropdownSelected(value) ? (
        ""
      ) : (
        <>{t("PleaseSelectDuration")}</>
      ),
    });
  };

  const handlePrice = (event) => {
    const value = event.target.value;
    const minBudget = value.name - 200 < 100 ? 100 : value.name - 200;

    setFormData((prevFormData) => ({
      ...prevFormData,
      price: value,
      minBudget: minBudget, // Automatically set minBudget to selected price - 200
      maxBudget: Number(value.name) + 200, // Reset maxBudget to enforce re-selection
    }));

    setErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      price: value ? "" : <>{t("PleaseSelectPrice")}</>,
      minBudget: value ? "" : <>{t("PleaseSelectMinBudget")}</>,
    }));
  };

  const handleMinBudget = (event) => {
    // const value = parseFloat(event.target.value);
    // console.log("value======", value)
    // if (value <= formData.price.name) {
    //   setFormData({ ...formData, minBudget: value });
    //   setErrorMessages({ ...errorMessages, minBudget: "" });
    // }
    // else {
    //   setErrorMessages({ ...errorMessages, minBudget: "Min Budget should not be greater than the selected Estimate Budget" });
    // }

    const value = event.target.value;
    if (value === "") {
      // Allow clearing the input field
      setFormData({ ...formData, minBudget: value });
      setErrorMessages({ ...errorMessages, minBudget: "" });
    } else {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue) && numericValue <= formData.price.name) {
        setFormData({ ...formData, minBudget: numericValue });
        setErrorMessages({ ...errorMessages, minBudget: "" });
      } else {
        setErrorMessages({
          ...errorMessages,
          minBudget: <>{t("MinBudgetShouldBe")}</>,
        });
      }
    }
  };

  const handleMaxBudget = (event) => {
    // const value = event.target.value
    // if (value <= formData.price.name) {
    //   setFormData({ ...formData, maxBudget: value });
    //   setErrorMessages({ ...errorMessages, maxBudget: "" });
    // } else {
    //   setErrorMessages({ ...errorMessages, maxBudget: "Max Budget should not be greater than the selected Estimate Budget" });
    // }

    const value = event.target.value;
    const values = event.target.value;
    setFormData((dt) => {
      return {
        ...dt,
        maxBudget: value,
      };
    });
    if (value === "") {
      setErrorMessages({ ...errorMessages, maxBudget: "" });
    } else {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue) && numericValue > formData.minBudget) {
        setErrorMessages({ ...errorMessages, maxBudget: "" });
      } else if (isNaN(numericValue)) {
        setErrorMessages({
          ...errorMessages,
          maxBudget: t("EnterValidNumber"),
        });
      } else if (numericValue < formData.minBudget) {
        setErrorMessages({
          ...errorMessages,
          maxBudget: <>{t("MaxMinGreater")}</>,
        });
      } else {
        setErrorMessages({
          ...errorMessages,
          maxBudget: <>{t("MaxBudgetShould")}</>,
        });
      }
    }
  };

  // const handleProjectTitle = (event) => {
  //   const value = event.target.value;
  //   setFormData({ ...formData, projectTitle: value });
  //   setErrorMessages({ ...errorMessages, projectTitle: formData.projectTitle.length < 3 ? "Project Title should be at least 3 characters long." : "" });
  // };

  // const handleProjectDetail = (event) => {
  //   const value = event.target.value;
  //   setFormData({ ...formData, projectDetail: value });
  //   setErrorMessages({ ...errorMessages, projectDetail: formData?.projectDetail?.length < 25 ? "Project Detail should be at least 25 characters long." : "" });
  // };

  const handleProjectDetail = (event) => {
    const value = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      projectDetail: value,
    }));

    setErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      projectDetail: value.length < 25 ? <>{t("ProjectDetail25")}</> : "",
    }));
  };

  const handleProjectTitle = (event) => {
    const value = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      projectTitle: value,
    }));

    setErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      projectTitle: value.length < 3 ? <>{t("ProjectTitle3")}</> : "",
    }));
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (
      !isNotEmpty(formData.projectTitle) ||
      formData.projectTitle.length < 3
    ) {
      errors.projectTitle = t("ProjectTitle3");
      isValid = false;
    }

    if (
      !isNotEmpty(formData?.projectDetail) ||
      formData?.projectDetail?.length < 25
    ) {
      errors.projectDetail = t("ProjectDetail25");
      isValid = false;
    }

    if (!isDropdownSelected(formData.gender)) {
      errors.gender = t("PleaseSelectCategory");
      isValid = false;
    }

    if (!isDropdownSelected(formData.type)) {
      errors.type = t("PleaseSelectPaymentType");
      isValid = false;
    }

    if (selectedNames.length === 0) {
      errors.skills = t("PleaseSelectSkills");
      isValid = false;
    }

    if (!isDropdownSelected(formData.location)) {
      errors.location = t("PleaseSelectLocation");
      isValid = false;
    }

    // if (!isDropdownSelected(formData.requiredEmployee)) {
    //   errors.requiredEmployee = "Please select required employee";
    //   isValid = false;
    // }

    // if (!isDropdownSelected(formData.payBy)) {
    //   errors.payBy = "Please select payby";
    //   isValid = false;
    // }

    if (!isDropdownSelected(formData.projectDuration)) {
      errors.projectDuration = t("PleaseSelectDuration");
      isValid = false;
    }

    if (!isDropdownSelected(formData.price)) {
      errors.price = t("PleaseSelectPrice");
      isValid = false;
    }

    if (!isDropdownSelected(formData.minBudget)) {
      errors.minBudget = t("PleaseSelectMinBudget");
      isValid = false;
    }

    if (!formData.maxBudget) {
      errors.maxBudget = t("PleaseSelectMaxBudget");
      isValid = false;
    } else if (Number(formData.maxBudget) < Number(formData.price.name)) {
      errors.maxBudget = t("MaxBudgetGreater");
      isValid = false;
    }
    setErrorMessages(errors);
    return isValid;
  };

  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  const handleFileChange = (newFiles) => {
    if (
      [...files, ...newFiles].reduce((val, ele) => ele?.size + val, 0) >
      1_048_576
    ) {
      toast.warn("Maximum size of attachments should be less than 2mb.");
      return;
    }
    setFiles((dt) => [...dt, ...newFiles]);
    const names = newFiles.map((file) => file.name);
    setFileNames((dt) => [...dt, ...names]);
  };

  const handleDeleteFile = (file, index) => {
    setFiles((prevFiles) => prevFiles.filter((f, i) => index !== i));
    setFileNames((prevFiles) => prevFiles.filter((f, i) => index !== i));
  };

  const handleSaveButtonClick = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      LoaderShow();
      try {
        const token = GetUserDetails().token;

        const dataToSend = new FormData();

        dataToSend.append("title", formData.projectTitle);
        dataToSend.append("description", formData.projectDetail);
        // dataToSend.append('skillsRequired', JSON.stringify(selectedNames));
        // dataToSend.append('skillsRequired', JSON.stringify(selectedNames));
        selectedNames.forEach((name, index) => {
          dataToSend.append(`skillsRequired[${index}]`, name);
        });
        dataToSend.append(
          "budget",
          `${formData.minBudget}-${formData.maxBudget}`
        );
        dataToSend.append("currency", "USD");
        formData.gender.forEach((name, index) => {
          dataToSend.append(`category[${index}]`, name);
        });
        // dataToSend.append("category", formData.gender);
        dataToSend.append("proposalId", "");
        dataToSend.append("duration", formData.projectDuration._id);
        dataToSend.append("paymentType", formData.type);
        dataToSend.append("location", formData.location);
        dataToSend.append("estimatedBudget", formData.price._id);
        // dataToSend.append('budget', formData.price);
        // dataToSend.append('paymentType', formData.payBy);
        // dataToSend.append('requiredEmployee', formData.requiredEmployee);

        files.forEach((file, index) => {
          dataToSend.append("documents", file);
        });

        // const dataToSends = {
        //   title: formData.projectTitle,
        //   description: formData.projectDetail,
        //   skillsRequired: selectedNames,
        //   budget: `${formData.minBudget}-${formData.maxBudget}`,
        //   currency: "USD",
        //   category: formData.gender,
        //   proposalId: "",
        //   duration: formData.projectDuration,
        //   paymentType: formData.type,
        //   location: formData.location,
        //   estimatedBudget: formData.price,
        //   documents: files
        // }
        const response = await postAPI("project/create", dataToSend, token);
        if (response.statusCode === 200) {
          LoaderHide();
          toast.success(response.message);
          navigate("/dashboard/employer/myprojects");
          setFormData({
            projectTitle: "",
            projectDetail: "",
            skills: "",
            price: "",
            gender: "",
            projectDuration: "",
            type: "",
            location: "",
            payBy: "",
          });
          setFileNames([]);
          setFiles([]);
          setSelectedNames([]);
          setSelectedCategories([]);
        }
      } catch (error) {
        toast.error(error.message);
        LoaderHide();
      }
    } else {
      LoaderHide();
    }
  };

  // const [selectedFiles, setSelectedFiles] = useState([]);
  // const [fileError, setFileError] = useState("");

  // const handleFileChange = (files) => {
  //   setSelectedFiles(files);
  //   setFileError("");
  // };

  // const handleDeleteFile = (file) => {
  //   setSelectedFiles((prevFiles) => prevFiles.filter((f) => f !== file));
  // };

  // const validateFiles = () => {
  //   if (selectedFiles.length === 0) {
  //     setFileError("Please upload at least one file.");
  //     return false;
  //   }

  //   // Additional validation logic if needed

  //   return true;
  // };

  // const handleUploadFile = () => {
  //   if (validateFiles()) {
  //     // Proceed with saving the files or other actions
  //     console.log("Files are valid. Proceeding with saving data:===", selectedFiles);
  //   } else {
  //     console.log("File validation failed.===");
  //   }
  // };

  return (
    <section className="EmployeerCreateProject">
      <div id="hideloding" className="loding-display">
        <img src={loader} alt="loader-img" />
      </div>
      <Box>
        <Typography
          className="employeeHead1"
          variant="h4"
          sx={{ pb: 1, fontWeight: 600 }}
        >
          {t("CreateProj")}
        </Typography>
        <Typography
          className="priceHeadEmployee"
          variant="p"
          sx={{ fontSize: 18, color: "#777" }}
        ></Typography>
      </Box>

      <Box
        className="employeeProjdetails"
        sx={{
          backgroundColor: "#fff",
          p: 5,
          py: 3.5,
          my: 4,
          borderRadius: 7.5,
          border: 1,
          borderColor: "#d6d6d6",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#000",
            fontWeight: 600,
            pb: 2,
            borderBottom: 1,
            borderColor: "#d6d6d6",
            px: 0,
          }}
        >
          {t("BasicInformation")}
        </Typography>
        <Box sx={{ py: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>
                <FormLabel
                  sx={{
                    display: "block",
                    textAlign: "start",
                    py: 1.4,
                    fontSize: 14,
                  }}
                >
                  {t("Category")}
                </FormLabel>
                <Select
                  sx={{
                    width: "100%",
                  }}
                  MenuProps={{ disableScrollLock: true }}
                  multiple
                  value={selectedCategories}
                  onChange={handleGender}
                  IconComponent={CustomCaretIcon} // Assuming CustomCaretIcon is defined elsewhere
                  input={<OutlinedInput label="Skills" />}
                  renderValue={(selected) => (
                    <Stack gap={1} direction="row" flexWrap="wrap">
                      {selected.map((value) => {
                        const selectedSkill = projectData?.categories?.find(
                          (skill) => skill._id === value
                        );
                        return (
                          selectedSkill && (
                            <Chip
                              key={value}
                              label={selectedSkill.name}
                            />
                          )
                        );
                      })}
                    </Stack>
                  )}
                  onClose={() => setIsCategoryMenuOpen(false)} // Close menu on select
                  onOpen={() => setIsCategoryMenuOpen(true)} // Open menu on select
                  open={isCategoryMenuOpen} // Controlled open state
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      borderColor: "",
                      mx: 1,
                    }}
                  >
                    <IconButton
                      size="small"
                      onClick={() => setIsCategoryMenuOpen(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  {projectData?.categories?.map((skill) => (
                    <MenuItem key={skill._id} value={skill._id}>
                      {skill.name}
                    </MenuItem>
                  ))}
                </Select>
                {errorMessages.gender && (
                  <Typography variant="p" sx={{ color: "red" }}>
                    {errorMessages.gender}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box>
                <FormLabel
                  sx={{
                    display: "block",
                    textAlign: "start",
                    py: 1.4,
                    fontSize: 14,
                  }}
                >
                  {t("ProjectTitle")}
                </FormLabel>
                <TextField
                  variant="outlined"
                  sx={{
                    background: isFocused ? "#FFFFFF" : "transparent",
                    borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                    boxShadow: isFocused
                      ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                      : "none",
                  }}
                  fullWidth={true}
                  placeholder={t("ProjectTitle")}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  value={formData.projectTitle}
                  onChange={handleProjectTitle}
                />
                {errorMessages.projectTitle && (
                  <Typography variant="p" sx={{ color: "red" }}>
                    {errorMessages.projectTitle}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ my: 1 }}>
                <FormLabel
                  sx={{
                    display: "block",
                    textAlign: "start",
                    py: 1,
                    fontSize: 14,
                  }}
                >
                  {t("ProjectDescription")}
                </FormLabel>
                <Textarea
                  sx={{
                    width: "100%",
                    border: 1,
                    borderColor: "#cbcbcb",
                    borderRadius: 1,
                  }}
                  minRows={6}
                  maxRows={6}
                  placeholder={t("ProjectDescription")}
                  value={formData.projectDetail}
                  onChange={handleProjectDetail}
                />
                {errorMessages.projectDetail && (
                  <Typography variant="p" sx={{ color: "red" }}>
                    {errorMessages.projectDetail}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl sx={{ mt: 2, width: "100%" }}>
              <InputLabel>{t("SkillsOne")}</InputLabel>
              <Select
                disabled={formData.gender.length <= 0}
                MenuProps={{ disableScrollLock: true }}
                multiple
                value={selectedNames}
                onChange={handleSkills}
                IconComponent={CustomCaretIcon} // Assuming CustomCaretIcon is defined elsewhere
                input={<OutlinedInput label="Skills" />}
                renderValue={(selected) => (
                  <Stack gap={1} direction="row" flexWrap="wrap">
                    {selected.map((value) => {
                      const selectedSkill = skills.find(
                        (skill) => skill._id === value
                      );
                      return (
                        selectedSkill && (
                          <Chip
                            key={value}
                            label={selectedSkill.name}
                          />
                        )
                      );
                    })}
                  </Stack>
                )}
                onClose={() => setIsMenuOpen(false)} // Close menu on select
                onOpen={() => setIsMenuOpen(true)} // Open menu on select
                open={isMenuOpen} // Controlled open state
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    borderColor: "",
                    mx: 1,
                  }}
                >
                  <IconButton size="small" onClick={handleCloseSelect}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                {filteredSkills.map((skill) => (
                  <MenuItem key={skill._id} value={skill._id}>
                    {skill.name}
                  </MenuItem>
                ))}
              </Select>

              {errorMessages.skills && (
                <Typography variant="p" sx={{ color: "red" }}>
                  {errorMessages.skills}
                </Typography>
              )}
            </FormControl>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box sx={{ mt: 2 }}>
                <FormLabel
                  sx={{
                    display: "block",
                    textAlign: "start",
                    py: 1.4,
                    fontSize: 14,
                  }}
                >
                  {t("ProjectTypeOne")}
                </FormLabel>
                <Dropdown
                  value={formData.type}
                  onChange={handleProjectType}
                  options={projectData?.paymentTypeEnumValues?.map((value) => ({
                    value,
                    label: `${value}`,
                  }))}
                  label={t("ProjectTypeOne")}
                />
                {errorMessages.type && (
                  <Typography variant="p" sx={{ color: "red" }}>
                    {errorMessages.type}
                  </Typography>
                )}
              </Box>
            </Grid>

            {/* <Grid item xs={12} md={6}>
              <Box>
                <FormLabel
                  sx={{
                    display: "block",
                    textAlign: "start",
                    py: 1.4,
                    fontSize: 14,
                  }}
                >
                  Skills
                </FormLabel>
                <Dropdown
                  value={formData.skills}
                  onChange={handleSkills}
                  options={renderSkillOptions()}
                  label="Skills"
                />
                {errorMessages.skills && (
                  <Typography variant="p" sx={{ color: "red" }}>
                    {errorMessages.skills}
                  </Typography>
                )}
              </Box>
            </Grid> */}
            <Grid item xs={12} md={6}>
              <Box sx={{ mt: 2 }}>
                <FormLabel
                  sx={{
                    display: "block",
                    textAlign: "start",
                    py: 1.4,
                    fontSize: 14,
                  }}
                >
                  {t("Location")}
                </FormLabel>
                <Dropdown
                  value={formData.location}
                  onChange={handleLocation}
                  options={countries.map((ele) => {
                    return { ...ele, value: ele.label };
                  })}
                  label={t("Location")}
                />
                {errorMessages.location && (
                  <Typography variant="p" sx={{ color: "red" }}>
                    {errorMessages.location}
                  </Typography>
                )}
              </Box>
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <Box>
                <FormLabel
                  sx={{
                    display: "block",
                    textAlign: "start",
                    py: 1.4,
                    fontSize: 14,
                  }}
                >
                  Required Employee
                </FormLabel>
                <Dropdown
                  value={formData.requiredEmployee}
                  onChange={handleRequiredEmployee}
                  options={requiredEmployee}
                  label="RequiredEmployee"
                />
                {errorMessages.requiredEmployee && (
                  <Typography variant="p" sx={{ color: "red" }}>
                    {errorMessages.requiredEmployee}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>
                <FormLabel
                  sx={{
                    display: "block",
                    textAlign: "start",
                    py: 1.4,
                    fontSize: 14,
                  }}
                >
                  Pay by
                </FormLabel>
                <Dropdown
                  value={formData.payBy}
                  onChange={handlePayBy}
                  options={hourlyOptions}
                  label="PayBy"
                />
                {errorMessages.payBy && (
                  <Typography variant="p" sx={{ color: "red" }}>
                    {errorMessages.payBy}
                  </Typography>
                )}
              </Box>
            </Grid> */}
            <Grid item xs={12} md={6}>
              <Box>
                <FormLabel
                  sx={{
                    display: "block",
                    textAlign: "start",
                    py: 1.4,
                    fontSize: 14,
                  }}
                >
                  {t("ProjectDuration")}
                </FormLabel>
                <Dropdown
                  value={formData.projectDuration}
                  onChange={handleProjectDuration}
                  options={projectData?.durationEnumValues?.map((value) => ({
                    value,
                    label: `${value?.name}`,
                  }))}
                  label={t("ProjectDuration")}
                />
                {errorMessages.projectDuration && (
                  <Typography variant="p" sx={{ color: "red" }}>
                    {errorMessages.projectDuration}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box>
                <FormLabel
                  sx={{
                    display: "block",
                    textAlign: "start",
                    py: 1.4,
                    fontSize: 14,
                  }}
                >
                  {t("EstimateBudget")}
                </FormLabel>
                <Dropdown
                  value={formData.price}
                  onChange={handlePrice}
                  options={projectData?.estimatEnumValues?.map((value) => ({
                    value,
                    label: `${value?.name}`,
                  }))}
                  label={t("EstimateBudget")}
                />
                {errorMessages.price && (
                  <Typography variant="p" sx={{ color: "red" }}>
                    {errorMessages.price}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box>
                <FormLabel
                  sx={{
                    display: "block",
                    textAlign: "start",
                    py: 1.4,
                    fontSize: 14,
                  }}
                >
                  {t("MinBudegte")}
                </FormLabel>
                <TextField
                  type="number"
                  onChange={handleMinBudget}
                  value={formData.minBudget}
                  variant="outlined"
                  fullWidth
                />
                {/* <Dropdown

                  value={formData.minBudget}
                  onChange={handleminBudget}
                  options={projectData?.estimatEnumValues?.map(value => ({
                    value,
                    label: `${value}`
                  }))}
                  label="MinBudget"
                  disabled
                /> */}
                {errorMessages.minBudget && (
                  <Typography variant="p" sx={{ color: "red" }}>
                    {errorMessages.minBudget}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box>
                <FormLabel
                  sx={{
                    display: "block",
                    textAlign: "start",
                    py: 1.4,
                    fontSize: 14,
                  }}
                >
                  {t("MaxBudget")}
                </FormLabel>
                <TextField
                  type="number"
                  value={formData.maxBudget}
                  onChange={handleMaxBudget}
                  variant="outlined"
                  fullWidth
                />
                {/* <Dropdown

                  value={formData.minBudget}
                  onChange={handleminBudget}
                  options={projectData?.estimatEnumValues?.map(value => ({
                    value,
                    label: `${value}`
                  }))}
                  label="MinBudget"
                  disabled
                /> */}
                {errorMessages.maxBudget && (
                  <Typography variant="p" sx={{ color: "red" }}>
                    {errorMessages.maxBudget}
                  </Typography>
                )}
                {/* <Dropdown
                  value={formData.maxBudget}
                  onChange={handleMaxBudget}
                  options={projectData?.estimatEnumValues?.map((value) => ({
                    value,
                    label: `${value?.name}`,
                  }))}
                  label={t("MaxBudget")}
                  disabled
                />
                {errorMessages.maxBudget && (
                  <Typography variant="p" sx={{ color: "red" }}>
                    {errorMessages.maxBudget}
                  </Typography>
                )} */}
              </Box>
            </Grid>
          </Grid>

          {/* <Box sx={{ my: 6, mb: 0, display: "flex", justifyContent: "start" }}>
            <Button
              className="SignUpBtn"
              sx={{
                textTransform: "capitalize",
                py: "8px !important",
                px: "24px !important",
                width: "auto !important",
                borderRadius: 12,
                maxWidth: "auto !important",
                color: "white",
                display: "flex",
                alignItems: "center",
              }}
              endIcon={
                <Box
                  component="img"
                  src={arrowCross}
                  sx={{ width: 24, height: 24, marginRight: 1 }}
                />
              }
              onClick={handleSaveButtonClick}
            >
              Save & Post
            </Button>
          </Box> */}
        </Box>
      </Box>

      {/* <div>
        <h2>Upload Files</h2>
        <input type="file" onChange={handleFileChange} multiple />
        <div>
          {fileNames.map((name, index) => (
            <div key={index}>{name}</div>
          ))}
        </div>
        <button onClick={handleSaveButtonClick}>Upload</button>
      </div> */}

      <Box
        sx={{
          backgroundColor: "#fff",
          p: 5,
          py: 3.5,
          my: 4,
          borderRadius: 7.5,
          border: 1,
          borderColor: "#d6d6d6",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#000",
            fontWeight: 600,
            pb: 2,
            borderBottom: 1,
            borderColor: "#d6d6d6",
            px: 0,
          }}
        >
          {t("UploadAttachments")}
        </Typography>
        <Box
          className="uploadSec"
          sx={{
            py: 5,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            {fileNames.map((file, index) => (
              <Typography
                key={index}
                variant="p"
                sx={{
                  color: "#000",
                  px: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* {file.name} ({file.size / 1024}kB) */}
                {t("FileName")} : {file}
                <img
                  src={delete1}
                  alt="Delete"
                  onClick={() => handleDeleteFile(file, index)}
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px",
                    width: "15px",
                    height: "15px",
                  }}
                />
              </Typography>
              //  {fileNames.map((name, index) => (
              //   <div key={index}>{name}</div>
              // ))}
            ))}
          </Box>
          <Box sx={{ py: 4 }}>
            <DropzoneArea
              acceptedFiles={["image/*", ".pdf", ".txt"]} // Define accepted file types
              dropzoneText={t("DragandDrop")}
              onChange={handleFileChange}
              showPreviewsInDropzone={false}
              showAlerts={false}
              maxFileSize={2000000}
              dropzoneclassName="custom-dropzone"
              filesLimit={1} // Custom CSS class for DropzoneArea
            />
          </Box>
          <Box sx={{ my: 2, mb: 0, display: "flex", justifyContent: "start" }}>
            <Button
              className="SignUpBtn"
              sx={{
                textTransform: "capitalize",
                py: "12px !important",
                px: "26px !important",
                width: "auto !important",
                borderRadius: 12,
                maxWidth: "auto !important",
                color: "white",
                display: "flex",
                alignItems: "center",
              }}
              endIcon={
                <Box
                  component="img"
                  src={arrowCross}
                  sx={{ width: 24, height: 24, marginRight: 1 }}
                />
              }
              // onClick={handleUploadFile}
              onClick={handleSaveButtonClick}
            >
              {t("SavePost")}
            </Button>
          </Box>
        </Box>
      </Box>
    </section>
  );
}
