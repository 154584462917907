import { getAPI, postAPI } from "./Api";

export const getCategory = async () => {
  try {
    let headers = {
      "Content-Type": "application/json",
    };

    let response = await getAPI("category/all", { headers });
    return response;
  } catch (err) {
    console.log(err);
    return false;
  }
};
export const getDropDownValue = async () => {
  try {
    let headers = {
      "Content-Type": "application/json",
    };

    let response = await getAPI("project/dropdown", { headers });
    return response;
  } catch (err) {
    console.log(err);
    return false;
  }
};
export const getProjectByCategory = async (catId) => {
  try {
    let headers = {
      "Content-Type": "application/json",
    };
    let body = {
      categoryId: catId,
    };

    let response = await postAPI("project/category", body, { headers });
    return response;
  } catch (err) {
    console.log(err);
    return false;
  }
};
