import {
  Box,
  TextField,
  Typography,
  FormLabel,
  IconButton,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./RegistrationEmployee.css";
import googleIcon from "../../../../../Assets/Images/RedGoole.png";
import {
  isValidEmail,
  hasSpecialCharacter,
  isValidName,
  LoaderShow,
  LoaderHide,
} from "../../../../../Helpers/Utility";
import { postAPI } from "../../../../../Services/Api";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import "../../../../../Localization/i18n";
import { getAuth, signInWithPopup } from "firebase/auth";
import { provider } from "../../../../Firebase/firebase";

export default function RegistrationEmployee(props) {
  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [errors, setErrors] = React.useState({}); // State for validation errors
  const [fullName, setFullName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (props?.employeeUserData?.registrationDataEmployee) {
      setFullName(props?.employeeUserData?.registrationDataEmployee?.fullName || "");
      setEmail(props?.employeeUserData?.registrationDataEmployee?.email || "");
      setTermsChecked(true);
    }
  }, []);

  const handleFocus = () => {
    setIsFocused(false);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleTermsCheckboxChange = (event) => {
    setTermsChecked(event.target.checked);
    setErrors((prevErrors) => ({
      ...prevErrors,
      terms: event.target.checked
        ? ""
        : "You must agree to the Terms & Conditions",
    }));
  };

  const getUserExistance = async () => {
    try {
      const dataToSend = {
        email: email,
        role: "employee",
      };
      const response = await postAPI("user/checkExistence", dataToSend);
      return response;
    } catch (error) {
      toast.error("Email already exists");
    }
  };

  const handleSignUp = async () => {
    const newErrors = {};
    LoaderShow();
    if (!fullName?.trim()) {
      newErrors.fullName = t("FullNameIsRequired");
    } else if (!isValidName(fullName)) {
      newErrors.fullName = t("FullNameAtleastThree");
    }

    if (!email?.trim()) {
      newErrors.email = t("EmailIsRequired");
    } else if (!isValidEmail(email)) {
      newErrors.email = t("InvalidEmailFormat");
    }

    // if (!password?.trim()) {
    //   newErrors.password = t("PasswordIsRequired");
    // } else if (password.length < 8 || !hasSpecialCharacter(password)) {
    //   newErrors.password = t("PassWordMustBe8Characters");
    // }

    if (!termsChecked) {
      newErrors.terms = t("YouMustAgree");
    }

    const checkUserExistOrNot = await getUserExistance();


    if (checkUserExistOrNot === undefined) {
      newErrors.checkuser = "email already exists";
    }

    // Update the errors state with new validation errors
    setErrors(newErrors);

    // If there are no errors, proceed with signup
    if (Object.keys(newErrors).length === 0) {
      try {
        const dataToSend = {
          fullName: fullName,
          email: email,
          password: password,
        };
        props.SetEmployeeUserDataObject("registrationDataEmployee", dataToSend);
      } catch (error) {
        console.error("Failed to post data:===", error.message);
      }
      props.handleNextStepEmployee(); // Call the prop function for signup
    }
    LoaderHide();
  };

  const signInGoogle = async (ev) => {
    // ev.preventDefault();
    // const auth = getAuth();
    // LoaderShow();
    // try {
    //   const result = await signInWithPopup(auth, provider);
    //   console.log(result);
    // } catch (err) {}
    // LoaderHide();
  };

  return (
    <Box
      sx={{
        p: 8,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
      className="sideBg"
    >
      <Box sx={{ paddingTop: 4 }}>
        <Box className="">
          <Typography
            variant="h5"
            sx={{ textAlign: "center", fontWeight: 700, fontFamily: "inherit" }}
            className="registerHead"
          >
            {t("RegisterEmployee")}
          </Typography>
          <p className="beginText">{t("WorkWaveHub")}</p>
        </Box>
        <Box sx={{ width: "100%", marginTop: 5 }}>
          <div>
            <FormLabel
              sx={{ display: "block", textAlign: "start", py: 1, fontSize: 14 }}
            >
              {t("Full")}*
            </FormLabel>
            <TextField
              variant="outlined"
              sx={{
                background: isFocused ? "#FFFFFF" : "transparent",
                borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                boxShadow: isFocused
                  ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                  : "none",
              }}
              fullWidth={true}
              placeholder={t("EnterFullName")}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              error={!!errors.fullName} // Set error state based on validation
              helperText={errors.fullName} // Display error message if there's an error
            />
          </div>
          <Box sx={{ my: 1 }}>
            <FormLabel
              sx={{ display: "block", textAlign: "start", py: 1, fontSize: 14 }}
            >
              {t("EmailAddd")}*
            </FormLabel>
            <TextField
              variant="outlined"
              sx={{
                background: isFocused ? "#FFFFFF" : "transparent",
                borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                boxShadow: isFocused
                  ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                  : "none",
              }}
              fullWidth={true}
              placeholder={t("EnterEmailAdd")}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!errors.email}
              helperText={errors.email}
            />
          </Box>
          {/* <Box sx={{ my: 1 }}>
            <FormLabel
              sx={{ display: "block", textAlign: "start", py: 1, fontSize: 14 }}
            >
              {t("CreatePass")}*
            </FormLabel>
            <TextField
              variant="outlined"
              sx={{
                background: isFocused ? "#FFFFFF" : "transparent",
                borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                boxShadow: isFocused
                  ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                  : "none",
              }}
              fullWidth={true}
              placeholder={t("EnterPass")}
              type={showPassword ? "text" : "password"} // Change the type dynamically
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!errors.password}
              helperText={errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box> */}
          <Box
            sx={{
              my: 1,
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Checkbox
              checked={termsChecked}
              onChange={handleTermsCheckboxChange}
            />
            <a
              style={{ color: "#777777" }}
              href="/terms-and-conditions"
              alt="terms"
            >
              {t("TermsAnd")}
            </a>
          </Box>
          {errors.terms && (
            <Typography
              variant="body2"
              sx={{ color: "#d32f2f", textAlign: "start" }}
            >
              {errors.terms}
            </Typography>
          )}
          <Box sx={{ my: 2, display: "flex", justifyContent: "start" }}>
            <Button
              onClick={handleSignUp}
              // onClick={props.handleNextStepEmployee} // Call the prop function on click
              className="SignUpBtn"
              sx={{ textTransform: "capitalize", width: 100, color: "white" }}
            >
              {t("SignUp")}
            </Button>
          </Box>
          {/* <Box sx={{ my: 1 }}>
            <FormLabel
              sx={{
                display: "block",
                textAlign: "center",
                py: 1,
                fontSize: 14,
              }}
            >
              OR
            </FormLabel>
          </Box>
          <Box sx={{ my: 1, display: "flex", justifyContent: "start" }}>
            <Button
              onClick={signInGoogle}
              className="SignUpGoogle"
              sx={{
                background: "#fff",
                textTransform: "capitalize",
                color: "white",
              }}
              startIcon={<img src={googleIcon} alt="dd" />} // Add the Google icon as the startIcon
            >
              {t("RegisterGoogle")}
            </Button>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
}
